import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Search from '../Search/Search'
import Navigation from '../../constants/Navigation'
import cx from 'classnames'

import { ReactComponent as Chevron } from '../../assets/images/Chevron.svg';
import { ReactComponent as Login } from '../../assets/images/Login-white.svg'
import { ReactComponent as SearchIcon } from '../../assets/images/search_white.svg'


import './MobileNav.scss';

const MobileNavigation = ({closeNav}) => {
  const [navigation] = useState(Navigation);
  const [activeItem, setItem] = useState([]);

  const [showLogin, toggleLogin] = useState(false);
  const [showSearch, toggleSearch] = useState(false);
  // const [activeItem, setItem] = useState([]);

  const showModal = showLogin || showSearch ? true : false;

  const logInContent = (
    <div className="MN__modal">
    <div className="MN__content__block">
            <h2 className="MN__content__title">
              <button className="MN__modal__back" type="button" onClick={() => toggleLogin(false)}><Chevron/></button>
              My Lombard
            </h2>
            <ul className="MN__content__links">
                <li className="MN__content__links--single"><a href="/">Bankline<Chevron/></a></li>
                <li className="MN__content__links--single"><a href="/">Online Banking<Chevron/></a></li>
                <li className="MN__content__links--single"><a href="/">ClearSpend<Chevron/></a></li>
                <li className="MN__content__links--single"><a href="/">RBSIF FacFlow<Chevron/></a></li>
                <li className="MN__content__links--single"><a href="/">FreeAgent<Chevron/></a></li>
            </ul>
            </div>
            </div>
)
const searchContent = (
    <div className="MN__modal">
    <div className="MN__content__block">
            <h2 className="MN__content__title">
              <button className="MN__modal__back" type="button" onClick={() => toggleSearch(false)}><Chevron/></button>
                Search
            </h2>
            <ul className="MN__content__links">
                <li className="MN__content__links--single">
                  <Search />
                </li>
            </ul>
            </div>
            </div>
)

const ToggleLevel1 = (key) => {
  if (activeItem.includes(key)) {
    setItem([])
    document.body.classList.remove('modal-open');
  } else {
    setItem([key])
    document.body.classList.add('modal-open');
  }
}

const ToggleLevel2 = (parentKey, Key) => {
  if (activeItem.includes(parentKey)) {
    if(activeItem.includes(Key)) {
      setItem([parentKey])
    } else {
      setItem([parentKey, Key])
    }
  } 
}

const NavigationLevel2 = (keyTwo, keyOne) => {
  const length = Object.keys(navigation[keyOne][keyTwo]).length > 0;
  const content = length ? (
    <div className={cx("MN__content__block--3", {'NM__Active' : activeItem.includes(keyTwo)})}>
      {Object.values(navigation[keyOne][keyTwo]).map((value, index) => (
        <>
         <h2 className="MN__content__title">{value.title}</h2>
         <ul className="MN__content__links">
           {Array.isArray(value.links) ? value.links.map((value, index) => (
                <li key={index} className="MN__content__links--single">
                <a href={value.link} >{value.title}<Chevron/></a>
              </li>
           )) : ''}
        </ul>
        </>
      ))}
    </div>
  ) : null;

  return content;
}

const NavigationLevel1 = (parentKey) => {
  const length = Object.keys(navigation[parentKey]).length > 0;
  const content = length ? (
    <div className={cx("MN__content__block--2", {'NM__Active' : activeItem.includes(parentKey)})}>
    <h2 className="MN__content__title">{parentKey}</h2>
    <ul className="MN__content__links">
      {Object.keys(navigation[parentKey]).map((key, index) => (
      <li key={index} className="MN__content__links--single">
      <button type="button" onClick={() => ToggleLevel2(parentKey, key)}>{key}<Chevron/></button>
      {NavigationLevel2(key, parentKey)}
  </li>
))}
    </ul>
    </div>
  ) : null;

  return content;
}

  return (
    <div className="MN">
        <div className="MN__head">
            <div className="MN__head__links">
                <a href="https://personal.natwest.com/personal.html">Personal</a>
                <a href="https://personal.natwest.com/premier-banking.html">Premier</a>
                <a className="active" href="https://www.natwest.com/business.html">Business</a>
            </div>
            <button type="button" onClick={() => closeNav(true)} className="MN__head__close">
                <span>Close</span>
            </button>
        </div>
        <div className="MN__content">
            <div className="MN__content__block">
            <h2 className="MN__content__title">Business</h2>
            <ul className="MN__content__links">
              {Object.keys(navigation).map((key, index) => (
              <li key={index} className="MN__content__links--single">
              <button 
                type="button" 
                onClick={() => {
                  if(navigation[key]){
                    if(Object.keys(navigation[key]) && navigation[key]['link'])
                      {
                        window.location.href = navigation[key]['link']
                        return;
                      }
                    }
                    ToggleLevel1(key)
                  }
                }
                >{key}<Chevron/></button>
              {NavigationLevel1(key)}
          </li>
        ))}
              {/* <li className="MN__content__links--single">
        <a href='https://www.natwest.com/business/support-centre/service-status/coronavirus.html'>Coronavirus<Chevron/></a>
      </li> */}
            </ul>
            </div>
        </div>
        <div className="MN__bottom">
            <button type="button" onClick={() => {
              window.location.href="https://my.lombard.co.uk/login"
              (toggleLogin(true), toggleSearch(false))
              }}><Login/>My Lombard</button>
            <button type="button" onClick={() => {
              toggleLogin(false); 
              toggleSearch(true);
            }}><SearchIcon/>Search</button>
        </div>
    {showModal ? 
      showLogin ? logInContent : showSearch ? searchContent : null
    : null}
    </div>
  );
}

MobileNavigation.propTypes = {
  closeNav: PropTypes.func,
}

MobileNavigation.defaultProps = {
  closeNav: () => {},
}

export default MobileNavigation;