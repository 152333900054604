import React from 'react';

import { 
  FieldBase, 
  FieldError,
  FieldAddress,
  FieldAmount,
  FieldDate,
  FieldDropdown,
  FieldInput,
  FieldRadio,
  FieldCheckbox,
  FieldCompanySearch,
} from './';

/**
 * title
 * subtitle
 * groupTitle
 * name
 * type
 * size
 * formRegister
 */
const Field = (props) => {
  const { type } = props;

  const renderContent = (type) => {
    switch (type) {
      case 'field-address':
        return <FieldAddress { ...props } /> 
      case 'field-amount':
        return <FieldAmount { ...props } /> 
      case 'field-company-search':
        return <FieldCompanySearch { ...props } /> 
      case 'field-date':
        return <FieldDate { ...props } /> 
      case 'field-checkbox':
        return <FieldCheckbox { ...props } /> 
      case 'field-dropdown':
        return <FieldDropdown { ...props } /> 
      case 'field-radio':
        return <FieldRadio { ...props } /> 
      case 'field-input':
      default:
        return <FieldInput { ...props } />;
    }
  };

  const renderError = (type) => {
    switch (type) {
      default:
        return <FieldError { ...props } />;
    }
  };

  return (
    <FieldBase
      {...props}
      content={renderContent(type)}
      error={renderError(type)}
    />
  );
};

export default Field;