import React, {useContext, useState, useEffect} from 'react';
// import { Link } from "react-router-dom";
import cx from 'classnames';

import { ReactComponent as Cross } from '../../assets/images/icon_plus.svg';
import { ReactComponent as Tick } from '../../assets/images/tick.svg';
import { ReactComponent as GreenTick } from '../../assets/images/icon_tick.svg';
import Preflow from '../../components/Preflow/Preflow';

// import CTABlock from '../../components/CTABlock/CTABlock';
import { withRouter } from "react-router-dom";


import {Context} from '../../stores/store'

import './eligibilityDecision.scss';

const Approved = ({history}) => {
    const [state] = useContext(Context);
    const [importantNotesActive, setNotesActive] = useState(false);
    const applyRef = React.createRef();

    useEffect(() => {
        const {formData} = state;
        if (formData.status !== "Likely" && formData.status !== "Highly Likely") {
            history.push('/business/loans-and-finance/small-business-loan')
        }
    }, [history, state]);

    const scrollToApply = () => {
      applyRef.current.scrollIntoView({ behavior: 'smooth' });
    }

    return (
         <div className="ED">
      <div className="ED__heading">
        <div className="ED__wrapper">
        <div className="ED__flex">
          <div className="ED__heading__title">
          <h1>Good news, we're highly likely to approve your application.</h1>
          </div>        
          <button type="button" id="EI_APPLY_NOW" onClick={() => scrollToApply()} className="EI__button">Apply now</button>
        </div>
        </div>
      </div>
      <div className="ED__content">
        <div className="ED__wrapper">
        <div className="ED__flex">
          <div className="ED__content__checklist__wrapper">
          <div className="ED__result">
          <GreenTick />
          <div className="ED__result__content">
          <span>Eligibility decision</span>
          <p>We've checked the personal and business details you provided against our affordability criteria. We're pleased to tell you that we'd be highly likely to approve an application based on these details.</p>
          </div>
          </div>
            <h3 className="ED__content__title--large">Small Business Loan:</h3>
        <ul className="ED__content__checklist">
          <li><Tick/>Borrow from <strong>£1,000 to £50,000</strong>, subject to approval</li>
          <li><Tick/>No early repayment charges</li>
          <li><Tick/>Choose a repayment term between <strong>1 to 7 years</strong>, or up to 10 years may be available on request, subject to credit status</li>
          <li><Tick/>Fixed rate of interest</li>
        </ul>
        <p className="ED__info">
A personal, director or member's guarantee may be required. Over 18s only. Subject to status, business use only.
        </p>
        </div>
        <div className="ED__Calculator__output">
                <h2>Representative example</h2>
                <div className="EL__Calculator__output_single">
                    <span className="CO__label">Loan amount</span>
                    <span className="CO__value">£{state.calculator.loanAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                </div>
                <div className="EL__Calculator__output_single">
                    <span className="CO__label">Loan term</span>
                    <span className="CO__value">{state.calculator.years} years, {state.calculator.months} months</span>
                </div>
                <div className="ED__Calculator__output_single">
                    <span className="CO__label">Monthly repayments</span>
                    <span className="CO__value">£{state.calculator.monthlyRepayments.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                </div>
                <div className="ED__Calculator__output_single">
                    <span className="CO__label">Interest rate % (fixed)</span>
                    <span className="CO__value">{parseFloat(state.calculator.interestRate).toFixed(2)}%</span>
                </div>
                <div className="ED__Calculator__output_single">
                    <span className="CO__label">Representative APR (variable)</span>
                    <span className="CO__value">{parseFloat(state.calculator.APR).toFixed(2)}% <span>APR</span></span>
                </div>
                <div className="ED__Calculator__output_single">
                    <span className="CO__label">Total repayment</span>
                    <span className="CO__value">£{state.calculator.Total.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                </div>
            </div>
        </div>
      </div>
      </div>
       <div className="Accordion">
        <div className={cx('Accordion__single', {'active': importantNotesActive})}>
          <button type="button" onClick={() => {setNotesActive(!importantNotesActive)}}>
            <h3>Important points to note before you apply <Cross /></h3>
          </button>
          <div className="Accordion__single__content">
            <ul>
              <li>We calculate your rate based on the loan term, loan amount and your status</li>
              <li>You'll need to make a repayment every month</li>
              <li>You'll be paying off the capital and the interest</li>
              <li>You won't be able to take a repayment holiday</li>
              <li>You can't take the loan in instalments (sometimes called a tranche drawdown)</li>
              <li>Read our <a href="https://www.natwest.com/content/dam/natwest/business/loans/documents/nw-bus-loans-sbl.pdf" targer="_blank">Small Business Loan factsheet</a> for additional product specifications</li>
            </ul>
          </div>
        </div>
    </div>
    <div className="ED__bottom__preapply__wrapper">
      <div className="ED__bottom__preapply" ref={applyRef}>
      <Preflow/>
      </div>
      </div>
       <section className="Bottom__section">
        <div className="Bottom__content">
        <div className="Bottom__desktop">
        <p>
        Customers with hearing and speech impairments can contact us by using our Relay UK Service: 18001 0345 711 4477 
        <br/>
        <br/>
        </p>
        <p>
        For more information on accessibility visit:<br/><a id="ACCESSABILITY" href="https://www.natwest.com/business/accessibility.html">https://www.natwest.com/business/accessibility.html</a><br/>
        Calls may be recorded for training and monitoring purposes.<br/>
        To see how much your call might cost, visit the <a id="CALLCHARGE" href="https://supportcentre.natwest.com/913202582">call charge information page.</a>
               </p>
        </div>
        <p>
        National Westminster Bank Plc. Registered in England and Wales (Registered Number 929027), Registered Office: 250 Bishopsgate, London EC2M 4AA.
        </p>
        </div>
      </section>
    </div>
    );
  };

export default withRouter(Approved);