import React, { useContext, useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
// import cx from 'classnames';
import { withRouter } from "react-router-dom";

import { Context } from '../../../stores/store'
// import { navToNext } from '../../../common/navigate';
// import { experianCheck } from '../../../common/experian';
// import { ReactComponent as Info } from "../../../assets/images/Info.svg";
import { trackApplicationStep } from '../../../common/tagging';

const DecisionRefer = ({ history }) => {
  const [ state ] = useContext(Context);
  // const { formData } = state;
  const {
    // register, 
    // handleSubmit, 
    // errors, setError, clearError, 
    // formState, 
    // watch, 
    setValue, 
  } = useForm({
    mode: 'onChange',
  });
  const [loaded, isLoaded] = useState(false);
  
  /**
   * render
   */
  useEffect(() => {

    if (loaded) {
      return;
    }

    /****** first load ******/
    // 1, tagging
    trackApplicationStep('refer');

    // 2, load existing data to form
    
    /****** first load completed ******/
    isLoaded(true);
  }, [state.formData.data, loaded, setValue]);

  return (
    <>
    <div className='ec__banner row'>
      <div className='ec__banner-inner col-lg-8 col-lg-offset-2'>
        <div className="ec__banner-title">You may not be eligible</div>
        <div className='ec__banner-subtitle'>Thank you for using our eligibility checker. Based on the information that you’ve provided, it’s possible that we would not be able to offer you finance at this time.</div>
      </div>
    </div>

    <div className='ec__main row'>
      <div className='ec__main-inner col-lg-8 col-lg-offset-2'>

        <p>Here are some typical reasons why:</p>

        <div className='ec__bullet'>
          <div className='ec__bullet-left'>
            <div className='ec__bullet-point'></div>
          </div>
          <div className='ec__bullet-right'>
            <div className='ec__bullet-content'>
              Missing or incorrect information<br/>
              We may not have received the correct information from you when completing the eligibility check.
            </div>
          </div>
        </div>
        <div className='ec__bullet'>
          <div className='ec__bullet-left'>
            <div className='ec__bullet-point'></div>
          </div>
          <div className='ec__bullet-right'>
            <div className='ec__bullet-content'>
              Too much borrowing<br/>
              You or another applicant entered may have too much borrowing with us or other banks and building societies.
            </div>
          </div>
        </div>
        <div className='ec__bullet'>
          <div className='ec__bullet-left'>
            <div className='ec__bullet-point'></div>
          </div>
          <div className='ec__bullet-right'>
            <div className='ec__bullet-content'>
              Low credit score<br/>
              You or another applicant entered may not have reached the minimum credit score needed.
            </div>
          </div>
        </div>


        <div className='ec__section-title'>What can you do now?</div>
        <div className='ec__section-content'>You may like to look at <a href="https://www.fla.org.uk/home" target="_blank" rel="noopener noreferrer">https://www.fla.org.uk/home</a> for a range of other finance providers, or for general help go to <a href="https://www.betterbusinessfinance.co.uk" target="_blank" rel="noopener noreferrer">https://www.betterbusinessfinance.co.uk</a>.</div> 


        {/* <div className="ec--step-bottom">
          <button className="btn btn-primary">Get a personalised quote</button>
        </div> */}
      </div>
    </div>
    </>
  );
}

export default withRouter(DecisionRefer);