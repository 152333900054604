import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { withRouter } from 'react-router-dom';

import { Context } from '../../../stores/store'
// import { navToNext } from '../../../common/navigate';
import { toDecimal, toFormatedNumStr, toNum } from '../../../common/util';
// import { ReactComponent as Info } from "../../../assets/images/Info.svg";
import { trackApplicationStep } from '../../../common/tagging';

const DecisionAccept = ({ history }) => {
  const [ state ] = useContext(Context);
  const { aboutAssets } = state.formData.data;
  const { formData } = state;
  const {
    // register, 
    // handleSubmit, 
    // errors, setError, clearError, 
    // formState, 
    // watch, 
    setValue, 
  } = useForm({
    mode: 'onChange',
  });
  const [loaded, isLoaded] = useState(false);

  /**
   * render
   */
  useEffect(() => {

    if (loaded) {
      return;
    }

    /****** first load ******/
    // 1, tagging
    trackApplicationStep('accept');

    // 2, load existing data to form
    
    /****** first load completed ******/
    isLoaded(true);
  }, [state.formData.data, loaded, setValue]);

  return (
    <>
    <div className='ec__banner row'>
      <div className='ec__banner-inner col-lg-8 col-lg-offset-2'>
        <div className="ec__banner-title">Congratulations</div>
        <div className='ec__banner-subtitle'>Thank you for using our eligibility checker. We’re pleased to confirm that based on the information that you’ve provided so far, it’s likely that you would be successful if you applied for finance with us at this time.</div>
      </div>
    </div>

    <div className='ec__main row'>
      <div className='ec__main-inner col-lg-8 col-lg-offset-2'>

        <p>This assessment is subject to further checks and is based on the following information:</p>

        {formData.data.aboutAssets?.assetSingle === 'yes' && 
        <div className='ec__assets-table ec__assets-table-single'>
          <div className='ec__assets-row'>
            <div className='ec__assets-cell ec__assets-cell--asset-type'>
              <div className='ec__assets-cell--header'>Asset type</div>
              <div className='ec__assets-cell--content'>{aboutAssets?.assetType}</div>
            </div>
            <div className='ec__assets-cell ec__assets-cell--asset-type-price'>
              <div className='ec__assets-cell--header'>
                {aboutAssets?.assetType === 'Car' ? 'Purchase price (including VAT)' : 'Purchase price (excluding VAT)'}
              </div>
              <div className='ec__assets-cell--content'>£{toFormatedNumStr(toDecimal(toNum(aboutAssets?.purchasePrice)))}</div>
            </div>
            <div className='ec__assets-cell ec__assets-cell--asset-type-deposit'>
              <div className='ec__assets-cell--header'>

              {aboutAssets?.assetType === 'Car' ? 'Deposit' : 'Deposit (including VAT)'}
              </div>
              <div className='ec__assets-cell--content'>£{toFormatedNumStr(toDecimal(toNum(aboutAssets?.deposit)))}</div>
            </div>
          </div>
        </div>}

        {formData.data.aboutAssets?.assetSingle === 'no' && 
        <div className='ec__assets-table ec__assets-table-multiple'>
          <div className='ec__assets-row'>
            <div className='ec__assets-cell ec__assets-cell--asset-type-count'>
              <div className='ec__assets-cell--header'>Number of assets</div>
              <div className='ec__assets-cell--content'>{aboutAssets?.assetsCount}</div>
            </div>
            <div className='ec__assets-cell ec__assets-cell--asset-type-main'>
              <div className='ec__assets-cell--header'>Main asset type</div>
              <div className='ec__assets-cell--content'>{aboutAssets?.mainAssetType}</div>
            </div>
            <div className='ec__assets-cell ec__assets-cell--asset-type-price'>
              <div className='ec__assets-cell--header'>
                {aboutAssets?.mainAssetType === 'Car' ? 'Purchase price (including VAT)' : 'Purchase price (excluding VAT)'}
              </div>
              <div className='ec__assets-cell--content'>£{toFormatedNumStr(toDecimal(toNum(aboutAssets?.purchasePrice)))}</div>
            </div>
            <div className='ec__assets-cell ec__assets-cell--asset-type-deposit'>
              <div className='ec__assets-cell--header'>
                {aboutAssets?.mainAssetType === 'Car' ? 'Deposit' : 'Deposit (including VAT)'}
              </div>
              <div className='ec__assets-cell--content'>£{toFormatedNumStr(toDecimal(toNum(aboutAssets?.deposit)))}</div>
            </div>
          </div>
        </div>}
        
        <div className='ec__section-title'>Next steps</div>
        <div className='ec__section-content'>If you’re ready to start your full application for Hire Purchase, you can apply below or <a className="ext-link" href="https://www.lombard.co.uk/finance-options/hire-purchase.html" target="_blank" rel="noopener noreferrer">explore your options further.</a></div> 
        <div className='ec__section-footer'>To apply you must be 18+ and a UK resident. Security may be required and product fees may apply. Subject to status, eligibility and approval.</div>


        <div className="ec--step-bottom">
          <a className="btn btn-primary btn-margin-right" href="https://quote.lombard.co.uk/landing/cars-and-vans?extcam=L_QQ_LINK_Getapersonalisedquote" target="_blank" rel="noopener noreferrer">Get a Fixed Rate Quote</a>

          <a className="btn btn-primary" href=" https://www.lombard.co.uk/support/contact-us.html#contact" target="_blank" rel="noopener noreferrer">Get a Variable Rate quote</a>
        </div>
        
      </div>
    </div>
    </>
  );
}

export default withRouter(DecisionAccept);
















    // if (data) {
    //   if ( data.Day &&  data.Day.length === 1) {
    //     data.Day = '0' + data.Day;
    //   }
    //   if ( data.Month &&  data.Month.length === 1) {
    //     data.Month = '0' +  data.Month;
    //   }
    // }
    // const currentID = formData.steps[currentStepId].id
    // const count = formData.steps.filter((x) => {return x.isComplete}).length + 1
    // const next = formData.steps.findIndex((element) => (
    //   element.isComplete === false && element.id !== currentID
    // ));
  
    // const result = {
    //   data: {
    //   addressHistory: [...formData.data.addressHistory],
    //   aboutYou: {data},
    //   aboutbusiness: {...formData.data.aboutbusiness},
    //   },
    //   steps: formData.steps.map(
    //     el => el.id === currentID ? { ...el, isComplete: true }: el
    //   ),
    //   sectionsCompelete: 4 - count,
    //   activeStep: formData.steps[next].id
    // }

    // dispatch({type: 'FORM_STEP', payload: result});
    // history.push(`/business/loans-and-finance/eligibility-check/${formData.steps[next].id}`)


