import cx from 'classnames';
import moment from 'moment';
import React from 'react';

import './field-date.scss';

const FieldDate = ({
  name,
  disableDay,
  beforeToday,
  validate: parentValidateHandler,
  formRegister,
}) => {
  const {
    register,
    errors,
    formState,
    watch,
    setValue,
    getValues,
  } = formRegister;
  const watchAllFields = watch({ nest: true });
  const nameDay = name + ".day";
  const nameMonth = name + ".month";
  const nameYear = name + ".year";

  return (
    <>
    {/* ***************************************************
      *** DAY
      *************************************************** */}
    {!disableDay && (
      <input
        type="number"
        name={nameDay}
        placeholder="DD"
        onChange={(e) => {
          e.currentTarget.value = e.currentTarget.value.slice(0, 2);
          e.preventDefault();
          setValue(nameDay, e.currentTarget.value);
        }}
        onBlur={(e) => {
          if (
            e.target.value.length < 2 &&
            e.target.value < 10 &&
            e.target.value >= 0
          ) {
            setValue(nameDay, "0" + e.target.value);
          }
        }}
        maxLength="2"
        className={cx(
          "form-control",
          "field-date__day",
          { touched: formState.touched[nameDay] },
          { success: watchAllFields[nameDay] },
          { error: errors[name]?.day  }
        )}
        ref={register({
          required: 'Please enter a date',
          validate: (val) => {
            if (val.length > 1 && (val > 31 || val < 1)) {
              return "Day is out of range";
            }
          },
        })}
      />
    )}

    {/* ***************************************************
      *** MONTH
      *************************************************** */}
    <input
      type="number"
      name={nameMonth}
      placeholder="MM"
      onChange={(e) => {
        e.currentTarget.value = e.currentTarget.value.slice(0, 2);
        e.preventDefault();
        setValue(nameMonth, e.currentTarget.value);
      }}
      onBlur={(e) => {
        if (
          e.target.value.length < 2 &&
          e.target.value < 10 &&
          e.target.value >= 0
        ) {
          setValue(nameMonth, "0" + e.target.value, true);
        }
      }}
      maxLength="2"
      className={cx(
        "form-control",
        "field-date__month",
        { touched: formState.touched[nameMonth] },
        { success: watchAllFields[nameMonth] },
        { error: errors[name]?.month }
      )}
      ref={register({
        required: 'Please enter a date',
        validate: (val) => {
          if (val.length > 1 && (val > 12 || val < 1)) {
            return "Month is out of range";
          }
        },
      })}
    />

    {/* ***************************************************
      *** YEAR
      *************************************************** */}
    <input
      type="number"
      name={nameYear}
      placeholder="YYYY"
      onChange={(e) => {
        e.currentTarget.value = e.currentTarget.value.slice(0, 4);
        e.preventDefault();
        setValue(nameYear, e.currentTarget.value);
      }}
      maxLength="4"
      className={cx(
        "form-control",
        "field-date__year",
        { touched: formState.touched[nameYear] },
        { success: watchAllFields[nameYear] },
        { error: errors[name]?.year }
      )}
      ref={register({
        required: 'Please enter a date',
        validate: (val) => {
          const valDay = getValues(nameDay);
          const valMonth = getValues(nameMonth);
          const valYear = getValues(nameYear);
          
          // beforeToday
          if (beforeToday) {
            const currDateStr = moment(new Date()).format(
              disableDay ? "YYYY-MM" : "YYYY-MM-DD"
            );
            const inputDateStr = moment()
              .set({ year: valYear, month: valMonth - 1, day: valDay || 1 })
              .format(disableDay ? "YYYY-MM" : "YYYY-MM-DD");
            
            // console.log(inputDateStr, currDateStr);
            if (inputDateStr > currDateStr) {
              return typeof beforeToday === "string"
                ? beforeToday
                : "Please input a date earlier than today";
            }
          }

          if (valYear < 1900) {
            return "Year is out of range";
          }
          
          const inputDate = moment(`${valYear}-${valMonth}-${valDay || '01'}`);
          // console.log('inputDate', `${valYear}-${valMonth}-${valDay || '01'}`, inputDate);
          // console.log(inputDate.isValid());
          if (!inputDate.isValid()) {
            return "Please enter a valid date";
          }

          if (parentValidateHandler) {
            const errMsg = parentValidateHandler({
              year: getValues(nameYear),
              month: getValues(nameMonth),
              day: getValues(nameDay),
            });
            if (errMsg) {
              return errMsg;
            }
          }
        },
      })}
    />
    </>
  );
};

export default FieldDate;
