import React from 'react';

import './field-checkbox.scss';

const FieldCheckbox = ({ name, label, subType, options, validate: parentValidateHandler, formRegister }) => {
  const {
    register,
  } = formRegister;

  const validationHandler = {
    required: !parentValidateHandler ? 'This field is required' : false,
    validate: val => {
      // console.log(val);
      if (parentValidateHandler) {
        return parentValidateHandler(val);
      }
    }
  };
  
  return (
    <>
    {/* <input name={name} type="checkbox" ref={register({ required: true })}/> */}
    <input name={name} type="checkbox" ref={register(validationHandler)}/>
    <span className='field-checkbox--label'>{label}</span>
    </>
  );
};

export default FieldCheckbox;