import React, {useContext, useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { withRouter } from "react-router-dom";

import { Context } from '../../../stores/store'
import { navToNext, navToPrev, calcNewFormData } from '../../../common/navigate';
import { Field } from '../../../components/fields';

const AboutBusiness = ({ history }) => {
  const [state, dispatch] = useContext(Context);
  const formRegister = useForm({
    mode: 'onChange',
  });
  const {
    handleSubmit, 
    watch, 
    setValue, 
  } = formRegister;
  const [loaded, isLoaded] = useState(false);
  const watchAllFields = watch();
  const currentStepIdx = 2;

  const isSoleTrader = state.formData.data.aboutApplicant?.applicantType === 'Sole Trader';
  const isPartnership = state.formData.data.aboutApplicant?.applicantType === 'Partnership';

  /**
   * 
   */
   useEffect(() => {    
    if (loaded) {
      return;
    }
    
    // setValue([
    //   // { applicantType: data.companySearch.companyRegNum},
    // ]);
    isLoaded(true);
  }, [state, loaded, setValue]);

  /**
   *  
   */
   const submitHandler = (data) => {
    // console.log(`[onSubmit] ${JSON.stringify(data)}`);

    data = {
      ...data,
      companyRef: '13312703',
      companyName: 'LIFE AND WELLBEING COACHING LTD',
      companyAddress: '120 Chesterfield Road, Suffolk, TN3 1YP',
      companyTurnover: 1000,
      incorporationDate: '2020-12-01',
    };

    // update store
    const newFormData = calcNewFormData(state.formData, currentStepIdx, data);
    dispatch({ type: 'FORM_STEP', payload: newFormData });

    // nav to next step
    if (isSoleTrader || isPartnership) {
      navToNext(currentStepIdx, state.formData.steps, history, 'company-details');
      return;
    }
    navToNext(currentStepIdx, state.formData.steps, history);
  }

  /**
   * 
   */
  const prevBtnHandler = () => {
    navToPrev(currentStepIdx, state.formData.steps, history);
  }

  return (
    <>
    <div className='log-block log-block-2'><p>{JSON.stringify(watchAllFields)}</p></div>
    
    <div className='ec__banner row'>
      <div className='ec__banner-inner col-lg-8 col-lg-offset-2'>
        <div className="ec__banner-title">Eligibility Checker</div>
      </div>
    </div>

    <div className='ec__main row'>
      <div className='ec__main-inner col-lg-8 col-lg-offset-2'>
        <form onSubmit={handleSubmit(submitHandler)} className="ec--form ec--step-one active">
          <div className="ec--form-wrapper">

            <div className='ec--step-progress'><b>Step 3</b> of 4</div>
            <div className='ec--step-title'><h2>About your business</h2></div>


            <Field
              type='field-input'
              name='company'
              title='Search for your company name or company number'
              size='lg'
              formRegister={formRegister}
            />

            <Field
              type='field-amount'
              name='annualTurnover'
              title='Annual Turnover'
              formRegister={formRegister}
            />

            <Field
              type='field-date'
              name='startTradingDate'
              title='When did the business start trading?'
              formRegister={formRegister}
            />


            <div className="ec--step-bottom">
              <button type="submit" className="btn btn-primary">Continue</button>
              <button type="button" className="btn btn-link" onClick={prevBtnHandler}>Previous</button>
            </div>
            
          </div>
        </form>
      </div>
    </div>
    </>
  );
}

export default withRouter(AboutBusiness);
