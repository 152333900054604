import _ from 'lodash';
import React, {useContext, useEffect, useRef, useState } from 'react';
import { useForm } from "react-hook-form";
import { withRouter } from "react-router-dom";

import { Context } from '../../../stores/store';
import { navToNext, navToPrev, calcNewFormData } from '../../../common/navigate';
import { Field } from '../../../components/fields';
import { toFormatedNumStr } from '../../../common/util';
import envConfig from '../../../common/env.config';
import { trackApplicationStep } from '../../../common/tagging';

const CompanySearch = ({ history }) => {
  const [state, dispatch] = useContext(Context);
  const formRegister = useForm({
    mode: 'onBlur',
  });
  const {
    handleSubmit, 
    watch, 
  } = formRegister;
  const firstLoad = useRef(true);
  const [loaded, isLoaded] = useState(false);
  const watchAllFields = watch({ nest: true });
  const currentStepIdx = 2;
  
  const { aboutApplicant } = state.formData.data;
  const isLtd = aboutApplicant?.applicantType === 'Limited Company';
  const isLlp = aboutApplicant?.applicantType === 'Limited Liability Partnership';
  const isSoleTrader = aboutApplicant?.applicantType === 'Sole Trader';
  const isPartnership = aboutApplicant?.applicantType === 'Partnership';
  const isIndividual = aboutApplicant?.applicantType === 'Individual';

  
  /**
   * 
   */
  useEffect(() => {
    // console.log('[useEffect] nextAction', nextAction);

    if (firstLoad.current) {
      firstLoad.current = false;
      dispatch({ type: 'FORM_STEP_DATA', payload: ['formData.data.companySearch', {}] });
      dispatch({ type: 'FORM_STEP_DATA', payload: ['formData.data.companyDetails', {}] });
    }

    if (loaded) {
      return;
    }

    /****** first load ******/
    // 1, tagging
    trackApplicationStep('company-search', aboutApplicant?.applicantType);

    // 2, load existing data to form
    
    /****** first load completed ******/
    isLoaded(true);
  }, [aboutApplicant.applicantType, dispatch, loaded]);

  /**
   * 
   */
  const onCompanySearchChangeHandler = (val) => {
    dispatch({ type: 'FORM_STEP_DATA', payload: ['formData.data.companySearch.company', val] });
  };

  /**
   *  
   */
   const submitHandler = (data) => {
    // console.log(`[onSubmit] ${JSON.stringify(data)}`);
    
    const companySearch = _.get(state, 'formData.data.companySearch');
    const newData = { ...companySearch };
    if (watchAllFields.annualTurnover) {
      newData.annualTurnover = watchAllFields.annualTurnover;
    }
    if (watchAllFields.incorporationDate) {
      newData.incorporationDate = watchAllFields.incorporationDate;
    }

    // update store
    const newFormData = calcNewFormData(state.formData, currentStepIdx, newData);
    dispatch({ type: 'FORM_STEP', payload: newFormData });

    // nav to next step
    navToNext(currentStepIdx, state.formData.steps, history);
  }

  /**
   * 
   */
  const prevBtnHandler = () => {
    if (watchAllFields.company) {
      dispatch({ type: 'FORM_STEP_DATA', payload: ['formData.data.companySearch.company', watchAllFields.company] });
    }

    if (watchAllFields.annualTurnover) {
      dispatch({ type: 'FORM_STEP_DATA', payload: ['formData.data.companySearch.annualTurnover', watchAllFields.annualTurnover] });
    }

    if (watchAllFields.incorporationDate) {
      dispatch({ type: 'FORM_STEP_DATA', payload: ['formData.data.companySearch.incorporationDate', watchAllFields.incorporationDate] });
    }

    navToPrev(currentStepIdx, state.formData.steps, history);
  }

  return (
    <>
    {envConfig.debug && <div className='log-block log-block-2'><p>{JSON.stringify(watchAllFields)}</p></div>}
    
    <div className='ec__banner row'>
      <div className='ec__banner-inner col-lg-8 col-lg-offset-2'>
        <div className="ec__banner-title">Eligibility Checker</div>
      </div>
    </div>

    <div className='ec__main row'>
      <div className='ec__main-inner col-lg-8 col-lg-offset-2'>
        <form onSubmit={handleSubmit(submitHandler)} className="ec--form ec--step-one active">
          <div className="ec--form-wrapper">

            <div className='ec--step-progress'><b>Step 3</b> of 4</div>
            <div className='ec--step-progress-bar step-3'>
              <div className='ec--step-progress-bar-dot'></div>
              <div className='ec--step-progress-bar-dot'></div>
              <div className='ec--step-progress-bar-dot'></div>
              <div className='ec--step-progress-bar-step'></div>
            </div>
            

            {(isLtd || isLlp || isSoleTrader) && <div className='ec--step-title'><h2>Company search</h2></div>}
            {(isLtd || isLlp) && <div className='ec--step-subtitle'>Great, if you tell us your full company name we can get all the necessary details from companies house</div>}
            {(isSoleTrader || isPartnership) && <div className='ec--step-subtitle'>If you are unable to find your business name please <a href="https://www.lombard.co.uk/support/contact-us.html?intcam=HP-TB-DEF-Default" rel="noopener noreferrer" target="_blank">contact us</a>.</div>}
            {(isPartnership || isIndividual) && <div className='ec--step-title'><h2>About your business</h2></div>}

      
            <Field
              type='field-company-search'
              name='company'
              title={
                isSoleTrader || isPartnership
                  ? 'Search for your business name'
                  : 'Search for your company name'
              }
              size='lg'
              companyType={aboutApplicant.applicantType === 'Limited Company' || aboutApplicant.applicantType === 'Limited Liability Partnership' ? 'L' : 'N'}
              formRegister={formRegister}
              onChange={onCompanySearchChangeHandler}
              validate={(o) => {
                console.log('[validate] field-company-search', o);
              }}
            />

            {(isSoleTrader || isPartnership || isIndividual) && 
            <Field
              type='field-amount'
              name='annualTurnover'
              title='Annual Turnover'
              formRegister={formRegister}
              validate={(val) => {
                if (!val) {
                  return 'Please enter the annual turnover';
                }
                const min = 0;
                const max = 25000000;
                if (val < min) {
                  return `Should be between £${toFormatedNumStr(min)} and £${toFormatedNumStr(max)}`;
                }
                if (val > max) {
                  return `Should be between £${toFormatedNumStr(min)} and £${toFormatedNumStr(max)}`;
                }
              }}
            />}
            {
            (isSoleTrader || isPartnership || isIndividual) && 
            <Field
              type='field-date'
              name='incorporationDate'
              title='When did the business start trading?'
              disableDay={true}
              beforeToday={true}
              formRegister={formRegister}
            />}


            <div className="ec--step-bottom">
              <button type="submit" className="btn btn-primary">Continue</button>
              <button type="button" className="btn btn-link" onClick={prevBtnHandler}>Previous</button>
            </div>

          </div>
        </form>
      </div>
    </div>
    </>

  );
}

export default withRouter(CompanySearch);
