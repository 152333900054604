import React, {useEffect} from 'react';
import Header from '../components/Header/header'
import Footer from '../components/Footer/footer'
// import BreadCrumbs from '../components/BreadCrumbs/BreadCrumbs'
// import ApplicationTimes from '../components/ApplicationTimes/ApplicationTimes'
import PropTypes from 'prop-types';
// import { useCookies } from 'react-cookie';



const StandardLayout = ({children, EC}) => {
  const pageRef = React.createRef();
  // const [cookies, setCookie, removeCookie] = useCookies(['cookie-name']);
  // const [ATHasCookie, ChangeATCookie] = useState(cookies.ApplicationTimes ? true : false)

  /*
  const onChange = () => {
    let expire = new Date();
    expire.setDate(expire.getDate()+30);  
    setCookie('ApplicationTimes',true, { expires: expire });
    ChangeATCookie(true)
  }
  */

  useEffect(() => {
    pageRef.current.scrollIntoView();
    // console.log(cookies.ApplicationTimes)

  });
  return (
    <div ref={pageRef}>
      <Header/>
      {/* <BreadCrumbs checker={EC}/> */}
      {/* <ApplicationTimes isVisible={!ATHasCookie} handler={onChange}/> */}
        {children}
      <Footer/>
    </div>
  );
}

StandardLayout.propTypes = {
    children : PropTypes.object.isRequired,
    EC: PropTypes.bool,
}

StandardLayout.defaultProps = {
  EC: false,
}

export default StandardLayout;
