import React, {useContext, useState, useEffect} from 'react';
import { useForm } from "react-hook-form";
import { withRouter } from "react-router-dom";
import axios from 'axios';

import cx from 'classnames';


import { ReactComponent as Edit } from '../../../assets/images/pencil-edit-button.svg';

import {Context} from '../../../stores/store'

const AddressHistory = ({history}) => {
  const { register, handleSubmit, watch, setValue, errors, formState } = useForm({defaultValues: {isCurrent: 'false'}});
  const [state, dispatch] = useContext(Context);
  const {formData} = state;
  const [timeAddress, addTime] = useState(0);
  const [year, addYear] = useState(0);
  const [month, addMonth] = useState(0);
  const [addMore, showAddMore] = useState(false);
  const [isFull, setFull] = useState(false);
  const current = 1;
  const watchAllFields = watch(); // when pass nothing as argument, you are watching everything

  const [postcodeLookup, updatePostcode] = useState('');
  const [addressResults, returnAddress] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState(false);
  const [selectedMode, setselectedMode] = useState('view');
  const [autoPostcode, setManualPostcode] = useState(true);
  const [postCodeError, setPostError] = useState(false);

    useEffect(() => {
        const {formData} = state;
        if (formData.combinedTimeAtAddress >= 36) {
          setFull(true);
        }
        if (formData.activeStep === 'about-you') {
            history.push('/business/loans-and-finance/eligibility-checker/about-you')
        }
    }, [history, state]);

    const ContinueBtn = (data, e) => {
      e.preventDefault();
      if(formData.data.addressHistory.length === 0) {
        data['isCurrent'] = "true";
      } else if (data['isCurrent'] !== 'true') {
        data['isCurrent'] = "false";
      }
        const currentID = formData.steps[current].id
        const count = formData.steps.filter((x) => {return x.isComplete}).length + 1
        const totalTime = formData.combinedTimeAtAddress + timeAddress;
        const next = formData.steps.findIndex((element) => (
          element.isComplete === false && element.id !== currentID
          ));

        const combinedData = data['isCurrent'] === 'true' ? [data, ...formData.data.addressHistory] : [...formData.data.addressHistory, data];
      
        const result = {
            data: {
            combinedTimeAtAddress: formData.combinedTimeAtAddress + timeAddress,
            addressHistory: combinedData,
            aboutYou: {...formData.data.aboutYou},
            aboutbusiness: {...formData.data.aboutbusiness},
            },
            steps: formData.steps.map(
              el => el.id === currentID ? { ...el, isComplete: true }: el
            ),
            sectionsCompelete: 4 - count,
            activeStep: formData.steps[next].id
          }

        const addAddress = {
            data: {
            combinedTimeAtAddress: totalTime,
            addressHistory: combinedData,
            aboutYou: {...formData.data.aboutYou},
            aboutbusiness: {...formData.data.aboutbusiness},
            },
        }
        if (totalTime < 36) {
          dispatch({type: 'ADD_ADDRESS', payload: addAddress});
          addTime(0)
          returnAddress([]);
          updatePostcode('');
          e.target.reset();
          setValue({isCurrent: 'false'})
        } else {
          dispatch({type: 'FORM_STEP', payload: result});
          history.push(`/business/loans-and-finance/eligibility-checker/${formData.steps[next].id}`)
        }
      }

      const StepForward = () => {
        setselectedMode('view')
        const currentID = formData.steps[current].id
        const count = formData.steps.filter((x) => {return x.isComplete}).length + 1
        const next = formData.steps.findIndex((element) => (
          element.isComplete === false && element.id !== currentID
          ));

        const result = {
          data: {
          combinedTimeAtAddress: formData.combinedTimeAtAddress,
          addressHistory: [...formData.data.addressHistory],
          aboutYou: {...formData.data.aboutYou},
          aboutbusiness: {...formData.data.aboutbusiness},
          },
          steps: formData.steps.map(
            el => el.id === currentID ? { ...el, isComplete: true }: el
          ),
          sectionsCompelete: 4 - count,
          activeStep: formData.steps[next].id
        }
        dispatch({type: 'FORM_STEP', payload: result});
        returnAddress([]);
        updatePostcode('');
        history.push(`/business/loans-and-finance/eligibility-checker/${formData.steps[next].id}`)
      }
      
      const BackBtn = (e, value) => {
        e.preventDefault();
        const { formData } = state; 
        const backID = formData.steps[value].id
        const result = {
            steps: formData.steps.map(
              el => el.id === backID ? { ...el, isComplete: false }: el
            ),
            sectionsCompelete: formData.sectionsCompelete + 1,
            activeStep: formData.steps[value].id
          }
          dispatch({type: 'FORM_EDIT', payload: result});
          history.push(`/business/loans-and-finance/eligibility-checker/${backID}`)
      }

      const changeTime = (e) => {

        const value = parseInt(e.target.value);
        const name = e.target.name;
        
        switch(name) {
          case 'Year':
            let totalYear = (value * 12) + month;
            addYear(value)
            showAddMore(totalYear < 36)
            addTime(totalYear);
            break;
          case 'Month':
            let totalMonth = (year * 12) + value;
            addMonth(value);
            showAddMore(totalMonth < 36)
            addTime(totalMonth);
            break;
          default:
            break;
        }
      }

      const editPrev = (e, address) => {
        if(isFull) {
        setFull(false)
        }
        setselectedMode('edit')
          let editedData = [];
          if (formData.data.addressHistory.length < 1) {
            editedData = null;
          } else {
            editedData = formData.data.addressHistory.filter(item => item.postcode !== address.postcode);
          }
          const originalYear = parseInt(address.Year);
          const originalMonth = parseInt(address.Month);
          const originalTime = (originalYear * 12) + originalMonth;
          const timeWithoutCurrent = formData.combinedTimeAtAddress - originalTime;
          const editAddress = editedData === null ? {
            data: {
            combinedTimeAtAddress: timeWithoutCurrent,
            addressHistory: [],
            aboutYou: {...formData.data.aboutYou},
            aboutbusiness: {...formData.data.aboutbusiness},
            },
          } : {
          data: {
            combinedTimeAtAddress: timeWithoutCurrent,
            addressHistory: [...editedData],
            aboutYou: {...formData.data.aboutYou},
            aboutbusiness: {...formData.data.aboutbusiness},
            },
          }
          dispatch({type: 'EDIT_ADDRESS', payload: editAddress});
          addTime(originalTime);
          addMonth(originalMonth);
          addYear(originalYear);
          if(timeWithoutCurrent < 32) {
            showAddMore(true);
          }
        setValue([
          { address1 : address.address1, },
          { address2 : address.address2, },
          { town : address.town, },
          { postcode : address.postcode, },
          { Year: address.Year},
          { Month: address.Month},
          { isCurrent: address.isCurrent}
        ])

          updatePostcode(address.postcode);
          setSelectedAddress(address.address1);
          searchAddress(address.postcode, true);
      }

      const prevAddresses = () => {
        const addresses = formData.data.addressHistory.filter((x) => {return x.address1})
        const content = addresses.map((address, index) => (
          <div key={index} className="AH__prevAddress">
          <h3>{address.isCurrent === 'true' ? 'Current address' : 'Previous address'}</h3>
          <span>{address.address1} {address.address2}, {address.town} {address.postcode}</span>
          <span>{address.Year === '' ? 0 : address.Year} {address.Year === 1 ? 'Year' : 'Years'}, {address.Month === '' ? 0 : address.Month} {address.Month === 1 ? 'Month' : 'Months'}</span>
          <button type="button" onClick={(e) => editPrev(e, address)}><Edit />Edit</button>
          </div>
        ))
        return content;
      }

      const selectAddress = (address) => {
        const fullAddress = address.split(',');
        setValue([
          { address1 : fullAddress[0], },
          { address2 : fullAddress[1], },
          { town : fullAddress[3], },
          { postcode : postcodeLookup, }
        ])
      }

      const searchAddress = (postcode, address) => {
        const apikey = "cDQ92xSDZUipI_HkrfU0SA26532";
        let value = postcode && postcode.length ? postcode : postcodeLookup;
        axios.get(`https://api.getAddress.io/find/${value}?api-key=${apikey}&format=true`)
      .then(res => {
        returnAddress(res.data);
        if(!address) {
          selectAddress(addressData(res.data.addresses[0]));
        }      
      }).catch(error => {
        setPostError(true)
        // console.log(error);
  })
      }

      const cleanAddress = (address) => {
        let addressOne = address[0] && address[0].length ? address[0] : '';
        let addressTwo = address[1] && address[1].length ? `, ${address[1]}` : '';
        let addressThree = address[2] && address[2].length ? `, ${address[2]}` : '';
        let addressFour = address[3] && address[3].length ? `, ${address[3]}` : '';
        let addressFive = address[4] && address[4].length ? `, ${address[4]}` : '';
        return addressOne + addressTwo + addressThree + addressFour + addressFive;
      }

      const addressData = (address) => {
        let addressOne = address[0] && address[0].length ? address[0] : ' ';
        let addressTwo = address[1] && address[1].length ? `, ${address[1]}` : ',';
        let addressThree = address[2] && address[2].length ? `, ${address[2]}` : ',';
        let addressFour = address[3] && address[3].length ? `, ${address[3]}` : ',';
        let addressFive = address[4] && address[4].length ? `, ${address[4]}` : ',';
        return addressOne + addressTwo + addressThree + addressFour + addressFive;
      }

      const disableFind = postcodeLookup.length <= 0;
      
      return (
        <form onSubmit={handleSubmit(ContinueBtn)} className="EC__form EC__stepTwo active">
        <div className="EC__form__wrapper">
        <h2>Address history</h2>
        <h3>Enter your personal address history to cover the last 3 years.</h3>
        {formData.data.addressHistory.length > 0 ? prevAddresses() : null}
        {formData.data.addressHistory.length > 0 ? (
          <div className="AH__prevAddress_title">
            <h3>{selectedMode === 'view'?'' : 'Previous address'}</h3>
          </div>
        ) : null}
          <div className={cx("AH__form", {'hidden' : isFull})}>
        <div className={cx("AH__form__auto", {'hidden': !autoPostcode})}>
          <div className="postcodeLookup__input">
            <label htmlFor="postcodeLookup">
            <span>What is your postcode?</span>
            <input type="text" name="postcodeLookup" value={postcodeLookup} onChange={e => {updatePostcode(e.target.value); postCodeError && setPostError(false);}}/>
            {postCodeError && (<span className="errorMsg">Invalid Postcode</span>)}
          </label>
          <button type="button" className={cx("findPostcode",{'disabled': disableFind})} onClick={() => postcodeLookup.length > 0 ? searchAddress() : null}>Find</button>
          </div>
          <button type="button" className="enterManual" onClick={() => setManualPostcode(false)}>Enter manual address</button>
          {addressResults.length <= 0 ? null : (
          <div className="postcodeLookup__output">
              <span>Address</span>
              <label htmlFor="foundAddress">
                <select name="foundAddress" onChange={e => selectAddress(e.target.value)}>
                {addressResults.addresses.map(address => {
                    if(selectedAddress) {
                      return (
                        <option key={address[0]} selected={selectedAddress === address[0] ? 'selected' : false} value={addressData(address)}>{cleanAddress(address)}</option>
                      )
                    } else {
                      return (
                        <option key={address[0]} value={addressData(address)}>{cleanAddress(address)}</option>
                      )
                    }
                  }
                  )}
                </select>
                {false && (<span className="errorMsg">This field is required.</span>)}
              </label>
          </div>
          )}
          </div>
          <div className={cx("AH__form__manual", {'hidden': autoPostcode})}>
          <label htmlFor="address1">
            <span>Address line 1</span>
            <input type="text" className={cx({'touched' : formState.touched.address1}, {'success' : watchAllFields.address1}, {'error' : errors.address1})} name="address1" ref={register({required:true})} />
            {errors.address1 && (<span className="errorMsg">This field is required.</span>)}
          </label>
          <label htmlFor="address2">
            <span>Address line 2</span>
            <input type="text" className={cx({'touched' : formState.touched.address2}, {'success' : watchAllFields.address2}, {'error' : errors.address2})} name="address2" ref={register({required:false})} />
          </label>
          <label htmlFor="town">
            <span>Town or City</span>
            <input type="text" className={cx({'touched' : formState.touched.town}, {'success' : watchAllFields.town}, {'error' : errors.town})} name="town" ref={register({required:true})} />
            {errors.town && (<span className="errorMsg">This field is required.</span>)}
          </label>
          <label htmlFor="postcode">
            <span>Postcode</span>
            <input type="text" className={cx({'touched' : formState.touched.postcode}, {'success' : watchAllFields.postcode}, {'error' : errors.postcode})} name="postcode" ref={register({required:true})} />
            {errors.postcode && (<span className="errorMsg">This field is required.</span>)}
          </label>
          </div>
          <div className="AddressTime">
          <span>How long have you lived at the address above?</span>
          <label htmlFor="Year">
            <select onChange={(e) => changeTime(e)} name="Year" ref={register({required:true})}>
              <option value='' hidden>Select Year</option>
              <option value='0'>0 year</option>
              <option value='1'>1 year</option>
              <option value='2'>2 years</option>
              <option value='3'>3 years</option>
              <option value='4'>4 years</option>
              <option value='5'>5 years</option>
              <option value='6'>6 years</option>
              <option value='7'>7 years</option>
              <option value='8'>8 years</option>
              <option value='9'>9 years</option>
              <option value='10'>10 years or more</option>
            </select>
            {errors.Year && (<span className="errorMsg">This field is required.</span>)}
          </label>
          <label htmlFor="Month">
            <select name="Month" onChange={(e) => changeTime(e)} ref={register({required:true})}>
              <option value='' hidden>Select Month</option>
              <option value='0'>0 month</option>
              <option value='1'>1 month</option>
              <option value='2'>2 months</option>
              <option value='3'>3 months</option>
              <option value='4'>4 months</option>
              <option value='5'>5 months</option>
              <option value='6'>6 months</option>
              <option value='7'>7 months</option>
              <option value='8'>8 months</option>
              <option value='9'>9 months</option>
              <option value='10'>10 months</option>
              <option value='11'>11 months</option>
              <option value='12'>12 months</option>
            </select>
            {errors.Month && (<span className="errorMsg">This field is required.</span>)}
          </label>
          </div>
        </div>
        <input name='isCurrent' type="hidden" ref={register} />
        {addMore && formData.combinedTimeAtAddress + timeAddress < 36 ? (
        <div className="addMoreAddress">
          <h3>Add previous address</h3>
          <p>As you have lived at the current address for less than 3 years, we need to ask you about your previous address.</p>
          <button type="submit" id="ADD_MORE_ADDRESS" className="addMoreAddress__add">Add previous address</button>
        </div>
        ) : null}
      </div>
      <div className="EC__button__container">
        <button type="button" id="BACK_TO_AY" onClick={(e) => {BackBtn(e, 0)}} className="EC__form__back">Back</button>
        {isFull ? (
          <button type="button" id="CONT_TO_AYB" onClick={(e) => {StepForward()}} className={cx("EC__form__continue", {'disabled' : formData.combinedTimeAtAddress + timeAddress < 36 })}>Continue</button>
        ) : (
          <button type="submit" id="CONT_TO_AYB" className={cx("EC__form__continue", {'disabled' : formData.combinedTimeAtAddress + timeAddress < 36 })}>Continue</button>
        )}
      </div>
      </form>
      );
}


export default withRouter(AddressHistory);

