import axios from 'axios';

import envConfig from './env.config';

/**
 * 
 */
const checkDowntime = async () => {
  let axiosConfig = {
    headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
    }
  };
  const res = await axios.get(envConfig.API.DOMAIN + envConfig.API.PATH.MAINTENANCE_CHECK, axiosConfig);
  // console.log(res);
  return res.data;
}

export { checkDowntime };