import axios from 'axios';
import moment from 'moment';

import envConfig from '../common/env.config';
import constants from '../common/const';

/**
 * 
 */
const experianCheck = async (allData) => {
	const { aboutAssets, aboutApplicant, companySearch, companyDetails } = allData;

	// !!! for testing only
	// const { aboutAssets, aboutApplicant, companySearch, companyDetails } = fakeData;
	// console.log(JSON.stringify(fakeData));


  const isLtd = aboutApplicant?.applicantType === 'Limited Company';
  const isLlp = aboutApplicant?.applicantType === 'Limited Liability Partnership';
  const isSoleTrader = aboutApplicant?.applicantType === 'Sole Trader';
  const isPartnership = aboutApplicant?.applicantType === 'Partnership';
  const isIndividual = aboutApplicant?.applicantType === 'Individual';

	for (const key in aboutAssets) {
		if (typeof aboutAssets[key] === 'string' && aboutAssets[key].indexOf('£') === 0) {
			aboutAssets[key] = parseFloat(aboutAssets[key].substring(1).replace(/,/g,''));
		}
	}
	for (const key in companySearch) {
		if (typeof aboutAssets[key] === 'string' && companySearch[key].indexOf('£')  === 0) {
			companySearch[key] = parseFloat(companySearch[key].substring(1).replace(/,/g,''));
		}
	}
	
	const toNum  = (str) => {
		if (typeof str === 'string' && str.indexOf('£') === 0) {
			return parseFloat(str.substring(1).replace(/,/g,''));
		}

		if (!str) {
			return 0;
		}
		
		return str;
	};

	const getResidentialStatus = () => {
		const mapping = {
			Tenant: 'F',
			'Living with parents': 'P',
			Homeowner: 'O',
			Other: 'X',
		};
		return isIndividual ? mapping[companyDetails.residentialStatus] : 'O';
	};

	const type = aboutAssets.assetType || aboutAssets.mainAssetType;
	const productTypeObj = aboutAssets.assetDescription ? constants[type][aboutAssets.assetDescription] : constants[type];
	

	// 3, assemble the endpoint url
	const url = `${envConfig.API.DOMAIN}${envConfig.API.PATH.EXPERIAN_CHECK}`;

	// 4, prepare the payload
  const payload = {
    "Options": {
      "CustomerType": isLtd || isLlp? "LIM" : isIndividual ? 'CON' :  'NLM',
      "ProductType": "ASST"
    },
    "Application": {
			Term: 46,
      "AutomotiveSpecific": {
        "TotalNumberOfVehicles": aboutAssets.assetSingle === 'yes' ? 1 : +aboutAssets.assetsCount,
        "Vehicles": [{
          "Price": aboutAssets.purchasePrice,
          // "Price": (Math.round(aboutAssets.purchasePrice * 100) / 100).toFixed(2),
          // "DepositAmount": aboutAssets.deposit + '',
          "DepositAmount": (Math.round(aboutAssets.deposit * 100) / 100).toFixed(2),
					Class: productTypeObj?.class + '',
					ProductCodeOrId: productTypeObj?.code,
        }]
      }
    },
  };

	//////////////////////////////////
	// payload.SpareInputFields
	//////////////////////////////////
	if (aboutAssets.outstandingAmount) {
    payload.SpareInputFields = {
      Spare_81_Decimal: toNum(aboutAssets.outstandingAmount),
    };
	}

	//////////////////////////////////
	// payload.BusinessEntity
	//////////////////////////////////
	if (!isIndividual) {
		payload.BusinessEntity = {
      "BusinessReferenceNumber": companySearch.company?.value.BusinessRef,
      "BusinessLegalName": companySearch.company?.value.CommercialName,
      "CompanyTurnover": toNum(companySearch.annualTurnover || companySearch.company?.value.AnnualTurnover),
			BusinessType: isSoleTrader ? 'Sole Trader' : isPartnership ? 'Partnership' : '',
      // "LengthOwnership": {
      //   "Years": "QQ",
      //   "Months": "QQ"
      // }
    }

		if (isLtd || isLlp) {
			payload.BusinessEntity.DateOfIncorporation = companySearch?.company?.value.Identification?.IncorporationDate;
		}
	}

	if (isSoleTrader || isPartnership) {
		const incorporationDateMoment = moment(`${companySearch.incorporationDate?.year}-${companySearch.incorporationDate?.month}-01`);
		const yearDiff = moment(new Date()).diff(incorporationDateMoment, 'year');
		let monthDiff = moment(new Date()).diff(incorporationDateMoment, 'month');
		monthDiff = monthDiff - yearDiff * 12;
		// TODO: months and year should be string
		payload.BusinessEntity.LengthOwnership = {
			Years: yearDiff + '',
			Months: monthDiff + '',
		};
	}

	//////////////////////////////////
	// payload.KeyPersons
	//////////////////////////////////
	if (companyDetails.firstname || companyDetails.addDirector === 'yes') {
		payload.KeyPersons = [];
	}

	if (companyDetails.firstname) {
		payload.KeyPersons.push({
			Person: {
				
				KeyPersonType: (isLtd || isLlp) ? 'D' : isIndividual ? 'A' : 'S',
				Forename: companyDetails.firstname,
				Surname: companyDetails.lastname,
				Title: "",
				MiddleName: "",
				DateOfBirth: `${companyDetails.dob.year}-${companyDetails.dob.month}-${companyDetails.dob.day}`,
			},
			Residencies: [
				{
					ResidentFrom: "2010-01-01",
					ResidentTo: (new Date()).toISOString().split('T')[0],

					// TODO: to update based on the companyDetails.address
					AddressType: "UK",
					Location: {
						Flat: companyDetails.address.flatName,
						HouseNumber: companyDetails.address.houseNumber,
						HouseName: companyDetails.address.houseName,
						Street: companyDetails.address.streetName,
						Street2: companyDetails.address.streetName2,
						Town: companyDetails.address.town,
						County: '',
						District: '',
						Postcode: companyDetails.address.postcode,
						Country: "UK",
					},
				},
			],
			KeyPersonData: {
				EmploymentDetails: {
					EmploymentStatus: "X",
				},
			},
		});
		if (isIndividual) {
			payload.KeyPersons[payload.KeyPersons.length - 1].KeyPersonData.PersonalDetails = {
				ResidentialStatus: getResidentialStatus(),
			};
		}
	}

	if (companyDetails.addDirector === 'yes') {
		payload.KeyPersons.push({
			Person: {
				KeyPersonType: (isLtd || isLlp) ? 'D' : isIndividual ? 'A' : 'S',
				Forename: companyDetails.director1_firstName,
				Surname: companyDetails.director1_lastName,
				Title: "",
				MiddleName: "",
				DateOfBirth: `${companyDetails.director1_dob.year}-${companyDetails.director1_dob.month}-${companyDetails.director1_dob.day}`,
			},
			Residencies: [
				{
					ResidentFrom: "2010-01-01",
					ResidentTo: (new Date()).toISOString().split('T')[0],

					// TODO: to update based on the companyDetails.director1_address
					AddressType: "UK",
					Location: {
						Flat: companyDetails.director1_address.flatName,
						HouseNumber: companyDetails.director1_address.houseNumber,
						HouseName: companyDetails.director1_address.houseName,
						Street: companyDetails.director1_address.streetName,
						Street2: companyDetails.director1_address.streetName2,
						Town: companyDetails.director1_address.town,
						County: '',
						District: '',
						Postcode: companyDetails.director1_address.postcode,
					},
				},
			],
			KeyPersonData: {
				EmploymentDetails: {
					EmploymentStatus: "X",
				},
			},
		});
	}

	if (companyDetails.director2_firstName) {
		payload.KeyPersons.push({
			Person: {
				KeyPersonType: (isLtd || isLlp) ? 'D' : isIndividual ? 'A' : 'S',
				Forename: companyDetails.director2_firstName,
				Surname: companyDetails.director2_lastName,
				Title: "",
				MiddleName: "",
				DateOfBirth: `${companyDetails.director2_dob.year}-${companyDetails.director2_dob.month}-${companyDetails.director2_dob.day}`,
			},
			Residencies: [
				{
					ResidentFrom: "2000-01-01",
					ResidentTo: (new Date()).toISOString().split('T')[0],

					// TODO: to update based on the companyDetails.director2_address
					AddressType: "UK",
					Location: {
						Flat: companyDetails.director2_address.flatName,
						HouseNumber: companyDetails.director2_address.houseNumber,
						HouseName: companyDetails.director2_address.houseName,
						Town: companyDetails.director2_address.town,
						County: '',
						District: '',
						Postcode: companyDetails.director2_address.postcode,
					},
				},
			],
			KeyPersonData: {
				EmploymentDetails: {
					EmploymentStatus: "X",
				},
			},
		});
	}

	let result = {};
  let data;
  try {
		// console.log(new Date());
    const res = await axios.post(url, payload, { 
			// headers 
		});
    // console.log('res.data', res.data);
		data = res.data;
  } catch (err) {
    console.log('err', err);
		throw (err);
    // workaround for localhost with CORS error
    // data = { ...sample_pass };
  }
	
  // 9, process data and return
	switch (data.Response.DecisionDetails.Decision) {
		case 'A':
			result = {
				decision: 'accept'
			};
			break;
		case 'R':
			result = {
				decision: 'refer'
			};
			break;
		case 'X':
			result = {
				decision: 'no-decision'
			};
			break;
		case 'P':
			result = {
				decision: 'not-available'
			};
			break;
		case 'D':
		default:
			result = {
				decision: 'decline'
			};
			break;
	}
	return result;
};

export { experianCheck };
