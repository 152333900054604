import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import cx from 'classnames';

import 'react-select-search/style.css';
import './field-address.scss';
// import envConfig from '../../../common/env.config';
import { addressSearch } from '../../../common/address';

const FieldAddress = ({title, name, defaultValue, defaultShowDetailFields, onChange: parentOnChangeHandler, isSubmitted, formRegister}) => {
  const { 
    formState,
    register,
    setValue,
    getValues,
    setError,
    clearError,
    triggerValidation,
    errors,
  } = formRegister;
  // const watchAllFields = watch({ nest: true });
  const [addressOptions, setAddressOptions] = useState();
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [showSelection, setShowSelection] = useState(false);
  const [showDetailFields, setShowDetailFields] = useState(false);
  const [isManual, setIsManual] = useState(false);

  const [flatNumber] = useState();
  const [houseNumber] = useState();
  const [houseName] = useState();
  const [streetName] = useState();
  const [streetName2] = useState();
  const [town] = useState();

  /**
   * 
   */
  const customStyles = {
    input: (provided, state) => ({
      ...provided,
      'font-weight': 'normal',
      'box-shadow': 'none',
    })
  };

  /**
   * 
   */
  useEffect(() => {
    // console.log('[FieldAddress] useEffect');
  }, []);

  /**
   * 
   */
  const validationHandler = (o) => {
    // console.log(`[validationHandler] isSubmitted`, isSubmitted ? isSubmitted() : 'N.A');

    // 1, Do not validate if the form has not been submitted
    const wasSubmitted = isSubmitted ? isSubmitted() : formState.isSubmitted;
    if (!wasSubmitted) {
      return;
    }
    
    // 2, Validation for all address fields
    const flatNumber = getValues(name + '.flatNumber');
    const houseName = getValues(name + '.houseName');
    const houseNumber = getValues(name + '.houseNumber');
    
    if (houseNumber) {
      clearError(name);
      return;
    }
    if (houseName) {
      clearError(name);
      return;
    }
    if (flatNumber && (houseName || houseNumber)) {
      clearError(name);
      return;
    }

    return 'Flat number, house name or house number are required';
  };

  return (
    <>
    <div className='field-title'>Post code</div>
    <input
      className={cx( 
        'field-address__address-postcode',
        { error: errors[name] }
      )}
      name={name + '.postcode'}
      maxLength={8}
      ref={register({ 
        required: 'Please enter the post code',
        validate: validationHandler,
      })}
    />
    
    {!isManual && 
    <button 
      type='button' 
      className='btn btn-tertiary field-address__btn-search' 
      onClick={async () => {
        const postcodeInput = getValues(`${name}.postcode`);

        // 1, skip if no postcode
        if (!postcodeInput) {
          triggerValidation(`${name}.postcode`);
          return;
        }

        // 2, search address
        let data;
        try {
          data = await addressSearch(postcodeInput);
          if (!data.data || !data.data.length) {
            setError(name, 'postcode', 'Post code is invalid');
            return;
          }

          const newOptions = _.map(data.data, (item, idx) => {
            return {
              value: item,
              label: item.formatted_address.join(','),
            };
          });
          setAddressOptions(newOptions);
          setShowSelection(true);
          // open menu
          setMenuIsOpen(true);
        } catch (err) {
          setError(name, 'postcode', 'Post code is invalid');
        }
      }
    }>Find address</button>}

    {!isManual && 
    <button 
      type='button' 
      className='btn btn-link field-address__btn-switch-manual'
      onClick={() => {
        setIsManual(true);
      }}
    >Enter address manually</button>}
    {isManual && 
    <button 
      type='button' 
      className='btn btn-link field-address__btn-switch-search'
      onClick={() => {
        setIsManual(false);
      }}
    >Search by post code</button>}

    {showSelection && 
    <>
    <div className='field-title'>Please select your address</div>
    <Select
      className='field-address__search'
      components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
      styles={customStyles}
      menuIsOpen={menuIsOpen}
      options={addressOptions}
      onChange={async (o) => {
        setMenuIsOpen(false);
        setShowDetailFields(true);

        const addressObj = o.value;
        if (addressObj) {
          setTimeout(() => {
            setValue(name + '.flatNumber', addressObj.sub_building_number);
            setValue(name + '.houseNumber', addressObj.building_number);
            setValue(name + '.houseName', 
            addressObj.sub_building_name 
              ? addressObj.sub_building_name + (addressObj.building_name ? (', ' + addressObj.building_name) : '')
              : (addressObj.building_name || '')
            );
            setValue(name + '.streetName', addressObj.line_1 + (addressObj.line_2 ? (', ' + addressObj.line_2) : ''));
            setValue(name + '.streetName2', addressObj.line_3 + (addressObj.line_4 ? (', ' + addressObj.line_4) : ''));
            setValue(name + '.town', addressObj.town_or_city);
          }, 100);
        }

        setShowSelection(false);
        clearError(name);
      }}
    />
    </>}
    
    {(showDetailFields || isManual) && 
    <>
    <div className='field-title'>Flat number (optional)</div>
    <input
      className='field-address--input field-address--flat-number'
      name={name + '.flatNumber'}
      value={flatNumber}
      onChange={() => {
        triggerValidation(name + '.flatNumber');
      }}
      ref={register({
        validate: validationHandler
      })}
      maxLength='30'
    />
    <div className='field-title'>House number (optional)</div>
    <input
      className='field-address--input field-address--house-number'
      name={name + '.houseNumber'}
      value={houseNumber}
      onChange={() => {
        triggerValidation(name + '.houseNumber');
      }}
      ref={register({
        validate: validationHandler
      })}
      maxLength='10'
    />
    <div className='field-title'>House name (optional)</div>
    <input
      className='field-address--input field-address--house-name'
      name={name + '.houseName'}
      value={houseName}
      onChange={() => {
        triggerValidation(name + '.houseName');
      }}
      ref={register({
        validate: validationHandler
      })}
      maxLength='50'
    />
    <div className='field-title'>Street name</div>
    <input
      className='field-address--input field-address--street-name'
      name={name + '.streetName'}
      value={streetName}
      ref={register({ required: 'Please enter the street name' })}
      onChange={() => {
        triggerValidation(name + '.streetName');
      }}
      maxLength='40'
    />
    {/* <div className='field-title'>Street 2 name (optional)</div> */}
    <br />
    <input
      className='field-address--input field-address--street-name-2'
      name={name + '.streetName2'}
      value={streetName2}
      ref={register()}
      maxLength='40'
    />
    <div className='field-title'>Town</div>
    <input
      className='field-address--input field-address--town'
      name={name + '.town'}
      value={town}
      ref={register({ required: 'Please enter the town' })}
      onChange={() => {
        triggerValidation(name + '.town');
      }}
      maxLength='30'
    />
    </>}
    </>
  );
};

export default FieldAddress;
