/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
// import { ReactComponent as Help } from "../../assets/images/Help.svg";
// import { ReactComponent as Info } from "../../assets/images/Info.svg";
// import { ReactComponent as Location } from "../../assets/images/Location.svg";
// import Plant from "../../assets/images/plant.png";

// import Facebook from "../../assets/images/FaceBookIcon.png";
// import Twitter from "../../assets/images/Twitter-Icon.png";
// import RSS from "../../assets/images/RSS-Icon.png";
// import LinkedIn from "../../assets/images/Linkedin-Icon.png";

// import FSCS from "../../assets/images/fscs-footer.png";
import "./footer.scss";

// import { NavSections } from "../../constants/Footer";

/*
function NavSection({ title, items }) {
  return (
    <div className="Footer__nav__section">
      <h3>{title}</h3>
      <ul>
        {items.map((item) => (
          <li>
            <a href={`${item.url}`}>{item.name}</a>
          </li>
        ))}
      </ul>
    </div>
  );
}
*/

function Footer() {
  return (
    <div className="ia__footer ia__footer--responsive">
      {/* <link rel="stylesheet" href="/etc.clientlibs/responsive/components/foundation/iafooter/clientlibs/clientlib_ia_footer_lombard-_52116cc441fb90cd2ca76a1f6ec77915.min.css" type="text/css"> */}

      <footer className="flex-rw">
        <div className="iah__container">
          <nav
            aria-label="footer navigation"
            className=" listtwohide  footer__premier"
          >
            <div className="footer__list__one">
              <ul>
                <li>
                  <a className="footer__header" target="_self">
                    <img role="presentation" alt="" />
                    <span></span>
                  </a>
                </li>
              </ul>
            </div>

            <div className="footer__list__three">
              <ul>
                <li>
                  <a href="https://lombard.co.uk/about-us.html" 
                    target="_blank" 
                    rel="noopener noreferrer"
                  >
                    About Us{" "}
                  </a>
                </li>

                <li>
                  <a
                    href="https://lombard.co.uk/privacy-cookies-policy.html"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Privacy &amp; Cookies{" "}
                  </a>
                </li>

                <li>
                  <a
                    href="https://lombard.co.uk/toolbar/accessibility.html"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Accessibility{" "}
                  </a>
                </li>

                <li>
                  <a
                    href="https://lombard.co.uk/toolbar/terms-conditions.html"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Terms and Conditions{" "}
                  </a>
                </li>

                <li>
                  <a
                    href="https://lombard.co.uk/toolbar/careers.html"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Careers{" "}
                  </a>
                </li>

                <li>
                  <a
                    href="https://lombard.co.uk/toolbar/social-media-house-rules.html"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Social Media House Rules{" "}
                  </a>
                </li>

                <li>
                  <a
                    href="https://lombard.co.uk/toolbar/site-map.html"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Site Map{" "}
                  </a>
                </li>

                <li>
                  <a
                    href="https://www.rbs.com/rbs/sustainability/responsible-business/business-human-rights-modern-slavery.html"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Modern Slavery Act{" "}
                  </a>
                </li>
              </ul>
            </div>

            <div className="footer__copytext">
              <p>
                ©{" "}
                <script type="text/javascript">
                  {" "}
                  document.write(new Date().getFullYear());{" "}
                </script>
                2022 Lombard North Central plc. Registered office: 250
                Bishopsgate, London, EC2M 4AA
              </p>
            </div>
          </nav>
        </div>
      </footer>

      {/* <script src="/etc.clientlibs/responsive/components/foundation/footer/clientlibs/clientlib_footer_base-_7ad603327c300d88c7efdd686a27d4b6.min.js" defer=""></script> */}

      {/* <script src="/etc.clientlibs/responsive/components/foundation/iafooter/clientlibs/clientlib_ia_footer_base-_7ad603327c300d88c7efdd686a27d4b6.min.js" defer=""></script> */}
    </div>
    // <div className="Footer">
    //   <div className="Footer__top">
    //     <a href="https://www.natwest.com/business/support-centre.html" target="_blank"><Help/><span>Support Centre</span></a>
    //     <a href="https://www.natwest.com/business/green-banking.html" target="_blank">
    //     <img src={Plant} />
    //       <span>Green banking</span></a>
    //     <a href="https://www.natwest.com/personal/service-status.html" target="_blank"><Info/><span>Service status</span></a>
    //   </div>
    //   <div className="Footer__nav">
    //     { NavSections.map(navSection => <NavSection {...navSection} />) }
    //   </div>
    //   <div className="Footer__links">
    //       <ul>
    //         <li><a href="http://www.natwest.com/global/cookieprivacy.ashx?channel=business" target="_blank">Privacy & Cookies</a></li>
    //         <li><a href="https://www.natwest.com/global/website-terms-conditions-and-fscs.html?gclid=EAIaIQobChMIqt6i7ubp5wIVFODtCh14nQJoEAAYAiAAEgLN1_D_BwE&gclsrc=aw.ds" target="_blank">Website T&Cs & FSCS</a></li>
    //         <li><a href="https://www.natwest.com/business/accessibility.html?gclid=EAIaIQobChMIqt6i7ubp5wIVFODtCh14nQJoEAAYAiAAEgLN1_D_BwE&gclsrc=aw.ds" target="_blank">Accessibility</a></li>
    //         <li><a href="https://jobs.rbs.com/" target="_blank">Careers</a></li>
    //         <li><a href="https://www.rbs.com/rbs/sustainability/responsible-business/business-human-rights-modern-slavery.html?extcam=N_PPC_Google_Bus_Loan_66408733431_319167287005_%2Bnatwest%20%2Bsmall%20%2Bbusiness%20%2Bloan&gclid=EAIaIQobChMIqt6i7ubp5wIVFODtCh14nQJoEAAYAiAAEgLN1_D_BwE&gclsrc=aw.ds" target="_blank">Modern Slavery Act</a></li>
    //       </ul>
    //   </div>
    //   <div className="Footer__external">
    //     <ul>
    //       <li><a href="https://www.facebook.com/NatWest"><img src={Facebook} alt="facebook"/></a></li>
    //       <li><a href="https://twitter.com/NatWest_Help"><img src={Twitter} alt="facebook"/></a></li>
    //       <li><a href="https://www.rbs.com/rbs/our-brands/natwest.html"><img src={RSS} alt="facebook"/></a></li>
    //       <li><a href="https://www.linkedin.com/company/natwest"><img src={LinkedIn} alt="facebook"/></a></li>
    //     </ul>
    //     <a href="https://personal.natwest.com/content/dam/natwest/business/current-accounts/documents/FSCS_leaflet.pdf"><img src={FSCS} alt='FSCS'/></a>
    //   </div>
    //   <p className="Footer__copyright">Copyright © National Westminster Bank plc 2020</p>
    // </div>
  );
}

export default Footer;
