import Axios from 'axios';

import envConfig from '../common/env.config';

/**
 * 
 */
const addressSearch = async (str) => {
  const url = `${envConfig.API.DOMAIN}${envConfig.API.PATH.ADDRESS_SEARCH}?str=${str}`;
  const res = await Axios.get(url);
  return res.data;
};

const addressDetails = async (postcode, idx) => {
  const url = `${envConfig.API.DOMAIN}${envConfig.API.PATH.ADDRESS_DETAILS}?postcode=${postcode}&idx=${idx}`;
  const res = await Axios.get(url);
  return res.data;
};

export { addressDetails, addressSearch };