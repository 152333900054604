import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';

import './field-dropdown.scss';
import cx from 'classnames';

const FieldDropdown = ({ name, options, onChange, validate: parentValidateHandler, formRegister }) => {
  const {
    watch,
    setValue,
    register,
    errors,
    triggerValidation,
  } = formRegister;
  const watchAllFields = watch({ nest: true });

  const validationHandler = {
    required: !parentValidateHandler ? 'This field is required' : false,
    validate: val => {
      // console.log(val);
      if (parentValidateHandler) {
        return parentValidateHandler(val);
      }
    }
  };
  
  return (
    <>
    <input name={name} 
    ref={register(validationHandler
    // { 
    //   required: 'Please select your residential status',
    //   validate: val => {
    //     // console.log(val);
    //     if (parentValidateHandler) {
    //       return parentValidateHandler(val);
    //     }
    //   }  
    // }
    )} />
    <Dropdown
      onSelect={(eventKey, e) => {
        if (onChange) {
          onChange(e.target?.innerText);
        }
        triggerValidation(name);
      }}
    >
      <Dropdown.Toggle variant='success' id='dropdown-basic'
      className={cx( 
        { error: errors[name] }
      )}
      >{watchAllFields[name] || 'Please select'}</Dropdown.Toggle>
      <Dropdown.Menu>
        {options && options.map(option => {
          const value = option.value || option;
          const label = option.label || option;
          return (
            <Dropdown.Item key={value} onClick={() => { 
              setValue(name, value); 
            }}>{label}</Dropdown.Item>)
        })}
      </Dropdown.Menu>
    </Dropdown>
    </>
  );
};

export default FieldDropdown;






  // <div className={cx(
  //   'field-block',
  //   'field-select',
  //   size ? 'field-select--' + size : ''
  // )}>
  //   <label>
  //     {title && 
  //     <div className='field-title'>{title}</div>}

  //     {subtitle && 
  //     <div className='field-subtitle'><Info /><span>{subtitle}</span></div>}
      
  //     <input name={name} ref={register({ required: true })} />
      
  //     <div className="field-content">
  //   <Dropdown>
  //   <Dropdown.Toggle variant='success' id='dropdown-basic'>{watchAllFields[name] || 'Please select'}</Dropdown.Toggle>
  //   <Dropdown.Menu>
  //     {options && options.map(option => {
  //       const value = option.value || option;
  //       const label = option.label || option;
  //       return (<Dropdown.Item onClick={() => { setValue(name, value) }}>{label}</Dropdown.Item>)
  //     })}
  //   </Dropdown.Menu>
  // </Dropdown>
  //     </div>

  //     {errors[name] && 
  //     <span className="errorMsg">This field is required</span>}
  //   </label>
  // </div>