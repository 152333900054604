import React, {useState, useEffect, useContext} from 'react';
import Search from '../Search/Search'
import Navigation from '../../constants/Navigation'
import './DesktopNav.scss';

import {Context} from '../../stores/store'


  const DesktopNavigation = ({toggleNav}) => {
    const [state, dispatch] = useContext(Context);
    const [navigation] = useState(Navigation);
    const [width, setWidth] = useState(window.innerWidth)

    const { navData } = state;
    const { activeItem } = navData;

    const widthStyle = {
      width: `${width}px`
    }

    useEffect(() => {
      const handleResize = () => setWidth(window.innerWidth);
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []); // <-- empty array

    const NavigationLevels = (parentKey) => {
      const length = Object.keys(navigation[parentKey]).length > 0;
      const content = length ? (
        <div className="DN__Links-2">
        <ul>
      {Object.keys(navigation[parentKey]).map((key, index) => (
        <li key={index+key}>
        <button type="button"
          className={activeItem.includes(key) ? 'DN__Active' : null}
           onClick={() => {
            if(Object.keys(navigation[parentKey][key]).length > 0 && typeof(Object.keys(navigation[parentKey][key][0]) === 'object')) 
            ToggleLevel2(parentKey, key)
            else if(Object.keys(navigation[parentKey][key]).length > 0 && typeof(Object.keys(navigation[parentKey][key][0]) === 'string'))
            window.location.href = navigation[parentKey][key]['link']
          }}   
             >{key}</button>
             {/* {console.error(Object.keys(navigation[parentKey]).length)} */}
        {Object.keys(navigation[parentKey][key]).length > 0 ? 
        (
          <div className={Object.keys(navigation[parentKey]).length > 1? "DN__Links-3": "DN__Links-3 DN__Links-3-top"} style={widthStyle}>
            {/* {Object.values(navigation[parentKey][key])} */}
          {Object.values(navigation[parentKey][key]).map((value, index) => (    
            value.link ? (
              <a className="a" key={index+key} href={value.link}>{value.title}</a>
            ) :  
            value.title !== ''?
            <div className="DN__Section" key={index+key}>
            <h3>{value.title}</h3>
            {Object.values(value.links).map((value, index) => (
              <a className="b" key={index+key} href={value.link}>{value.title}</a>
            ))}
          </div>
           : null
          ))}
          </div>
        ):  null}
        </li>
      ))}
    </ul>
    </div>
      ) : null;

      return content;
    }

    const ToggleLevel1 = (key) => {
      if (activeItem.includes(key)) {        
        dispatch({type: 'TOGGLE_DN', payload: []});
        document.body.classList.remove('modal-open');
      } else {
        dispatch({type: 'TOGGLE_DN', payload: [key]});
        document.body.classList.add('modal-open');
      }
    }

    const ToggleLevel2 = (parentKey, Key) => {
      if (activeItem.includes(parentKey)) {
        if(Object.values(navigation[parentKey][Key]).length === 1){
          window.location.href = navigation[parentKey][Key][0].link;
          return;
        }
        if(activeItem.includes(Key)) {
          dispatch({type: 'TOGGLE_DN', payload: [parentKey]});
        } else {
          dispatch({type: 'TOGGLE_DN', payload: [parentKey, Key]});

        }
      } 
    }

      return (
        <div className="DN">
          {navigation !== null? (
            <ul className="DN__Links">
            {Object.keys(navigation).map((key, index) => (
              <li key={index+key}>
                <button 
                  className={activeItem.includes(key) ? 'DN__Active' : null} 
                  type="button" 
                  onClick={() => {
                    if(navigation[key]){
                      if(Object.keys(navigation[key]) && navigation[key]['link']){
                          window.location.href = navigation[key]['link']
                          return;
                        }
                      }
                      ToggleLevel1(key)
                    }
                  }
                  >
                    {key}
                  </button>
                {NavigationLevels(key)}
              </li>
            ))}
            {/* <li>
              <a href='https://www.natwest.com/business/support-centre/service-status/coronavirus.html'>Coronavirus</a>
            </li> */}
          </ul>
          ):null}
            <div className="DN__Search">
                <Search />
            </div>
        </div>
      );
}


export default DesktopNavigation;
