import React, {createContext, useReducer} from "react";
import { fakeFormData } from "../common/const";
import Reducer from './reducer'

// Default data to be displayed on the calculator can be set here
const initialState = {
  calculator: {
    monthlyRepayments: "276.41",
    interestRate: "8.9%",
    APR: "9.4%",
    Total: "10,000",
    loanAmount: 10000,
    years: 1,
    months: 10,
  },
  navData: {
    activeItem: [],
  },
  formData: {
    status: "None",
    combinedTimeAtAddress: 0,
    data2: fakeFormData,
    data: {
      aboutAssets: {},
      aboutApplicant: {},
      companySearch: {},
      companyDetails: {},
    },
    steps: [
      {
        id: 'about-assets',
        isComplete: false,
        key: 'aboutAssets',
      },
      {
        id: 'about-applicant',
        isComplete: false,
        key: 'aboutApplicant',
      },
      {
        id: 'company-search',
        isComplete: false,
        key: 'companySearch',
      },
      {
        id: 'company-details',
        isComplete: false,
        key: 'companyDetails',
      },
    ],
    sectionsCompelete: 4,
    activeStep: "about-you", // 'about-you' || 'address' || 'about-your-business' || 'confirmation'
  },
};

const Store = ({children}) => {
    const [state, dispatch] = useReducer(Reducer, initialState);
    return (
        <Context.Provider value={[state, dispatch]}>
            {children}
        </Context.Provider>
    )
};

export const Context = createContext(initialState);
export default Store;
