import React ,{useContext, useState} from 'react';

import { Link } from "react-router-dom";
import CalculatorRates from '../../constants/CalculatorRates';

import cx from 'classnames';

import { ReactComponent as Tick } from '../../assets/images/tick.svg';
import Pdf from '../../assets/documents/FSCS_leaflet.pdf'

import {Context} from '../../stores/store'

import './EligibilityLanding.scss';
import NumberFormat from 'react-number-format';

import { ReactComponent as Edit } from '../../assets/images/pencil-edit-button.svg';

const EligibilityLanding = () => {
    const [state, dispatch] = useContext(Context);
    const { calculator } = state;
    const [editAmount, setAmount] = useState(false);
    const [loanYears, changeYear] = useState(state.calculator.years);
    const [loanMonths, changeMonth] = useState(state.calculator.months);
    const [previousLoanAmount, setPrevAmount] = useState(state.calculator.loanAmount);
    const [previousTermYear, setPrevYear] = useState(state.calculator.years);
    const [previousTermMonths, setPrevMonths] = useState(state.calculator.months);
    const [recentLoanAmount, setRecentAmount] = useState(state.calculator.loanAmount);
    const [recentTermYear, setRecentYear] = useState(state.calculator.years);
    const [recentTermMonths, setRecentMonths] = useState(state.calculator.months);
    const [editTerm, setTerm] = useState(false);
    const [amountState, amountStateSet] = useState('untouched') // 'untouched' || 'touched' || 'error' || 'success'
    const [yearState, yearStateSet] = useState('untouched') // 'untouched' || 'touched' || 'error' || 'success'
    const [monthState, monthStateSet] = useState('untouched') // 'untouched' || 'touched' || 'error' || 'success'
    const {monthlyRepayments, interestRate, APR, Total, loanAmount, months, years} = calculator;
    
    const getLoanTermInMonths = () => {
      return state.calculator.years * 12 + state.calculator.months;
    }

    const resetLoanAmount = () => {
      dispatch({type: 'UPDATE_LOAN_AMOUNT', payload: previousLoanAmount});
      updateCalculator(previousLoanAmount, getLoanTermInMonths());
    }

    const resetTerm = () => {
      const yearsToMonth = previousTermYear * 12;
      const final = yearsToMonth + previousTermMonths;
      dispatch({type: 'UPDATE_LOAN_LENGTH_MONTHS', payload: previousTermMonths});
      dispatch({type: 'UPDATE_LOAN_LENGTH_YEARS', payload: previousTermYear});
      updateCalculator(state.calculator.loanAmount, final);
    }

    const setLoanAmount = () => {
      dispatch({type: 'UPDATE_LOAN_AMOUNT', payload: recentLoanAmount});
      updateCalculator(recentLoanAmount, setRecentAmount());
      setPrevAmount(recentLoanAmount);
    }

    const setTermPeriod = () => {
      const yearsToMonth = recentTermYear * 12;
      const final = yearsToMonth + recentTermMonths;
      dispatch({type: 'UPDATE_LOAN_LENGTH_MONTHS', payload: recentTermMonths});
      dispatch({type: 'UPDATE_LOAN_LENGTH_YEARS', payload: recentTermYear});
      updateCalculator(state.calculator.loanAmount, final);
      setPrevMonths(recentTermMonths);
      setPrevYear(recentTermYear);
    }

    const onChangeAmount = (value) => {
      if(value === '') {
        dispatch({type: 'UPDATE_LOAN_AMOUNT', payload: value});
        setRecentAmount(value);
        amountStateSet('error');
      } else {
        if (value >= 1000 && value <= 50000) {
          amountStateSet('success');
        } else {
          amountStateSet('error');
        }
      dispatch({type: 'UPDATE_LOAN_AMOUNT', payload: value});
      setRecentAmount(value);
      updateCalculator(value, getLoanTermInMonths());
      }
    }

    const calculateTotal = (months, amount, interest) => {
      const monthlyInterest = (interest / 100.00) / 12;
      const total = amount * (monthlyInterest/(1 - Math.pow(1+monthlyInterest, -months)));
      return total;
    }

    const onChangeMonths = (e) => {      
      if(e.target.value === '' || isNaN(e.target.value)) {
        changeMonth('');
        monthStateSet('error');
      } else {
        if (parseInt(e.target.value) >= 0 && parseInt(e.target.value) <= 12) {
          monthStateSet('success');
        } else {
          monthStateSet('error');
        }
      const value = parseInt(e.target.value);
      const correctedValue = value <= 11 ? value : parseInt(value / 10);
      const yearsToMonth = loanYears * 12;
      const final = correctedValue + yearsToMonth;
      // dispatch({type: 'UPDATE_LOAN_LENGTH_MONTHS', payload: value});
      setRecentMonths(correctedValue);
      changeMonth(correctedValue);
      updateCalculator(state.calculator.loanAmount, final);}
    }
  
    const onChangeYears = (e) => {
      if(e.target.value === '' || isNaN(e.target.value)) {
        changeYear('');
        yearStateSet('error');
      } else {
        if (parseInt(e.target.value) >= 1 && parseInt(e.target.value) <= 10) {
          yearStateSet('success');
        } else {
          yearStateSet('error');
          changeYear(1)
        }
        const value = parseInt(e.target.value);
        const correctedValue = parseInt(e.target.value <=10 ? e.target.value : e.target.value / 10)
        let final = null;
        if(value >= 10) {
          final = correctedValue;
          // dispatch({type: 'UPDATE_LOAN_LENGTH_MONTHS', payload: 0});
          changeMonth(0);
        } else {
          final = (correctedValue * 12) + loanMonths
  
        }
        changeYear(parseInt(e.target.value <=10 ? e.target.value : e.target.value / 10));
        dispatch({type: 'UPDATE_LOAN_LENGTH_YEARS', payload: correctedValue});
        setRecentYear(correctedValue);
        updateCalculator(state.calculator.loanAmount, final);
      }
    }

    const updateCalculator = (amount, term) => {
      for(var i=0; i < CalculatorRates.length; i++) {
        if (amount >= CalculatorRates[i].minValue && amount <= CalculatorRates[i].maxValue) {
          for (var o=0; o < CalculatorRates[i].rates.length; o++)
          {
            if (term >= CalculatorRates[i].rates[o].minMonths && term <= CalculatorRates[i].rates[o].maxMonths) {
              const rate = CalculatorRates[i].rates[o].Interest;
              const total = calculateTotal(term, amount, rate);
              const result = {
                repayments: total.toFixed(2),
                total: (total * term).toFixed(2),
                interestRate: `${rate}%`,
                APR: `${CalculatorRates[i].rates[o].APR}%`
              }
              dispatch({type: 'UPDATE_RESULTS', payload: result})
            }
          }
        }
      }
    }

    return (
      <div className="EL">
      <div className="EL__heading">
        <div className="EL__wrapper">
        <div className="EL__flex">
          <div className="EL__heading__title">
          <h1>Quick eligibility check</h1>
          </div>        
        </div>
        </div>
      </div>
      <div className="EL__content">
        <div className="EL__wrapper">
        <div className="EL__flex">
          <div className="EL__content__checklist__wrapper">
        <h3 className="EL__content__title">Check your likelihood of being approved for a business loan <strong>without affecting your credit rating</strong>. We check your details with a soft credit search.</h3>
        <h3 className="EL__content__title--large">Have the following information to hand:</h3>
        <ul className="EL__content__checklist">
          <li><Tick/>Personal address details from the last 3 years</li>
          <li><Tick/>Annual business turnover</li>
          <li><Tick/>Any existing overdraft amount</li>
          <li><Tick/>Monthly borrowing repayments</li>
          <li className="EL__btn__item"><Link className="EL__btn EL__desktop" to="/business/loans-and-finance/eligibility-checker/about-you" id="START_CHECK__DESKTOP">Start eligibility check</Link></li>
        </ul>
        </div>
        <div className="EL__Calculator__output">
          <h2>Representative example</h2>
          <div className="EL__Calculator__output_single">
              { editAmount ? 
                (
                  <div>
                  <div className="EL__edit__container">
                    <span className="CO__label">Loan amount</span>
                    <div className="EL__edit__button__container">
                      <button type="button" className="EL__edit__link" onClick={(e) => {setLoanAmount(); setAmount(false)}}>Save</button>
                      <div className="EL__vertical__line"/>
                      <button type="button" className="EL__close__link" onClick={(e) => {resetLoanAmount(); setAmount(false)}}>Cancel</button>
                    </div>
                  </div>
                  <div className="CI__full__wrap prefixed-input">
                  <NumberFormat
                    type="text"
                    thousandSeparator={true}
                    className={cx(amountState)} 
                    name="loanAmount"
                    value={state.calculator.loanAmount}
                    onFocus={() => amountStateSet('touched')}
                    onBlur={() => amountStateSet('untouched')}
                    onValueChange={(values) => {
                    const {value} = values;
                    onChangeAmount(value);}}
                      />
                      {amountState === 'error' && (<span className="errorMsg">Please enter a value between 1,000 and 50,000</span>)}
                    </div>
                    </div>
                  ) 
                : (
                <div>
                  <div className="EL__edit__container">
                    <span className="CO__label">Loan amount</span>
                    <button type="button" className="EL__edit__link" onClick={(e) => {setAmount(true)}}><Edit />Edit</button>
                  </div>
                  <span className="CO__value">£{loanAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                </div>)
              }
          </div>
          <div className="EL__Calculator__output_single">
          { editTerm ? 
            (
              <div>
              <div className="EL__edit__container">
                <span className="CO__label">Loan term</span>
                <div className="EL__edit__button__container">
                  <button type="button" className="EL__edit__link" onClick={(e) => {setTermPeriod(); setTerm(false)}}>Save</button>
                  <div className="EL__vertical__line"/>
                  <button type="button" className="EL__close__link" onClick={(e) => {resetTerm(); setTerm(false)}}>Cancel</button>
                </div>
              </div>
              <div className="CI__Wrap">
                <label htmlFor='loanYears'>
                  {loanYears !== '' ? <span className={loanYears === 10 ? 'padding prepend' : 'prepend'}>{loanYears === 1 ? 'Year' : 'Years'}</span> : null}
                <input 
                  className={cx("CI__full", yearState)}
                  type="text" 
                  name="loanYears"
                  value={loanYears}
                  maxLength="2"
                  onFocus={() => yearStateSet('touched')}
                  onBlur={() => yearStateSet('untouched')}
                  onChange={(e) => onChangeYears(e)}
                    />
                  {yearState === 'error' && (<span className="errorMsg">Please enter a value between 1 and 10</span>)}
                </label>
                <label className={loanYears === 10 ? 'disabled' : ''} htmlFor='loanMonths'>
                  {loanMonths !== '' ? <span className={loanMonths >= 10 ? 'padding prepend' : 'prepend'}>{loanMonths === 1 ? 'Month' : 'Months'}</span> : null}
                <input 
                  className={cx('CI__full', monthState,{'disabled': loanYears === 10})} 
                  type="text" 
                  name="loanMonths"
                  value={loanMonths}
                  maxLength="2"
                  onFocus={() => monthStateSet('touched')}
                  onBlur={() => monthStateSet('untouched')}
                  onChange={(e) => onChangeMonths(e)}
                    />
                  {monthState === 'error' && (<span className="errorMsg">Please enter a value between 0 and 11</span>)}
                  </label>
                </div>
              </div>
              ) 
            : (
              <div>
                <div className="EL__edit__container">
                  <span className="CO__label">Loan term</span>
                  <button type="button" className="EL__edit__link" onClick={(e) => {setTerm(true)}}><Edit />Edit</button>
                </div>
                <span className="CO__value">{years} years, {months} months</span>
              </div>)
          }
          </div>
          <div className="EL__Calculator__output_single">
              <span className="CO__label">Monthly repayments</span>
              <span className="CO__value">£{monthlyRepayments.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
          </div>
          <div className="EL__Calculator__output_single">
              <span className="CO__label">Interest rate % (fixed)</span>
              <span className="CO__value">{parseFloat(interestRate).toFixed(2)}%</span>
          </div>
          <div className="EL__Calculator__output_single">
              <span className="CO__label">Representative APR (variable)</span>
              <span className="CO__value">{parseFloat(APR).toFixed(2)}% <span>APR</span></span>
          </div>
          <div className="EL__Calculator__output_single">
              <span className="CO__label">Total repayment</span>
              <span className="CO__value">£{Total.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
          </div>
          <p className="EL__Calculator__output_info">
          92.31% of SME customers received an end quote that was the same, better or no more than 10% above their indicative quote.
          </p>
      </div>
        <Link className="EL__btn EL__mobile" to="/business/loans-and-finance/eligibility-checker/about-you" id="START_CHECK__DESKTOP">Start eligibility check</Link>
        </div>
      </div>
      </div>
      <div className="EL__bottom__wrapper">
      <div className="EL__bottom">
        <p>
          <strong>Confirming your agreement:</strong><br />By continuing, you confirm that you have read and understood how we may use your information in the ways described above and are happy to proceed.
          You have no obligation to apply. <br /><br />This tool only offers an indication of likelihood. You must be over 18 years of age to apply for these products. Please read the following information before continuing:        </p>
        <a id="FSCS_EL" href={Pdf}>Financial Services Compensation Scheme Guide (PDF, 3MB)</a>
        <a id="HOW_WE_USE_INFO_EL" href="https://www.natwest.com/business/how-we-use-your-information.html">How we use your information</a>
      </div>
      </div>
    </div>
    );
  };

export default EligibilityLanding;