import _ from 'lodash';
import React, {useContext, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { withRouter } from 'react-router-dom';

import { Context } from '../../../stores/store';
import { navToNext, calcNewFormData } from '../../../common/navigate';
import Field from '../../../components/fields/field';
import { toFormatedNumStr, toNum } from '../../../common/util';
import { ReactComponent as IconError } from "../../../assets/images-new/error.svg";
import envConfig from '../../../common/env.config';
import { trackApplicationStep } from '../../../common/tagging';


const AboutAssets = ({ history }) => {
  const [state, dispatch] = useContext(Context);
  const formRegister = useForm({
    mode: 'onChange',
    // mode: 'onSubmit',
  });
  const {
    handleSubmit, 
    watch,
    setValue,
    getValues,
    triggerValidation,
  } = formRegister;
  const [loaded, isLoaded] = useState(false);
  const [potentialBorrowingError, setPotentialBorrowingError] = useState(false);
  const watchAllFields = watch({ nest: true });
  const attemptedToSubmit = useRef(false);
  const currentStepIdx = 0;
  const pageStateData = _.get(state, 'formData.data.aboutAssets');

  const assetTypeOptions = ['Car', 'Van', 'Other Vehicles', 'Technology', 'Construction', 'Production', 'Farming', 'More'];

  const assetSubtypeOptions = {
    Car: [],
    Van: [],
    'Other Vehicles': ['Bus', 'Coach', 'Mini-bus', 'Trailer'],
    Technology: [
      '16/35mm film camera and telecine/datacine',
      'Audio - outside broadcast equipment',
      'Film/video camera lens',
      'Film/video lighting and grip equipment',
      'Loudspeaker',
    ],
    Construction: [
      'Access Platform',
      'Backhoe Loader',
      'Bulldozer',
      'Crane',
      'Dump truck',
      'Forklift',
      'Hydraulic Excavator',
      'Overhead and gantry crane',
      'Telehandler',
      'Wheel loader',
    ],
    Production: [
      'Expanded polystyrene equipment',
      'Foam moulding equipment',
      'Laser cutter',
      'Packaging conveyor',
      'Packaging line',
      'Sawing machine',
      'Sewing machine/embroidery machine',
      'Wrapping machine',
    ],
    Farming: [
      'All-terrain vehicle',
      'Chipper',
      'Combine harvester',
      'Demountable sprayer/sprayer',
      'Hedger/grass trimmer',
      'Milking machinery',
      'Self-propelled sprayer',
      'Tractor',
      'Utility vehicle',
    ],
    More: [
      'Robotic and spot welding machine',
      'Sheet metal press brake',
      'Wood lathe',
      'Woodwork drilling machine',
    ],
  };



  /**
   * 
   */
  useEffect(() => {
    if (loaded) {
      return;
    }

    /****** first load ******/
    // 1, tagging
    trackApplicationStep('about-assets');

    // 2, load existing data to form
    const arr = [];
    for (const key in pageStateData) {
      arr.push({ [key]: pageStateData[key] });
    }
    // set default value for assetSingle
    if (!pageStateData.assetSingle) {
      arr.push({ assetSingle: 'yes' });
    }
    setValue(arr);
    setTimeout(() => { setValue(arr); }, 10);
    setTimeout(() => { setValue(arr); }, 20);


    /****** first load completed ******/
    isLoaded(true);
  }, [state, loaded, setValue, pageStateData]);


  /**
   *  
   */
  const submitHandler = (data) => {
    // console.log(`[onSubmit] ${JSON.stringify(data)}`);

    const outstandingAmount = toNum(getValues('outstandingAmount')) || 0;
    const purchasePrice = toNum(getValues('purchasePrice')) || 0;
    const deposit = toNum(getValues('deposit')) || 0;
    const assetType = toNum(getValues('assetType') || getValues('mainAssetType'));
    // console.log(outstandingAmount, purchasePrice, outstandingAmount + purchasePrice);

    if (
      (outstandingAmount + purchasePrice - (assetType === 'Car' ? deposit : deposit - purchasePrice * 0.2)) > 250000
    ) {
      setPotentialBorrowingError(true);
      return;
    } else {
      setPotentialBorrowingError(false);
    }

    // update store
    const newFormData = calcNewFormData(state.formData, currentStepIdx, data);
    dispatch({ type: 'FORM_STEP', payload: newFormData });

    // nav to next step
    navToNext(currentStepIdx, state.formData.steps, history);
  }

  return (
    <>
    {envConfig.debug && <div className='log-block log-block-2'><p>{JSON.stringify(watchAllFields)}</p></div>}

    <div className='ec__banner row'>
      <div className='ec__banner-inner col-lg-8 col-lg-offset-2'>
        <div className="ec__banner-title">Eligibility Checker</div>
      </div>
    </div>

    <div className='ec__main row'>
      <div className='ec__main-inner col-lg-8 col-lg-offset-2'>
        <form onSubmit={handleSubmit(submitHandler)} className="ec--form ec--step-one active">
          <div className="ec--form-wrapper">

            <p>Check your likelihood of being approved for asset financing, without affecting your credit score.</p>
            <br/>
            <p>It only takes a few minutes to check your eligibility for asset financing. Your eligibility is indicative based on information you have given us. This means we can’t guarantee you’ll be offered asset financing.</p>

            <div className='ec--step-progress'><b>Step 1</b> of 4</div>
            <div className='ec--step-progress-bar step-1'>
              <div className='ec--step-progress-bar-dot'></div>
              <div className='ec--step-progress-bar-dot'></div>
              <div className='ec--step-progress-bar-dot'></div>
              <div className='ec--step-progress-bar-step'></div>
            </div>
            <div className='ec--step-title'><h2>About the asset</h2></div>


            <Field
              type='field-radio'
              name='existingCustomer'
              title='Are you an existing Lombard customer?'
              formRegister={formRegister}
              value={pageStateData.existingCustomer}
            />

            {watchAllFields.existingCustomer === 'yes' &&
            <Field
              type='field-amount'
              name='outstandingAmount'
              title={`What's your outstanding borrowing with us?`}
              formRegister={formRegister}
              validate={(val) => {
                if (val == null || val === '') {
                  return 'Please enter the outstanding borrowing amount';
                }
              }}
              onChange={(v) => {
                // only trigger validation after changes has been made/confirmed
                setTimeout(() => {
                  triggerValidation('deposit');
                }, 0);
              }}
            />}

            <Field
              type='field-radio'
              subType='field-radio-button'
              name='assetSingle'
              title={`Do you want asset financing for a single asset or multiple assets?`}
              options={[
                { value: 'yes', label: 'Single asset' },
                { value: 'no', label: 'Multiple assets' },
              ]}
              formRegister={formRegister}
            />
            
            {watchAllFields.assetSingle === 'no' &&
            <>
            <Field
              type='field-dropdown' 
              name='assetsCount'
              size='sm'
              title='How many assets do you want to finance?'
              subtitle='For multiple assets, you can select up to 5' 
              options={[1, 2, 3, 4, 5]}
              formRegister={formRegister}
            />

            <Field 
              type='field-dropdown' 
              name='mainAssetType'
              size='lg'
              title='What is the main type of asset that you want to finance?'
              subtitle='For example, if you would like help financing 3 cars and a van, your main asset type would be a car. For equal numbers of asset type such as 2 cars and 2 vans, you can select either asset.'
              options={assetTypeOptions}
              formRegister={formRegister}
              onChange={(val) => {
                if (val === watchAllFields.mainAssetType) {
                  return;
                }
                setValue('assetDescription', null);

                // force validate other "amount" fields
                triggerValidation('deposit');
              }}
            />
            </>}

            {watchAllFields.assetSingle === 'yes' &&
            <Field
              type='field-dropdown' 
              name='assetType'
              size='lg'
              title='Type of asset'
              options={assetTypeOptions}
              formRegister={formRegister}
              onChange={(val) => {
                if (val === watchAllFields.assetType) {
                  return;
                }
                setValue('assetDescription', null);

                // force validate other "amount" fields
                triggerValidation('deposit');
              }}
            />}

            {((watchAllFields.assetType && assetSubtypeOptions[watchAllFields.assetType]?.length > 0) ||
            (watchAllFields.mainAssetType && assetSubtypeOptions[watchAllFields.mainAssetType]?.length > 0)) &&
            <Field
              type='field-dropdown' 
              name='assetDescription'
              size='lg'
              title='Asset Description'
              options={assetSubtypeOptions[watchAllFields.assetType || watchAllFields.mainAssetType]}
              formRegister={formRegister}
            />}

            <Field
              type='field-amount' 
              name='purchasePrice'
              title={
                watchAllFields.assetSingle === 'yes'
                  ? (!watchAllFields.assetType || watchAllFields.assetType === 'Car')
                    ? 'Purchase price (including VAT)'
                    : 'Purchase price (excluding VAT)'
                  : (!watchAllFields.mainAssetType || watchAllFields.mainAssetType === 'Car')
                    ? 'Total purchase price for all assets (including VAT)'
                    : 'Total purchase price for all assets (excluding VAT)'
              }
              formRegister={formRegister}
              validate={(val) => {
                if (val == null || val === '') {
                  return 'Please enter the purchase price';
                }
                const min = 5000;
                const max = 250000;
                if (val < min) {
                  return `Please enter a value between £${toFormatedNumStr(min, true)} and £${toFormatedNumStr(max, true)}. For anything higher than this, please <a href="https://quote.lombard.co.uk/contact-us" target="_blank">contact us</a>.`;
                }
                if (val > max) {
                  return 'The maximum purchase price is £250,000. For anything higher than this, please <a href="https://quote.lombard.co.uk/contact-us" target="_blank">contact us</a>.';
                }
              }}
              onChange={(v) => {
                // console.log(v);

                // only trigger validation after changes has been made/confirmed
                setTimeout(() => {
                  triggerValidation('deposit');
                }, 0);
              }}
            />
            
            <Field
              type='field-amount' 
              name='deposit'
              title={
                watchAllFields.assetSingle  === 'yes'
                  ? (!watchAllFields.assetType || watchAllFields.assetType === 'Car')
                    ? 'Deposit' 
                    : 'Deposit (including VAT)'
                  : (!watchAllFields.mainAssetType || watchAllFields.mainAssetType === 'Car')
                    ? 'Total deposit for all assets'
                    : 'Total deposit for all assets (including VAT)'
              }
              formRegister={formRegister}
              validate={(val) => {
                if (!attemptedToSubmit.current && (val == null || val === '')) {
                  return;
                }

                const depositInput = getValues('deposit');
                // console.log('[deposit] [validate] input', depositInput);

                if (depositInput == null || depositInput === '') {
                  return 'Please enter the deposit.';
                }

                const deposit = toNum(depositInput);
                
                const assetType = getValues('assetType') || getValues('mainAssetType');
                const isCar = assetType === 'Car';
                const purchasePrice = toNum(getValues('purchasePrice'));
                // console.log('[deposit] [validate] deposit, purchasePrice', deposit, purchasePrice);

                if (isCar) {
                  if (deposit > 0.5 * purchasePrice) {
                    return `The maximum deposit allowed is £${toFormatedNumStr(0.5 * purchasePrice)}. This is 50% of the purchase price (plus VAT where applicable).`;
                  }

                  if (purchasePrice - deposit < 5000) {
                    return 'Total borrowing must be at least £5,000. Please enter a value between £0 and £' + toFormatedNumStr(purchasePrice - 5000) + '.';
                  }
                } else {
                  if (deposit > 0.7 * (purchasePrice)) {
                    return `The maximum deposit allowed is £${toFormatedNumStr(0.7 * purchasePrice)}. This is 50% of the purchase price (plus VAT where applicable).`;
                  }
                  if (deposit < 0.2 * (purchasePrice)) {
                    return `The minimum deposit allowed is £${toFormatedNumStr(0.2 * purchasePrice)}. This is 20% of the purchase price.`;
                  }
                  if (purchasePrice * 1.2 - deposit < 5000) {
                    return 'Total borrowing must be at least £5,000. Please enter a value between £0 and £' + toFormatedNumStr(purchasePrice * 1.2 - 5000) + '.';
                  }
                }
              }}
            />

            {potentialBorrowingError && <div className='ec__warning ec__warning--error'>
              <div className='inner-left-border-red'></div>
              <div className='ec__warning-icon'><IconError /></div>
              <div className='ec__warning-title'>Potential borrowing exceeds £250,000</div>
              <div className='ec__warning-content'>Due to the level of existing finance you have with us, we’re unable to serve you digitally. Please contact your relationship manager or call our direct team for support on 0800 502 402 (available Monday to Friday 9am to 5pm).</div>
            </div>}

            <div className="ec--step-bottom">
              <button type="submit" className="btn btn-primary" onClick={() => { attemptedToSubmit.current = true; }}>Continue</button>
            </div>
            
          </div>
        </form>
      </div>
    </div>
    </>
  );
}

export default withRouter(AboutAssets);
