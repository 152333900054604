import React, {useContext, useEffect, useState} from 'react';
import { withRouter } from "react-router-dom";
import axios from 'axios';
import {Context} from '../../../stores/store'
import { STRUCTURES } from '../../../constants/Labels';
import './confirmation.scss';
import { ReactComponent as Loader } from '../../../assets/images/loader-dark.svg';

const Confirmation = ({history}) => {
    const [state, dispatch] = useContext(Context);
    const [content, setContent] = useState(false);
    const [apiCalled, setApiCalled] = useState(false);
    useEffect(() => {
        const {formData} = state;
        if (formData.activeStep === 'about-you') {
            history.push('/business/loans-and-finance/eligibility-checker/about-you')
        } else {
          setContent(true)
        }
    }, [state, history]);

    if (!content) return false;

    const checkEligibility = () => {
      setApiCalled(true);
      const {formData} = state;
      const Term = (state.calculator.years * 12) + state.calculator.months
      let postData = {
        form: formData.data,
        calculator: {
          APR: state.calculator.APR,
          total: state.calculator.loanAmount,
          Term: Term,
        }
        };

      let axiosConfig = {
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
        }
      };
      axios.post(`/api/check`, postData,  axiosConfig)
      .then(res => {
        setApiCalled(false);
        redirectToResult(res.data);
      })
      .catch(res => {
        setApiCalled(false);
        console.log(res);
      })
    }

    const redirectToResult = (result) => {
      switch(result.checker[0].likelihood) {
        case "Not Eligible":
        case "Not Likely":
        case "Unlikely":
          dispatch({type: 'RESULT', payload: result.checker[0].likelihood});
          history.push('/business/loans-and-finance/not-approved')
          break;
        case "Likely":
        case "Highly Likely":
          dispatch({type: 'RESULT', payload: result.checker[0].likelihood});
          history.push('/business/loans-and-finance/approved')
          break;
        case "Not Matched":
        case "Not Rated":
          dispatch({type: 'RESULT', payload: result.checker[0].likelihood});
          history.push('/business/loans-and-finance/not-matched')
          break;
        default:
          break;
      }
    }

    const formatPrice = (price) => {
      return `£${price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
    }

    const getAddressString = (address) => {
      return [address.address1, address.address2, address.town, address.postcode].filter(line => !!line).join(', ');
    }

    const DOB = `${state.formData.data.aboutYou.data.Day}/${state.formData.data.aboutYou.data.Month}/${state.formData.data.aboutYou.data.Year}`
      return (
        <div className="EC__form EC__stepFour active EC__confirmation">
        <div className="EC__form__wrapper">
        <h2>Confirm details</h2>
        <h3>Here's what you've told us:</h3>
        <div className="EC__Section">
          <h2>About you</h2>
          <div className="EC__Section__item">
            <span>First name</span>
            <span>{state.formData.data.aboutYou.data.firstName}</span>
          </div>
          <div className="EC__Section__item">
            <span>Last name</span>
            <span>{state.formData.data.aboutYou.data.lastName}</span>
          </div>
          <div className="EC__Section__item">
            <span>Date of birth</span>
            <span>{DOB}</span>
          </div>
        </div>
                <div className="EC__Section">
          <h2>Address History</h2>
          {state.formData.data.addressHistory.length > 0 ? state.formData.data.addressHistory.map((address, index) => (
            <div key={index} className="EC__Section__item">
              <span>{index === 0 ? 'Current address' : 'Previous address'}</span>
              <span>{getAddressString(address)}<br/>{address.Year === '' ? 0 : address.Year} {address.Year === 1 ? 'Year' : 'Years'}, {address.Month === '' ? 0 : address.Month} {address.Month === 1 ? 'Month' : 'Months'}</span>
            </div>
          )) : null}
        </div>
        <div className="EC__Section">
          <h2>About your business</h2>
          <div className="EC__Section__item">
            <span>Business structure</span>
            <span>{STRUCTURES[state.formData.data.aboutbusiness.data.structure]}</span>
          </div>
          {state.formData.data.aboutbusiness.data.directors && (
            <div className="EC__Section__item">
            <span>Partners</span>
            <span>{state.formData.data.aboutbusiness.data.directors}</span>
          </div>
          )}
          {state.formData.data.aboutbusiness.data.borrowingRepayments && (
          <div className="EC__Section__item">
            <span>Total business borrowing monthly repayments</span>
            <span>{formatPrice(state.formData.data.aboutbusiness.data.borrowingRepayments)}</span>
          </div>
          )}
          {state.formData.data.aboutbusiness.data.overdraftAmount && (
       <div className="EC__Section__item">
            <span>Business overdraft facility</span>
            <span>{formatPrice(state.formData.data.aboutbusiness.data.overdraftAmount)}</span>
          </div>
          )}
          <div className="EC__Section__item">
            <span>Business turnover per year</span>
            <span>{formatPrice(state.formData.data.aboutbusiness.data.turnover)}</span>
          </div>
        </div>
        <p>We'll carry out a soft credit check based on your loan requirements and the details you've entered. <strong>This won't affect your credit score.</strong></p>
        <button type="button" id="CHECK_ELIGIBILITY" onClick={(e) => {checkEligibility()}} className="EC__confirmation__btn">{
        apiCalled ?
        (<Loader className="EC__confirmation--loading"/>) :
        ('Check your eligibility')
}</button>
        </div>
      </div>
      );
}


export default withRouter(Confirmation);

