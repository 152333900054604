import React, {useContext, useEffect, useState} from 'react';
import { useForm } from "react-hook-form";
import { withRouter } from "react-router-dom";
import cx from 'classnames';
import { STRUCTURES } from '../../../constants/Labels';

import {Context} from '../../../stores/store'

const AboutYourBusiness = ({history}) => {
  const { register, handleSubmit, watch, errors, formState, setValue} = useForm();
  const [state, dispatch] = useContext(Context);
  const [loaded, isLoaded] = useState(false);
  const {formData} = state;
  const current = 2;
  const watchAllFields = watch();

    useEffect(() => {
        if (formData.activeStep === 'about-you') {
            history.push('/business/loans-and-finance/eligibility-checker/about-you')
        } else {
          if(!loaded) {
           if (formData.data.aboutbusiness.data) {
          setValue([
            {structure: formData.data.aboutbusiness.data.structure},
            {existingRepayments: formData.data.aboutbusiness.data.existingRepayments},
            {overdraft: formData.data.aboutbusiness.data.overdraft},
            {overdraftAmount: formData.data.aboutbusiness.data.overdraftAmount ? formData.data.aboutbusiness.data.overdraftAmount : undefined},
            {turnover: formData.data.aboutbusiness.data.turnover},
            {directors: formData.data.aboutbusiness.data.directors ? formData.data.aboutbusiness.data.directors : undefined},
            {borrowingRepayments: formData.data.aboutbusiness.data.borrowingRepayments ? formData.data.aboutbusiness.data.borrowingRepayments : undefined}
          ])
            isLoaded(true);
          }
        }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

  const handleBlur = (e) => {
    var num = parseFloat(e.target.value);
    var cleanNum = num.toFixed(2);
    setValue(e.target.name, cleanNum)
  }

    const ContinueBtn = (data, e) => {
      e.preventDefault();
      try{
        data.borrowingRepayments = data.existingRepayments === 'No' ?  "" : data.borrowingRepayments;
        data.overdraftAmount = data.overdraft === 'No' ?  "" : data.overdraftAmount;
        data.directors = data.structure === 'sole' ?  "" : data.directors;
      }
      catch(e){}
        const currentID = formData.steps[current].id
        const count = formData.steps.filter((x) => {return x.isComplete}).length + 1
        const next = formData.steps.findIndex((element) => (
          element.isComplete === false && element.id !== currentID
          ));
      
        const result = {
            data: {
            addressHistory: [...formData.data.addressHistory],
            aboutYou: {...formData.data.aboutYou},
            aboutbusiness: {data},
            },
            steps: formData.steps.map(
              el => el.id === currentID ? { ...el, isComplete: true }: el
            ),
            sectionsCompelete: 4 - count,
            activeStep: formData.steps[next].id
          }

          dispatch({type: 'FORM_STEP', payload: result});
          history.push("/business/loans-and-finance/eligibility-checker/confirmation")
      }
      
      const BackBtn = (e, value) => {
        e.preventDefault();
        const { formData } = state; 
        const backID = formData.steps[value].id
        const result = {
            steps: formData.steps.map(
              el => el.id === backID ? { ...el, isComplete: false }: el
            ),
            sectionsCompelete: formData.sectionsCompelete + 1,
            activeStep: formData.steps[value].id
          }
          dispatch({type: 'FORM_EDIT', payload: result});
          history.push("/business/loans-and-finance/eligibility-checker/address-history")
      }
      var showDirectorsQuestion = watchAllFields.structure === 'limited' || watchAllFields.structure === 'partnership';
      return (
        <form onSubmit={handleSubmit(ContinueBtn)} className="EC__form EC__stepThree active">
        <div className="EC__form__wrapper">
        <h2>About your business</h2>
        <div>
          <span>What is the legal structure of your business?</span>
          <label className={watchAllFields.structure === 'limited' ? 'active' : 'inactive'}>
            <input name="structure" type="radio" value="limited" ref={register({ required: true })}/>
            <span className="check"></span>
            <span className="label">{STRUCTURES.limited}</span>
          </label>
          <label className={watchAllFields.structure  === 'partnership' ? 'active' : 'inactive'}>
            <input name="structure" type="radio" value="partnership" ref={register({ required: true })}/>
            <span className="check"></span>
            <span className="label">{STRUCTURES.partnership}</span>
          </label>
          <label className={watchAllFields.structure  === 'sole' ? 'active' : 'inactive'}>
            <input name="structure" type="radio" value="sole" ref={register({ required: true })}/>
            <span className="check"></span>
            <span className="label">{STRUCTURES.sole}</span>
          </label>
          {errors.structure && (<span className="errorMsg">This field is required.</span>)}
        </div>
        <div className={showDirectorsQuestion  ? 'visible conditionalField' : 'conditionalField'}>
          <span>How many directors/partners are in your business?</span>
          <label className={watchAllFields.directors  === '1' ? 'active' : ''}>
          <input name="directors" type="radio" value="1" ref={register}/>
          <span className="check"></span>
            <span className="label">
            1
            </span>
          </label>
          <label className={watchAllFields.directors  === '2' ? 'active' : ''}>
          <input name="directors" type="radio" value="2" ref={register}/>
          <span className="check"></span>
            <span className="label">
            2
            </span>
          </label>
          <label className={watchAllFields.directors  === '3' ? 'active' : ''}>
          <input name="directors" type="radio" value="3" ref={register}/>
          <span className="check"></span>
            <span className="label">
            3+
            </span>
            </label>
            {errors.directors && (<span className="errorMsg">This field is required.</span>)}
        </div>
        <div>
        <span>Do you have any existing monthly borrowing repayments?</span>
          <p>Includes monthly repayments on any existing business loans, credit cards and other lending products.</p>            
            <label className={watchAllFields.existingRepayments === 'Yes' ? 'active--nested' : ''}>
            <input name="existingRepayments" type="radio" value="Yes" ref={register({ required: true })}/>
            <span className="check"></span>
            <span className="label">
            Yes
            </span>
              <label className={watchAllFields.existingRepayments === 'Yes' ? 'visible nested' : 'nested'} htmlFor="borrowingRepayments">
                <span>How much are your existing monthly borrowing repayments?</span>
                <div>
                <input 
                  type="number" 
                  name="borrowingRepayments"
                  onBlur={(e) => handleBlur(e)} 
                  className={cx({'touched' : formState.touched.borrowingRepayments}, {'success' : watchAllFields.borrowingRepayments},{'error' : errors.borrowingRepayments})}
                  ref={register} />
                  </div>
                {errors.borrowingRepayments && (<span className="errorMsg">This field is required.</span>)}
              </label>
          </label>
          

          <label className={watchAllFields.existingRepayments === 'No' ? 'active' : ''}>
          <input name="existingRepayments" type="radio" value="No" ref={register({ required: true })}/>
          <span className="check"></span>
            <span className="label">
            No
            </span>
          </label>
          {errors.existingRepayments && (<span className="errorMsg">This field is required.</span>)}
        </div>
        <div>
          <span>Do you have an existing business overdraft?</span>
          <label className={watchAllFields.overdraft === 'Yes' ? 'active--nested' : ''}>
            <input name="overdraft" type="radio" value="Yes" ref={register({ required: true })}/>
            <span className="check"></span>
            <span className="label">
            Yes
            </span>
            <label className={watchAllFields.overdraft === 'Yes' ? 'visible nested' : 'nested'} htmlFor="overdraftAmount">
            <span>What is your existing business overdraft amount?</span>
              <div><input 
                type="number" 
                onBlur={(e) => handleBlur(e)} 
                className={cx({'touched' : formState.touched.overdraftAmount}, {'success' : watchAllFields.overdraftAmount}, {'error' : errors.overdraftAmount})} 
                name="overdraftAmount" 
                ref={register} />
                </div>
                {errors.overdraftAmount && (<span className="errorMsg">This field is required.</span>)}
            </label>
          </label>
          <label className={watchAllFields.overdraft === 'No' ? 'active' : ''}>
          <input name="overdraft" type="radio" value="No" ref={register({ required: true })}/>
          <span className="check"></span>
            <span className="label">
            No
            </span>
          </label>
          {errors.overdraft && (<span className="errorMsg">This field is required.</span>)}
        </div>
        <label className="currencyInput" htmlFor="turnover">
          <span>What is your annual business turnover?</span>
          <div>
          <input type="number" onBlur={(e) => handleBlur(e)} className={cx({'touched' : formState.touched.turnover}, {'success' : watchAllFields.overdraftAmount}, {'error' : errors.turnover})} name="turnover" ref={register({required:true})} />
          </div>
          {errors.turnover && (<span className="errorMsg">This field is required.</span>)}
        </label>
       
      </div>
      <div className="EC__button__container">
          <button type="button" id="BACK_TO_AY" onClick={(e) => {BackBtn(e, 1)}} className="EC__form__back">Back</button>
          <button type="submit" id="CONT_TO_AYB" className="EC__form__continue">Continue</button>
      </div>
      </form>
      );
}


export default withRouter(AboutYourBusiness);