import React, { useContext } from 'react';
import { withRouter } from 'react-router-dom';
import envConfig from '../../common/env.config';


import { Context } from '../../stores/store'
import './eligibilityChecker.scss';

const EligibilityChecker = ({children, history}) => {
  const [ state ] = useContext(Context);

  return (
    <>
      {envConfig.debug && <div className='log-block'><p>{JSON.stringify(state.formData.data)}</p></div>}

      <div className="ec__container">
        <div className="ec">
          {/* <Breadcrumbs */}
          {/* <Banner title="Eligibility Checker" /> */}
          
          {children}
        </div>
      </div>
    </>
  );
};

export default withRouter(EligibilityChecker);
