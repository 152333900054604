const numeral = require('numeral');

/**
 * 
 */
const toFormatedNumStr = (x, noDecimal) => {
  if (noDecimal) {
    return numeral(x).format('0,0');
  }
  return numeral(x).format('0,0.00');
}

/**
 * 
 */
const toDecimal = (num) => {
  return (Math.round(num * 100) / 100).toFixed(2);
};

/**
 * 
 */
const toNum  = (str) => {
  if (typeof str === 'string' && str.indexOf('£') === 0) {
    return parseFloat(str.substring(1).replace(/,/g,''));
  }
  return str;
};

/**
 * 
 */
const startsWithNum = (str) => {
  return /^\d/.test(str);
}

/**
 * 
 */
const capitalizeFirstLetter = str => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

/**
 * 
 */
const formatAddressStr = str => {
  if (!str) {
    return '';
  }

  // console.log(str, startsWithNum(str), str.indexOf(' ') > -1);
  const separatedStrList = str.split(' ');
  if (startsWithNum(str) && separatedStrList.length > 1) {
    separatedStrList[1] = capitalizeFirstLetter(separatedStrList[1]);
    return separatedStrList.join(' ');
  }

  return capitalizeFirstLetter(str);
}

export { toFormatedNumStr, toDecimal, toNum, startsWithNum, formatAddressStr };