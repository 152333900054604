import React, {useContext, useEffect, useState, useRef} from 'react';
import { useForm } from "react-hook-form";
import cx from 'classnames';
import { withRouter } from "react-router-dom";

import {Context} from '../../../stores/store'

const AboutYou = ({history}) => {

  const [state, dispatch] = useContext(Context);
  const { register, handleSubmit, errors, formState, watch, setValue, setError, clearError } = useForm({
  mode: 'onChange',
});
  const watchAllFields = watch();
  const current = 0;
  const [loaded, isLoaded] = useState(false);

  const dayRef = useRef()
  const monthRef = useRef()
  const yearRef = useRef()
  const fullDOB = `${watchAllFields.Day}-${watchAllFields.Month}-${watchAllFields.Year}`

  useEffect(() => {
    const {formData} = state;
    if(!loaded) {
      if (formData.data.aboutYou.data) {
        setValue([
          {firstName: formData.data.aboutYou.data.firstName},
          {lastName: formData.data.aboutYou.data.lastName},
          {Day: formData.data.aboutYou.data.Day},
          {Month: formData.data.aboutYou.data.Month},
          {Year: formData.data.aboutYou.data.Year},
          {DOB: formData.data.aboutYou.data.DOB}
          ])
          isLoaded(true);
        }
      }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validDate = () => {
    let dateString = `${watchAllFields.Year}-${watchAllFields.Month}-${watchAllFields.Day}`
    const now = new Date();
    const EnteredDate = new Date(dateString);
    // console.log(dateString);
    if (EnteredDate < now) {
        clearError("DOB")
    } else {
        setError("DOB", "invalid", "invalid DOB")
    }
    return false
  }

  const onChangeDOB = (e) => {
    if (watchAllFields.Year && watchAllFields.Day && watchAllFields.Month && watchAllFields.Year.length === 4) {
        setValue('DOB', fullDOB)
        validDate();
    }
    if (e.target.name === 'Day' && e.target.value.length === 2) {
      monthRef.current.focus();
    }
    if (e.target.name === 'Month' && e.target.value.length === 2) {
      yearRef.current.focus();
    }
  }

  const ContinueBtn = (data) => {
      const {formData} = state;
      if (data) {
        if ( data.Day &&  data.Day.length === 1) {
          data.Day = '0' + data.Day;
        }
        if ( data.Month &&  data.Month.length === 1) {
          data.Month = '0' +  data.Month;
        }
      }
      const currentID = formData.steps[current].id
      const count = formData.steps.filter((x) => {return x.isComplete}).length + 1
      const next = formData.steps.findIndex((element) => (
        element.isComplete === false && element.id !== currentID
        ));
    
  const result = {
      data: {
      addressHistory: [...formData.data.addressHistory],
      aboutYou: {data},
      aboutbusiness: {...formData.data.aboutbusiness},
      },
      steps: formData.steps.map(
        el => el.id === currentID ? { ...el, isComplete: true }: el
      ),
      sectionsCompelete: 4 - count,
      activeStep: formData.steps[next].id
    }

    dispatch({type: 'FORM_STEP', payload: result});
    history.push(`/business/loans-and-finance/eligibility-checker/${formData.steps[next].id}`)
  }
  return (
    <form onSubmit={handleSubmit(ContinueBtn)} className="EC__form EC__stepOne active">
      <div className="EC__form__wrapper">
        <h2>About you</h2>
        <h3>Enter your personal details</h3>
        <label className="text" htmlFor="firstName">
          <span>First Name</span>
          <input className={cx({'touched' : formState.touched.firstName}, {'success' : watchAllFields.firstName}, {'error' : errors.firstName})} type="text" name="firstName" ref={register({required:true})} />
          {errors.firstName && (<span className="errorMsg"><div className='inner-left-border'></div>Your first name is required</span>)}
        </label>
        <label className="text" htmlFor="lastName">
          <span>Last Name</span>
          <input className={cx({'touched' : formState.touched.lastName}, {'error' : errors.lastName}, {'success' : watchAllFields.lastName})} type="text" name="lastName" ref={register({required:true})} />
          {errors.lastName && (<span className="errorMsg"><div className='inner-left-border'></div>Your last name is required</span>)}
        </label>
        <div className="DOB">
          <span>Date of birth</span>
          <label htmlFor="Day">
            <span className="label">Day</span>
            <input 
              onChange={(e) => onChangeDOB(e)}
              className={cx({'touched' : formState.touched.Day}, {'success' : watchAllFields.Day}, {'error' : errors.Day})} 
              type="number" 
              name="Day" 
              ref={(e) => {
                register(e,{required:true, max: 32, maxLength: 2})
                dayRef.current = e;
              }}
            />
            {errors.Day && errors.Day.type === 'required' && (<span className="errorMsg"><div className='inner-left-border'></div>This field is required.</span>)}
            {errors.Day && errors.Day.type !== 'required' && (<span className="errorMsg"><div className='inner-left-border'></div>invalid entry</span>)}
          </label>
          <label htmlFor="Month">
            <span className="label">Month</span>
            <input 
              onChange={(e) => onChangeDOB(e)}
              className={cx({'touched' : formState.touched.Month}, {'success' : watchAllFields.Month},  {'error' : errors.Month})} 
              type="number" 
              name="Month" 
              ref={(e) => {
                register(e,{required:true, max:12, maxLength: 2})
                monthRef.current = e;
              }}
            />
            {errors.Month && errors.Month.type === 'required' && (<span className="errorMsg"><div className='inner-left-border'></div>This field is required.</span>)}
            {errors.Month && errors.Month.type !== 'required' && (<span className="errorMsg"><div className='inner-left-border'></div>invalid entry</span>)}
          </label>
          <label htmlFor="Year">
            <span className="label">Year</span>
            <input 
              onChange={(e) => onChangeDOB(e)}
              className={cx({'touched' : formState.touched.Year}, {'success' : watchAllFields.Year},  {'error' : errors.Year})} 
              type="number" 
              name="Year" 
              ref={(e) => {
                register(e, {required:true, max:2050, maxLength: 4})
                yearRef.current = e;
              }}
            />
            {errors.Year && errors.Year.type === 'required' && (<span className="errorMsg"><div className='inner-left-border'></div>This field is required.</span>)}
            {errors.Year && errors.Year.type !== 'required' && (<span className="errorMsg"><div className='inner-left-border'></div>invalid entry</span>)}
          </label>
          <input type="hidden" name="DOB" ref={register} />
          {errors.DOB && (<span className="errorMsg"><div className='inner-left-border'></div>Invalid entry for date of birth</span>)}
        </div>
      </div>
      <div className="EC__button__container">
        <button type="submit" id="CONT_TO_AH" className="EC__form__continue">Continue</button>
      </div>
    </form>
  );
}


export default withRouter(AboutYou);
