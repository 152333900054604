import React, { useRef, useState} from 'react';

import { useForm } from 'react-hook-form'

import { ReactComponent as SearchIcon } from '../../assets/images/search_white.svg'

import './Search.scss';

function Search() {
  const { register, formState } = useForm();
  const [isFocused, toggleFocused] = useState(false);
  const [query, setQuery] = useState('');
  const search = useRef();
  const action = `https://lombard.co.uk/search-results.html#stq=${query}&stp=1`

  return (
    <form action={action}>
      <label className={isFocused || formState.touched.search ? 'Search__label is-focused': 'Search__label'} htmlFor="search">
        <input type="text" name="q" 
        ref={(e) => {
          register(e, {required: true})
          search.current = e
        }}
        onFocus={() => toggleFocused(true)}
        onBlur={() => toggleFocused(false)}
        onChange={(e) => setQuery(e.target.value)}/>
        <span className={formState.touched.search ? 'Search--touched' : null}>Search</span>
        <button type="submit"><SearchIcon /></button>
      </label>
      <input type="hidden" name="brand" value="LOMBARD_CO_UK" ref={register}/>
      <input type="hidden" name="section" value="lombard" ref={register}/>
      <input type="hidden" name="enginekey" value="qTho3Evd1d2EZ6gXhyNi" ref={register}/>
    </form>
  );
}

export default Search;
