import cx from 'classnames';
import React from 'react';

// import { ReactComponent as Info } from '../../assets/images/Info.svg';
import { ReactComponent as Info } from '../../assets/images-new/info-medium.svg';
import './field-base.scss';

/**
 * title
 * subtitle
 * groupTitle
 * name
 * type
 * size
 * formRegister
 */
const FieldBase = (props) => {
  const { groupTitle, title, subtitle, type, subType, size, content, error, hideError } = props;

  // const {
  //   errors,
  // } = formRegister;

  return (
    <div className={cx(
      'field-block',
      type || '',
      subType || '',
      size ? 'field-block--' + size : ''
    )}>
      <label>
        {groupTitle && <div className='field-title field-title--group'>{groupTitle}</div> }

        {title && <div className='field-title'>{title}</div>}

        {subtitle && <div className='field-subtitle'><Info /><span>{subtitle}</span></div>}
        
        <div className="field-content">{content}</div>
        
        {!hideError && <div className='field-error'>{error}</div>}
        {/* <div className='field-error'>{errors && JSON.stringify(errors[name])}</div> */}
        {/* <div className='field-error'>
          <span class="errorMsg">{errors && errors[name] && errors[name].message}</span>
        </div> */}
      </label>
    </div>
  );
};

export default FieldBase;