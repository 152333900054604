/* eslint-disable jsx-a11y/alt-text */
import React from "react";

import Calculator from "../../components/Calculator/Calculator";
import { ReactComponent as Tick } from '../../assets/images/tick_grey.svg';


import "./home.scss";

const Home = () => {
  const CalcRef = React.createRef();

  return (
    <div className="Main">
      <div
        className="container-fluid custom-section-wrapper single-article-shelf theme--midnight-blue   fifty-fifty "
        data-shelf-name="Single Article"
      >
        <div className="container custom-section">
          <div className="row">
              <div className="title-wrapper">
                <h1 className="title-comp main-title ">Fixed Rate Hire Purchase Calculator</h1>

                <script
                  src="/etc.clientlibs/responsive/components/foundation/title/clientlibs/clientlib_title_base-_7ad603327c300d88c7efdd686a27d4b6.min.js"
                  defer=""
                ></script>
              </div>

          </div>
        </div>
      </div>

      <section className="productlistingmultiple">
        {/* <link rel="stylesheet" href="/etc.clientlibs/responsive/shelves/productlistingmultiple/clientlibs/clientlib_productlisting_multiple_shelf_lombard-_9c0a0f0a76061e2ba98574ca8a0ce1bc.min.css" type="text/css"> */}

        <div
          id="hirepurchase"
          className="productcomp-multiple-shelf  container flt__shlf"
          data-shelf-name="Multiple Listing Card"
        >
          <div className=" hideShelfText  ">
            <div className="row">
                <div className="shelf-titleWrapper">
                  <h2 className="title-comp h2-custom">
                    <div className="custom-font">
                      Try our Fixed Rate Hire Purchase calculator
                    </div>
                  </h2>
                </div>
            </div>

            <div className="row">

                <div className="shelf-textarea">
                  <div className="comp-rich-text">
                    <p>
                      Security may be required. Product fees may apply. Finance
                      is only available for business purposes.
                    </p>
                  </div>
                </div>
            </div>
          </div>

          <div className="multi-product-comp">
            <div className="row">
              <div className="col-xs-12 col-sm-11 col-sm-offset-right-1 col-md-11 col-md-offset-right-1 col-lg-offset-right-1 yellow subListLayout flt__comp">
                <div className="product-comp clearfix">
                  {/* <link rel="stylesheet" href="/etc.clientlibs/responsive/components/foundation/product/clientlibs/clientlib_product_lombard-_7edf6b5990f5e83ef64641a9ae4d5028.min.css" type="text/css"> */}

                  {/* <div className="col-xs-11 col-sm-5 col-md-5 col-lg-5 single-img multi-img hideLeftDiv"></div> */}


                  <div className="rte-wrapper">
                        <div>
                          <ul className="EI__content__checklist">
                            <li><Tick className="tick"/>If you don't yet have a specific asset in mind or are looking for a quick estimate of borrowing costs and monthly repayments.</li>
                            <li><Tick className="tick"/>To explore different agreement lengths and deposit amounts to get a clearer picture of your options.</li>
                          </ul>
                      </div>
                    </div>

                  <div className="col-xs-12 col-sm-11 col-md-11 col-lg-12 single-content multi-content flt__item">


                    <div className="text-wrapper">
                      <div className="h4">
                      </div>
                      <br/>
                      <div className="h4">
                        <span className="text-comp">
                        If you're a Sole Trader or Partnership of 2 or 3 partners
                        borrowing up to £25,000.00, or a private individual
                        borrowing any amount, your agreement will be
                        regulated by the Consumer Credit Act (1974). This
                        means you'll have a Fixed Rate Regulated Conditional
                        Sale agreement. Check out our factsheets for more
                        information; <a href="https://www.lombard.co.uk/content/dam/lombard_co_uk/PDFs/Lombard-Conditional-Sale-Factsheet.pdf" target="_blank" rel="noopener noreferrer">Fixed Rate Conditional Sale (PDF, 274KB)</a>, <a href="https://www.lombard.co.uk/content/dam/lombard_co_uk/documents/Lombard-Fixed-Rate-Hire-Purchase-Factsheet.pdf" target="_blank" rel="noopener noreferrer">Fixed Rate Hire Purchase (PDF, 181KB)</a>, <a href="https://www.lombard.co.uk/content/dam/lombard_co_uk/documents/Lombard-Variable-Rate-Hire-Purchase-Factsheet.pdf" target="_blank" rel="noopener noreferrer">Variable Rate Hire Purchase (PDF 215KB)</a>.
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="clearfix visible-sm"></div>
            </div>
          </div>
        </div>
      </section>

      {/* <EligibilityInfo scrollToCalc={scrollToCalc} scrollToApply={scrollToApply}/> */}
      <section ref={CalcRef} className="Main__grey">
        <Calculator />
      </section>
      <section className="singlearticle">
        <div
          id="apply"
          className="container-fluid single-article-shelf theme-white   fifty-fifty "
          data-shelf-name="Single Article"
        >
          <div className="container">
            <div className="row">
              <div className="col-xs-12 col-sm-5 col-md-5 col-lg-5 col-sm-offset-right-1 col-md-offset-right-1 col-lg-offset-right-1 pull-right">
                <div className="image-wrapper   ">
                  <div
                    className="comp-img-generic"
                    data-staticimage="/content/dam/Ulster/premier/ni/photos/ub-prem-photo-back-to-basics-450x280.jpg"
                  >
                    <script
                      src="/etc.clientlibs/responsive/components/foundation/image/clientlibs/clientlib_image_base/clientlibs-_7ad603327c300d88c7efdd686a27d4b6.min.js"
                      defer=""
                    ></script>

                    <div
                      className="adaptive-image-wrapper"
                      data-picture=""
                      data-picture-width="450"
                      data-picture-height="280"
                      data-role="presentation"
                    >
                      <div
                        data-src="/content/dam/Ulster/premier/ni/photos/image.dim.180.ub-prem-photo-back-to-basics-450x280.jpg"
                        data-media="(min-width: 100px)"
                      ></div>
                      <div
                        data-src="/content/dam/Ulster/premier/ni/photos/image.dim.270.ub-prem-photo-back-to-basics-450x280.jpg"
                        data-media="(min-width: 181px)"
                      ></div>
                      <div
                        data-src="/content/dam/Ulster/premier/ni/photos/image.dim.360.ub-prem-photo-back-to-basics-450x280.jpg"
                        data-media="(min-width: 271px)"
                      ></div>
                      <div
                        data-src="/content/dam/Ulster/premier/ni/photos/image.dim.480.ub-prem-photo-back-to-basics-450x280.jpg"
                        data-media="(min-width: 361px)"
                      ></div>
                      <div
                        data-src="/content/dam/Ulster/premier/ni/photos/image.dim.full.ub-prem-photo-back-to-basics-450x280.jpg"
                        data-media="(min-width: 481px)"
                      ></div>
                      <noscript>
                        <img
                          src="/content/dam/Ulster/premier/ni/photos/image.dim.180.ub-prem-photo-back-to-basics-450x280.jpg"
                          role="presentation"
                        />
                      </noscript>
                      <img
                        className="image-wrap"
                        role="presentation"
                        src="https://lombard.co.uk/content/dam/Ulster/premier/ni/photos/image.dim.480.ub-prem-photo-back-to-basics-450x280.jpg"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xs-12 col-sm-5 col-md-5 col-lg-5 col-sm-offset-1 col-md-offset-1 col-lg-offset-1 ">
                <div className="title-wrapper">
                  <h2 className="title-comp h2-custom ">
                    Apply online for Fixed Rate Hire Purchase
                  </h2>
                </div>

                <div className="rte-wrapper">
                  <div className="theme-purple">
                    <div className="comp-rich-text">
                      <p>Applying online couldn’t be easier. Simply:</p>
                      <ul>
                        <li>Start your quote by entering details about your business.</li>
                        <li>Enter details about your asset.</li>
                        <li>Submit your application and we'll perform a credit search - if approved we will let you know instantly.</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <br />
                <div className="cta-wrapper">
                  <div className="btn btn-primary">
                  <a
                    className="cta cta-primary  "
                    data-disable-blank="false"
                    href="https://quote.lombard.co.uk/landing/cars-and-vans?extcam=L_QQ_LINK_ApplyOnline2"
                    title="Apply online"
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-describedby="new-window-0"
                  >
                    <span className="cta-text">Apply online</span>
                  </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        className="container-fluid comp_whyUs_shelf bgWhite coloumn_3"
        data-shelf-name="Highlights With SVG Icons"
      >
        {/* <link rel="stylesheet" href="" type="text/css"> */}

        <div className="container">
          <div className="row">
            <div className="whyus-shelf-title-wrapper">
              <div className="col-lg-7 col-lg-offset-1 col-md-8 col-md-offset-1 col-sm-10 col-sm-offset-1 col-xs-12">
                <h2 className="title-comp h2-custom ">
                  Steps when applying online
                </h2>
              </div>
            </div>

            <div className="whyusblockwrapper">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div className="row">
                  <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 col-num-1">
                    <div className="whyUs-col-block ">
                      
                      <div className="title-wrapper">
                        <h3 className="title-comp h3-custom ">Apply online</h3>
                      </div>
                      <div className="text-wrapper col-sm-12">
                        <div className="col-lg-10 col-lg-offset-1">
                          <span className="text-comp">
                          Complete the online application form. Give us details about the asset, your business and we'll give you an instant Fixed Rate Hire Purchase quote
                          </span>

                          <div className="btn btn-link">
                            <a
                              className="cta cta-secondary  "
                              data-disable-blank="false"
                              href="https://quote.lombard.co.uk/landing/cars-and-vans"
                              title="Apply online"
                              target="_blank"
                              rel="noopener noreferrer"
                              aria-describedby="new-window-0"
                            >
                              <span className="cta-text">Apply online</span>
                            </a>
                          </div>
                        </div>
                      </div>
                      
                      {/* <div className="cta-wrapper2">
                        <div className="btn btn-primary">
                          <a
                            className="cta cta-secondary  "
                            data-disable-blank="false"
                            href="https://quote.lombard.co.uk/landing/cars-and-vans"
                            title="Apply online"
                            target="_blank"
                            rel="noopener noreferrer"
                            aria-describedby="new-window-0"
                          >
                            <span className="cta-text">Apply online</span>
                          </a>
                        </div>
                      </div> */}
                    </div>
                  </div>
                  <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 col-num-2">
                    <div className="whyUs-col-block ">
                      <div></div>
                      <div className="title-wrapper">
                        <h3 className="title-comp h3-custom ">
                          Get a decision
                        </h3>
                      </div>
                      <div className="text-wrapper col-sm-12">
                        <div className="col-lg-10 col-lg-offset-1">
                          <span className="text-comp">
                            A dedicated Relationship Manager will call you to
                            talk through your application. You will get your
                            decision instantly.
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 col-num-3">
                    <div className="whyUs-col-block ">
                      <div></div>
                      <div className="title-wrapper">
                        <h3 className="title-comp  ">Receive goods</h3>
                      </div>
                      <div className="text-wrapper col-sm-12">
                        <div className="col-lg-10 col-lg-offset-1">
                          <span className="text-comp">
                            Once everything has been approved your date for
                            receipt of the goods will be arranged with you
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="singlearticle">
        <div
          className="container-fluid single-article-shelf theme--lilac fifty-fifty single-article--left-align section-contact-us"
          data-shelf-name="Single Article"
        >
          <div className="container">
            <div className="row">
              <div className="col-xs-12 col-sm-5 col-md-5 col-lg-5 col-sm-offset-1 col-md-offset-1 col-lg-offset-1">
                <div className="image-wrapper">
                  <div
                    className="comp-img-generic"
                    data-staticimage="https://lombard.co.uk/content/dam/lombard_co_uk/photography/article/image.dim.480.lo-pers-woman-with-curly-hair-holding-phone-and-cup-looking-at-laptop.jpg"
                  >
                    <div
                      className="adaptive-image-wrapper"
                      data-picture=""
                      data-picture-width="450"
                      data-picture-height="280"
                      data-role="presentation"
                    >
                      <div
                        data-src="https://lombard.co.uk/content/dam/lombard_co_uk/photography/article/image.dim.480.lo-pers-woman-with-curly-hair-holding-phone-and-cup-looking-at-laptop.jpg"
                        data-media="(min-width: 100px)"
                      ></div>
                      <div
                        data-src="/content/dam/lombard_co_uk/photography/article/image.dim.270.lo-pers-woman-with-curly-hair-holding-phone-and-cup-looking-at-laptop.jpg"
                        data-media="(min-width: 181px)"
                      ></div>
                      <div
                        data-src="/content/dam/lombard_co_uk/photography/article/image.dim.360.lo-pers-woman-with-curly-hair-holding-phone-and-cup-looking-at-laptop.jpg"
                        data-media="(min-width: 271px)"
                      ></div>
                      <div
                        data-src="/content/dam/lombard_co_uk/photography/article/image.dim.480.lo-pers-woman-with-curly-hair-holding-phone-and-cup-looking-at-laptop.jpg"
                        data-media="(min-width: 361px)"
                      ></div>
                      <div
                        data-src="/content/dam/lombard_co_uk/photography/article/image.dim.full.lo-pers-woman-with-curly-hair-holding-phone-and-cup-looking-at-laptop.jpg"
                        data-media="(min-width: 481px)"
                      ></div>
                      <noscript>
                        <img
                          src="https://lombard.co.uk/content/dam/lombard_co_uk/photography/article/image.dim.480.lo-pers-woman-with-curly-hair-holding-phone-and-cup-looking-at-laptop.jpg"
                          role="presentation"
                        />
                      </noscript>
                      <img
                        className="image-wrap"
                        role="presentation"
                        src="https://lombard.co.uk/content/dam/lombard_co_uk/photography/article/image.dim.480.lo-pers-woman-with-curly-hair-holding-phone-and-cup-looking-at-laptop.jpg"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xs-12 col-sm-5 col-md-5 col-lg-5 col-sm-offset-right-1 col-md-offset-right-1 col-lg-offset-right-1 ">
                <div id="contact" className="title-wrapper">
                  <h2 className="title-comp  ">Contact our dedicated team</h2>
                </div>

                <div className="rte-wrapper">
                  <div className="theme-purple">
                    <div className="comp-rich-text">
                    <div>
                      <br></br>
                    </div>
                      <p>Available Monday to Friday 9am - 5pm</p>
                      <ul>
                        <li>For the fastest response, start a Live Chat.</li>
                        <li>Relay UK: 18001 0800 502 402</li>
                        <li>Speak to an advisor on 0800 502 402.</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <br />
                {/* <div className="cta-wrapper">
                  <div className="btn btn-primary">
                  <a
                    className="cta cta-primary  "
                    data-disable-blank="false"
                    href="https://lombard.co.uk/support/contact-us/request-a-call.html?extcam=L_QQ_LINK_Callback"
                    title="Request a call back"
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-describedby="new-window-0"
                  >
                    <span className="cta-text">Request a call back</span>
                  </a>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="sa-bgGrey theme--lilac">
        <div className="container">
          <div className="row standalone-wrapper_wt">
            <div className="col-xs-11 col-sm-11 col-md-11 col-lg-11 standalone-wrapper-wt">
              <div className="standalone-wrapper">
                <div className="standalone-text-wrapper">
                  <div className="iconWrapper">
                    <span className="elig_icon_wt"></span>
                  </div>
                  <div className="textWrapper legal-copy">
                    <div className="comp-rich-text">
                      <p>
                        Calls cost no more than regular calls to geographic
                        numbers (01 or 02) and are included in inclusive minutes
                        and discount schemes. Calls from landlines are typically
                        charged up to 10p per minute; calls from mobiles
                        typically cost between 10p and 40p per minute. Calls
                        from landlines and mobiles are included in free call
                        packages.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Home;
