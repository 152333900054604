const CalculatorRates = [
    {
        'minValue':1000,
        'maxValue':4999,
        'rates': [
            {
                'minMonths':12,
                'maxMonths':36,
                'APR':12.13,
                'Interest':11.50
            },
            {
                'minMonths':37,
                'maxMonths':60,
                'APR':12.68,
                'Interest':12
            },
            {
                'minMonths':61,
                'maxMonths':84,
                'APR':13.19,
                'Interest':12.45
            },
            {
                'minMonths':85,
                'maxMonths':120,
                'APR':13.69,
                'Interest':12.90
            }
        ]
    },
    {
        'minValue':5000,
        'maxValue':9999,
        'rates': [
            {
                'minMonths':12,
                'maxMonths':36,
                'APR':10.47,
                'Interest':10
            },
            {
                'minMonths':37,
                'maxMonths':60,
                'APR':11.02,
                'Interest':10.50
            },
            {
                'minMonths':61,
                'maxMonths':84,
                'APR':11.52,
                'Interest':10.95
            },
            {
                'minMonths':85,
                'maxMonths':120,
                'APR':12.01,
                'Interest':11.40
            }
        ]
    },
    {
        'minValue':10000,
        'maxValue':19000,
        'rates': [
            {
                'minMonths':12,
                'maxMonths':36,
                'APR':9.49,
                'Interest':9.10
            },
            {
                'minMonths':37,
                'maxMonths':60,
                'APR':10.03,
                'Interest':9.60
            },
            {
                'minMonths':61,
                'maxMonths':84,
                'APR':10.53,
                'Interest':10.05
            },
            {
                'minMonths':85,
                'maxMonths':120,
                'APR':11.02,
                'Interest':10.50
            }
        ]
    },
    {
        'minValue':20000,
        'maxValue':29000,
        'rates': [
            {
                'minMonths':12,
                'maxMonths':36,
                'APR':8.62,
                'Interest':8.30
            },
            {
                'minMonths':37,
                'maxMonths':60,
                'APR':9.16,
                'Interest':8.80
            },
            {
                'minMonths':61,
                'maxMonths':84,
                'APR':9.65,
                'Interest':9.25
            },
            {
                'minMonths':85,
                'maxMonths':120,
                'APR':10.14,
                'Interest':9.70
            }
        ]
    },
    {
        'minValue':30000,
        'maxValue':39000,
        'rates': [
            {
                'minMonths':12,
                'maxMonths':36,
                'APR':7.76,
                'Interest':7.50
            },
            {
                'minMonths':37,
                'maxMonths':60,
                'APR':8.30,
                'Interest':8
            },
            {
                'minMonths':61,
                'maxMonths':84,
                'APR':8.79,
                'Interest':8.45
            },
            {
                'minMonths':85,
                'maxMonths':120,
                'APR':9.27,
                'Interest':8.90
            }
        ]
    },
    {
        'minValue':40000,
        'maxValue':50000,
        'rates': [
            {
                'minMonths':12,
                'maxMonths':36,
                'APR':6.96,
                'Interest':6.75
            },
            {
                'minMonths':37,
                'maxMonths':60,
                'APR':7.50,
                'Interest':7.25
            },
            {
                'minMonths':61,
                'maxMonths':84,
                'APR':7.98,
                'Interest':7.70
            },
            {
                'minMonths':85,
                'maxMonths':120,
                'APR':8.46,
                'Interest':8.15
            }
        ]
    }
]

export default CalculatorRates;