
const envConfig = {
  debug: false,
  ENV: process.env.NODE_ENV,
  API: {
    DOMAIN: process.env.NATWEST_BE_API_URL,
    // DOMAIN: 'https://backend-dot-rbs-lombard-calc-s.nw.r.appspot.com',
    // DOMAIN: 'http://localhost:3009',
    PATH: {
      ADDRESS_SEARCH: '/api/v1/address/search',
      ADDRESS_DETAILS: '/api/v1/address/details',
      COMPANY_SEARCH: '/api/v1/company/search',
      COMPANY_DETAILS: '/api/v1/company/details',
      EXPERIAN_CHECK: '/api/v1/experian/check',
      MAINTENANCE_CHECK: '/api/v1/maintenance',
    },
  }, 
};

export default envConfig;
// console.log(envConfig);