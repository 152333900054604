import _ from 'lodash';
import React, {useContext, useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { withRouter } from "react-router-dom";

import { ReactComponent as IconError } from "../../../assets/images-new/error.svg";
import { Context } from '../../../stores/store'
import { navToNext, navToPrev, calcNewFormData } from '../../../common/navigate';
import Field from '../../../components/fields/field';
import { trackApplicationStep } from '../../../common/tagging';
import { checkDowntime } from '../../../common/request';

const AboutApplicant = ({ history }) => {
  const [state, dispatch] = useContext(Context);
  const formRegister = useForm({
    mode: 'onChange',
  });
  const {
    handleSubmit, 
    watch, 
    setValue, 
    getValues,
  } = formRegister;
  const [loaded, isLoaded] = useState(false);
  const [serviceAvailable, setServiceAvailable] = useState(true);
  const watchAllFields = watch({ nest: true });
  const currentStepIdx = 1;
  const pageStateData = _.get(state, 'formData.data.aboutApplicant');


  const onApplicantSelectionChangeHandler = async (val) => {
    console.log(`[onApplicantSelectionChangeHandler] ${val}`);
    if (val === 'Individual') {
      setServiceAvailable(true);
      return;
    }

    // clear company-search fields
    
    const results = await checkDowntime();
    setServiceAvailable(results.serviceAvailable);
  }

  /**
   * 
   */
  useEffect(() => {
    if (loaded) {
      return;
    }

    /****** first load ******/
    // 1, tagging
    trackApplicationStep('about-applicant');

    // 2, load existing data to form
    const arr = [];
    for (const key in pageStateData) {
      arr.push({ [key]: pageStateData[key] });
    }
    setValue(arr);
    setTimeout(() => { setValue(arr); }, 10);
    
    if (pageStateData.applicantType) {
      onApplicantSelectionChangeHandler(pageStateData.applicantType);
    }
    
    /****** first load completed ******/
    isLoaded(true);
    console.log('serviceAvailable', serviceAvailable);
  }, [state, loaded, setValue, pageStateData, serviceAvailable]);

  /**
   *  
   */
  const submitHandler = (data) => {
    // console.log(`[onSubmit] ${JSON.stringify(data)}`);

    if (!serviceAvailable) {
      return;
    }

    // update store
    const newFormData = calcNewFormData(state.formData, currentStepIdx, data);
    dispatch({ type: 'FORM_STEP', payload: newFormData });

    // nav to next step
    // console.log(data.applicantType);
    const isIndividual = data.applicantType === 'Individual';

    navToNext(currentStepIdx, state.formData.steps, history, isIndividual ? 'company-details' : null);
  }

  /**
   * 
   */
  const prevBtnHandler = () => {
    const newFormData = calcNewFormData(state.formData, currentStepIdx, watchAllFields);
    dispatch({ type: 'FORM_STEP', payload: newFormData });

    navToPrev(currentStepIdx, state.formData.steps, history);
  }

  return (
    <>
    {/* <div className='log-block log-block-2'><p>{JSON.stringify(watchAllFields)}</p></div> */}
    <div className='ec__banner row'>
      <div className='ec__banner-inner col-lg-8 col-lg-offset-2'>
        <div className="ec__banner-title">Eligibility Checker</div>
      </div>
    </div>

    <div className='ec__main row'>
      <div className='ec__main-inner col-lg-8 col-lg-offset-2'>
        <form onSubmit={handleSubmit(submitHandler)} className="ec--form ec--step-one active">
          <div className="ec--form-wrapper">

            {/* <Breadcrumbs */}
            {/* <Banner title="Eligibility Checker" /> */}

            <div className='ec--step-progress'><b>Step 2</b> of 4</div>
            <div className='ec--step-progress-bar step-2'>
              <div className='ec--step-progress-bar-dot'></div>
              <div className='ec--step-progress-bar-dot'></div>
              <div className='ec--step-progress-bar-dot'></div>
              <div className='ec--step-progress-bar-step'></div>
            </div>
            <div className='ec--step-title'><h2>Type of applicant</h2></div>
            <div className='ec--step-subtitle'>Will you be paying for this asset through a business or personal account?</div>


            <Field
              type='field-radio'
              name='applicantType'
              subType='field-radio-button'
              size='lg'
              groupTitle={`I’m paying through a business account`}
              options={[
                'Limited Company',
                'Limited Liability Partnership',
                'Sole Trader',
                'Partnership',
              ]}
              formRegister={formRegister}
              hideError={true}
              validate={(val) => {
                if (!getValues('applicantType')) {
                  return 'Please select the type of applicant'
                }
              }}
              onChange={(val) => {
                // clear company-search fields
                // clear company-details fields
                dispatch({ type: 'FORM_STEP_DATA', payload: ['formData.data.companySearch', {}] });
                dispatch({ type: 'FORM_STEP_DATA', payload: ['formData.data.companyDetails', {}] });

                onApplicantSelectionChangeHandler(val);
              }}
            />
            <Field
              type='field-radio'
              name='applicantType'
              subType='field-radio-button'
              size='lg'
              groupTitle={`I’m paying through a personal account`}
              options={[
                'Individual',
              ]}
              formRegister={formRegister}
              validate={(val) => {
                if (!getValues('applicantType')) {
                  return 'Please select the type of applicant'
                }
              }}
              onChange={(val) => {
                // clear company-search fields
                // clear company-details fields
                dispatch({ type: 'FORM_STEP_DATA', payload: ['formData.data.companySearch', {}] });
                dispatch({ type: 'FORM_STEP_DATA', payload: ['formData.data.companyDetails', {}] });

                onApplicantSelectionChangeHandler(val);
              }}
            />

            {watchAllFields.applicantType === 'Individual' &&
            <Field
              type='field-checkbox'
              name='individualConfirmed'
              label={<span>I confirm I’m either a Director, Partner or Owner of a business and I am based in the UK. I am purchasing this asset for personal use only and will make monthly repayments via a Personal Bank Account<br />
              <br />
              Not correct? <a href="https://quote.lombard.co.uk/contact-us" target="_blank" rel="noopener noreferrer">Contact us</a></span>}
              formRegister={formRegister}
              validate={(val) => {
                if (!val) {
                  return 'Please confirm your status';
                }
              }}
            />}

            {!serviceAvailable && <div className='ec__warning ec__warning--error'>
              <div className='inner-left-border-red'></div>
              <div className='ec__warning-icon'><IconError /></div>
              <div className='ec__warning-title'>Service Unavailable</div>
              <div className='ec__warning-content'>Sorry our Eligibility Checker is currently unavailable due to routine service maintenance. Please try again after 6am or contact us Mon-Fri between 9am and 5pm on: Phone: 0800 502 402 or Relay UK: 18001 0800 502 402. Standard call charges apply.</div>
            </div>}


            <div className="ec--step-bottom">
              <button type="submit" className="btn btn-primary">Continue</button>
              <button type="button" className="btn btn-link" onClick={prevBtnHandler}>Previous</button>
            </div>
            
            
          </div>
        </form>
      </div>
    </div>
    </>
  );
}

export default withRouter(AboutApplicant);