/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import axios from 'axios';
import cx from "classnames";
import _ from 'lodash';
import React, { useState, useEffect, useContext } from "react";
import NumberFormat from "react-number-format";
import Slider from "react-rangeslider";

import CalculatorRates from "../../constants/CalculatorRates";
import { Context } from "../../stores/store";


import PlusIcon from "../../assets/images/plus_icon.png";
import MinusIcon from "../../assets/images/minus_icon.png";
import Info from "../../assets/images/info_icon.png";
import InfoWhite from "../../assets/images/info_icon.png";
import Chevron from "../../assets/images/Chevron.svg";

// import Slider, { Range } from 'rc-slider';
// import 'rc-slider/assets/index.css';

import "react-rangeslider/lib/index.css";
import "./Calculator.scss";
import envConfig from "../../common/env.config";

const Calculator = ({history}) => {
  const [state, dispatch] = useContext(Context);

  const [loaded, hasLoaded] = useState(false);
  const [apiLoaded, hasApiLoaded] = useState(0);

  const [loanFor, setLoanFor] = useState("car");
  const [lumpsum, setLumpsum] = useState("no");

  const [loanYears, changeYear] = useState(state.calculator.years);
  const [purchasePrice, changePurchasePrice] = useState(state.calculator.years);
  const [purchasePriceVAT, changePurchasePriceVAT] = useState(26000);
  const [loanAmount, changeLoanAmount] = useState(purchasePriceVAT * 20 / 100);
  const [displayLoanAmount, changeDisplayLoanAmount] = useState(6500);


  const [loanMonths, changeMonth] = useState(48);
  const [displayLoanMonths, changeDisplayLoanMonth] = useState(48);
  const [turnover, changeTurnover] = useState("Under £2m");
  const [dropdownState, changeDropdownState] = useState("closed");
  const [eligibleForLoan, setEligibility] = useState(true);
  const [initialDataExist, setInitialDataExist] = useState(true);


  const [loanInterestRate,setLoanInterestRate] = useState(0)
  const [totalAmountPayable, setTotalAmountPayable] = useState(0)
  const [monthlyPayment, setMonthlyPayment] = useState(0)
  const [apr, setApr] = useState(0)

  const [amountState, amountStateSet] = useState("untouched"); // 'untouched' || 'touched' || 'error' || 'success'
  const [yearState, yearStateSet] = useState("untouched"); // 'untouched' || 'touched' || 'error' || 'success'
  const [monthState, monthStateSet] = useState("untouched"); // 'untouched' || 'touched' || 'error' || 'success'
  const [purchasePriceState, purchasePriceStateSet] = useState("untouched"); // 'untouched' || 'touched' || 'error' || 'success'
  const [purchasePriceVATState, purchasePriceVATStateSet] =
    useState("untouched"); // 'untouched' || 'touched' || 'error' || 'success'
  const [turnoverState, turnoverStateSet] = useState("untouched"); // 'untouched' || 'touched' || 'error' || 'success'
  const [amountSliderState, amountSliderStateSet] = useState("untouched"); // 'untouched' || 'touched' || 'error' || 'success'

  const [totalBorrLess, setTotalBorrLess] = useState(false)

  const [minLoan, changeMinLoan] = useState(
    ((purchasePriceVAT || 0 * 0) / 100).toFixed(2)
  );
  const [maxLoan, changeMaxLoan] = useState(
    ((purchasePriceVAT || 0 * 25) / 100).toFixed(2)
  );


  // constants for calculator

var displayLoan
const CARS_TURNOVER_0_2M = 3.04;
const CARS_TURNOVER_2_6pt5M = 2.74;
const CARS_TURNOVER_6pt5Mplus = 2.01;
const CARS_PD_GRADE = 1.50;
const VANS_TURNOVER_0_2M = 3.11;
const VANS_TURNOVER_2_6pt5M = 2.81;
const VANS_TURNOVER_6pt5Mplus = 1.59;
const VANS_PD_GRADE = 1.50; // VALUE MAY CHANGE LATER
const TERM_less5Y = 0;
const TERM_more5Y = 0.25;
const BALLOON_NOTPRESENT = 0;
const BALLOON_PRESENT = 1.25;
const DEPOSIT_0 = 0;
const DEPOSIT_1_10 = -0.30;
const DEPOSIT_11_20 = -0.60;
const DEPOSIT_20plus = -0.90;
const AMOUNT_FINANCED_0_10K = 2;
const AMOUNT_FINANCED_10_25K = 1;
const AMOUNT_FINANCED_25_50K = 0.25;
const AMOUNT_FINANCED_50_75K = 0;
const AMOUNT_FINANCED_75_150K = -0.50;
const AMOUNT_FINANCED_150Kplus = -0.80;
const [loanRates, setLoanRates] = useState(null);

// let loanRates = null;
  // 

  const loanStep = 100;
  const minPurchasePrice = 5000;
  const maxPurchasePrice = 250000;
  const minTerm = 12;
  const maxTerm = 84;
  const termStep = 6;
  const MAX_VAL = 999999;
  const withValueLimit = ({ floatValue }) => (!floatValue || (floatValue&&floatValue <=MAX_VAL));
  useEffect(() => {
    // console.warn(`You clicked ${loanFor} times`);
    onChangeAmount(loanAmount);
  }, [maxLoan, lumpsum]);

  useEffect(()=>{
    if(apiLoaded<2){
      getInterestRates()
      hasApiLoaded(apiLoaded+1)
    }
  },[loanRates])

  useEffect(() => {
    if (!loaded) {
      getInterestRates()
      updateCalculator(loanAmount, getLoanTermInMonths());
      hasLoaded(true);
      dispatch({ type: "UPDATE_LOAN_AMOUNT", payload: 6500 });
    }
  }, []);

  useEffect(()=>{
    const apRate = (Math.pow((1 + loanInterestRate / 100 / 12), 12) - 1) * 100;
    // console.warn('APR '+ apRate);
    setApr(apRate)
  },[loanInterestRate, totalAmountPayable, ])

  useEffect(()=>{
    let value = purchasePriceVAT || 0;
    var min = (value * 0) / 100 || 0;
    if (lumpsum === "no") var max = (value * 50) / 100 || 0;
    else max = (value * 25) / 100 || 0;

    if (value >= 5000) {
      max = Math.min(value - 5000, max);
    }

    if(loanFor === 'van'){
      min = min + purchasePriceVAT*(20/100)
      max = max + purchasePriceVAT*(20/100)
    }
    min = min.toFixed(2);
    max = max.toFixed(2);

    changeMinLoan(min);
    changeMaxLoan(max);
  },[loanFor,lumpsum])

  useEffect(() => {
    // console.error('computing rate');
    computeInterestRate();    
  }, [purchasePriceVAT,loanAmount,displayLoanMonths,turnover,loanFor,lumpsum, apr]);

  useEffect(()=>{
    let value = purchasePriceVAT || 0;
    var min = (value * 0) / 100;
    let max = 0;

    if (lumpsum === "no") {
      max = (value * 50) / 100 || 0;
    } else {
      max = (value * 25) / 100 || 0;
    }

    if (value >= 5000) {
      max = Math.min(value - 5000, max);
    }

    if (loanFor === 'van') {
      min += value * (20 / 100);
      max += value * (20 / 100);
    }
    min = min.toFixed(2);
    max = max.toFixed(2);

    changeMinLoan(min);
    changeMaxLoan(max);
    // onChangeAmount
    // if(loanAmount< minPurchasePrice)
    // setTimeout(() => {
    //   onChangeAmount(loanAmount - 1);
    // }, 0);
    // setTimeout(() => {
      onChangeAmount(loanAmount);
    // }, 0);

  },[purchasePriceVAT])
  const getLoanTermInMonths = () => {
    return displayLoanMonths;
  };

  const onChangeAmount = (value) => {
    setTotalBorrLess(false)

    if (value === "") {
      // dispatch({type: 'UPDATE_LOAN_AMOUNT', payload: value});
      // changeLoanAmount('');
      amountStateSet("error");
    } else {
      let totalBorr = purchasePriceVAT - value;

      if (loanFor === 'van') {
        totalBorr = purchasePriceVAT + ((purchasePriceVAT || 0) * 0.2) - value;
      }

          let displayLoan = value;
          if(parseFloat(minLoan) > parseFloat(value))
            displayLoan = minLoan
          if(parseFloat(maxLoan) < parseFloat(value))
            displayLoan = maxLoan
          changeDisplayLoanAmount(parseFloat(displayLoan))
      if (
        parseFloat(value) >= parseFloat(minLoan) &&
        parseFloat(value) <= parseFloat(maxLoan)
      ) {
        if (amountSliderState === "touched") {
          amountStateSet("success");
        } else {
          amountStateSet("untouched");
        }
      } else {
        if (amountSliderState === "touched" || true) {
          if (totalBorr < 5000) {
            setTotalBorrLess(true)
          }
          amountStateSet("error");
        }
      }
      // dispatch({type: 'UPDATE_LOAN_AMOUNT', payload: value});
      // console.log('value', value)
      changeLoanAmount(value);
      updateCalculator(value, getLoanTermInMonths());
    }
  };

  const onChangePurchasePrice = (value) => {
    // console.error(value)
    var min = (value * 0) / 100 || 0;
    if (lumpsum === "no") var max = (value * 50) / 100 || 0;
    else max = (value * 25) / 100 || 0;
    if(loanFor === 'van'){
      min = min + purchasePriceVAT*(20/100)
      max = max + purchasePriceVAT*(20/100)
    }
    min = min.toFixed(2);
    max = max.toFixed(2);
    changeMinLoan(min);
    changeMaxLoan(max);
    // onChangeAmount
    // if(loanAmount< minPurchasePrice)
    // setTimeout(() => {
    //   onChangeAmount(loanAmount - 1);
    // }, 0);
    // setTimeout(() => {
    //   onChangeAmount(loanAmount);
    // }, 0);
    // else if (loanAmount> maxPurchasePrice)
    // onChangeAmount(max)
    if (value === "") {
      // dispatch({ type: "UPDATE_LOAN_AMOUNT", payload: value });
      setEligibility(false);
      purchasePriceVATStateSet("error");
    } else {
      if (value >= minPurchasePrice && value <= maxPurchasePrice) {
        if (amountSliderState === "touched" || true) {
          purchasePriceVATStateSet("success");
          if (turnover !== "Over £25m") setEligibility(true);
        }
      } else {
        if (amountSliderState === "touched" || true) {
          setEligibility(false);
          purchasePriceVATStateSet("error");
        }
      }
      dispatch({ type: "UPDATE_LOAN_AMOUNT", payload: value });
      updateCalculator(value, getLoanTermInMonths());
    }
    // if (parseFloat(value) < 999999) {
      changePurchasePriceVAT(value);
    // }
  };

  const onChangeMonths = (e) => {
    let monthValue = e?.target?.value || e;
    if (monthValue === "" || isNaN(monthValue)) {
      changeMonth("");
      monthStateSet("error");
    } else {
      if (!monthValue || monthValue >= 100 || (monthValue+'').length>2) {
        return;
      }

      if(minTerm > monthValue)
        changeDisplayLoanMonth(minTerm)
      else if(maxTerm < monthValue)
        changeDisplayLoanMonth(maxTerm)
      else
        changeDisplayLoanMonth(monthValue)

      if (
        parseInt(monthValue) >= minTerm &&
        parseInt(monthValue) <= maxTerm
      ) {
        monthStateSet("success");
      } else {
        monthStateSet("error");
      }
      const value = parseInt(monthValue);
      // const yearsToMonth = loanYears * 12;
      const final = value;
      dispatch({ type: "UPDATE_LOAN_LENGTH_MONTHS", payload: value });
      changeMonth(value);
      updateCalculator(loanAmount, final);
    }
  };

  const onChangeYears = (e) => {
    if (e.target.value === "" || isNaN(e.target.value)) {
      changeYear("");
      yearStateSet("error");
    } else {
      if (
        parseInt(e.target.value) >= minTerm &&
        parseInt(e.target.value) <= maxTerm
      ) {
        yearStateSet("success");
      } else {
        yearStateSet("error");
      }
      const value = parseInt(e.target.value);
      let final = value;
      // if(value >= 10) {
      // final = value;
      // dispatch({type: 'UPDATE_LOAN_LENGTH_MONTHS', payload: 0});
      // changeMonth(0);
      // } else {
      // final = (value * 12) + loanMonths

      // }
      changeYear(parseInt(e.target.value));
      // dispatch({type: 'UPDATE_LOAN_LENGTH_YEARS', payload: value});s
      updateCalculator(loanAmount, final);
    }
  };

  const formatNumber = (num, forceDecimalPlace) => {
    // in case num has long decimal place
    num = Math.round(num * 1000000) / 1000000;
    num = Math.round(num * 100) / 100;
    
    
    // console.error(num)
    num = (num + "").split(".");
    const s = num[0];
    let f = ""; // the decimal part
    if (num.length === 2) {
      f = num[1];
    }
    let str = "";
    for (var i = 0; i <= Math.floor(s.length); i += 3) {
      str = s.slice(Math.max(s.length - i - 3, 0), s.length - i) + "," + str;
    }
    
    if (str.startsWith(",")) {
      str = str.slice(1, str.length - 1);
    }
    else {
      str = str.slice(0, str.length - 1);
    }
    
    if (f !== "") {
      str = str + "." + f.padEnd(2, "0");
    }

    if (f === '') {
      if (forceDecimalPlace === 2) {
        str += '.00';
      }
    }

    return str;
  };

  const handlePriceDropdown = (e) => {
      e.stopPropagation();
                  dropdownState === "open"
                    ? changeDropdownState("closed")
                    : changeDropdownState("open");
  }

  const handleTermSlider = (value) => {
    // var yearValue = value
    var monthValue = value;
    // if (yearValue === 10) {
    //   dispatch({type: 'UPDATE_LOAN_LENGTH_YEARS', payload: yearValue});
    //   dispatch({type: 'UPDATE_LOAN_LENGTH_MONTHS', payload: 0});
    // } else {
    //   dispatch({type: 'UPDATE_LOAN_LENGTH_YEARS', payload: yearValue});
    //   dispatch({type: 'UPDATE_LOAN_LENGTH_MONTHS', payload: monthValue});
    // }
    // changeYear(yearValue);
    onChangeMonths(monthValue)
    changeDisplayLoanMonth(monthValue)
    changeMonth(monthValue);
    updateCalculator(loanAmount, value);
    // console.log(loanAmount, value)
  };

  const handleLoanSlider = (value) => {
    let loanValue = parseFloat(value)
    // amountSliderStateSet("untouched");
    // amountStateSet("untouched");
    // if(value>maxLoan)
    //   value = maxLoan
    // if(value<minLoan)
    //   value = minLoan
    // dispatch({type: 'UPDATE_LOAN_AMOUNT', payload: value});
    // onChangeAmount(value)
    changeLoanAmount(loanValue);
    changeDisplayLoanAmount(loanValue);
    onChangeAmount(loanValue)
    updateCalculator(value, getLoanTermInMonths());
  };

  const calculateTotal = (months, amount, interest) => {
    const monthlyInterest = interest / 100.0 / 12;
    const total =
      amount * (monthlyInterest / (1 - Math.pow(1 + monthlyInterest, -months)));
    return total;
  };

  const updateCalculator = (amount, term) => {
    for (var i = 0; i < CalculatorRates.length; i++) {
      if (
        amount >= CalculatorRates[i].minValue &&
        amount <= CalculatorRates[i].maxValue
      ) {
        for (var o = 0; o < CalculatorRates[i].rates.length; o++) {
          if (
            term >= CalculatorRates[i].rates[o].minMonths &&
            term <= CalculatorRates[i].rates[o].maxMonths
          ) {
            const rate = CalculatorRates[i].rates[o].Interest;
            const total = calculateTotal(term, amount, rate);
            const result = {
              repayments: total.toFixed(2),
              total: (total * term).toFixed(2),
              interestRate: `${rate}%`,
              APR: `${CalculatorRates[i].rates[o].APR}%`,
            };
            dispatch({ type: "UPDATE_RESULTS", payload: result });
          }
        }
      }
    }
  };

  const computeInterestRate = () => {
    // [purchasePriceVAT,loanAmount,loanMonths,turnover,loanFor,lumpsum];
    // console.warn('-------------------------------------')
    let margin = 0;
    if (loanFor === 'car'){
      if (turnover === 'Under £2m'){
      margin = margin + CARS_TURNOVER_0_2M
      // console.warn('turnover: ',CARS_TURNOVER_0_2M+'%')
        }
      else if (turnover === 'Between £2m and £6.5m'){ 
        margin = margin + CARS_TURNOVER_2_6pt5M
      // console.warn('turnover: ',CARS_TURNOVER_2_6pt5M+'%')
    }
      else if (turnover === 'Between £6.5m and £25m'){
        margin = margin + CARS_TURNOVER_6pt5Mplus
      // console.warn('turnover: ',CARS_TURNOVER_6pt5Mplus+'%')
    }
      else if (turnover === 'Over £25m'){
        setEligibility(false)
          return;
        }
        // console.warn('PD grade: ',CARS_PD_GRADE+'%')
      margin = margin + CARS_PD_GRADE
      // console.warn('car PD: ',CARS_PD_GRADE)
    } 


    else if (loanFor === 'van') {
      if (turnover === 'Under £2m'){
        margin = margin + VANS_TURNOVER_0_2M
        // console.warn('turnover: ',VANS_TURNOVER_0_2M+'%')
        }
      else if (turnover === 'Between £2m and £6.5m'){
        margin = margin + VANS_TURNOVER_2_6pt5M
        // console.warn('turnover: ', VANS_TURNOVER_2_6pt5M+'%')  
        }
      else if (turnover === 'Between £6.5m and £25m'){
        margin = margin + VANS_TURNOVER_6pt5Mplus
        // console.warn('turnover: ', VANS_TURNOVER_6pt5Mplus+'%')
        }
      else if (turnover === 'Over £25m'){          
          setEligibility(false)
          return;
        }
      margin = margin + VANS_PD_GRADE
      // console.warn('PD grade: ',VANS_PD_GRADE+'%')
      // console.warn('van PD: ', VANS_PD_GRADE)
    }

    if (parseInt(displayLoanMonths) > 84){
      // setEligibility(false)
      return;
    }
    if (parseInt(displayLoanMonths) <= 60){
      margin = margin + TERM_less5Y
      // console.warn('term: ', TERM_less5Y+'%')
    }
    else if (parseInt(displayLoanMonths) > 60){
      margin = margin + TERM_more5Y
      // console.warn('term: ', TERM_more5Y+'%')
    }
    if (lumpsum === 'yes'){
      margin = margin + BALLOON_PRESENT
      // console.warn('balloon: ', BALLOON_PRESENT+'%')
    } 
    else if (lumpsum === 'no'){
      margin = margin + BALLOON_NOTPRESENT
      // console.warn('balloon: ',BALLOON_NOTPRESENT+'%')
    }


    // for cars
    let depositPercentage = displayLoanAmount / purchasePriceVAT * 100
    //  for vans
    if(loanFor ==='van'){
      depositPercentage = (displayLoanAmount-(purchasePriceVAT* 20 / 100)) / purchasePriceVAT * 100
    } 
    // console.warn('deposit percentage: ',depositPercentage)
if (depositPercentage === 0.00){
   margin = margin + DEPOSIT_0
  //  console.warn('deposit: ', DEPOSIT_0+'%')
  }
else if (depositPercentage > 0.00 && depositPercentage <= 10.00){
   margin = margin + DEPOSIT_1_10
  //  console.warn('deposit: ',DEPOSIT_1_10+'%')
  }
else if (depositPercentage > 10.00 && depositPercentage <= 20.00){
   margin = margin + DEPOSIT_11_20
  //  console.warn('deposit: ',DEPOSIT_11_20+'%')
  }
else if (depositPercentage > 20.00){
   margin = margin + DEPOSIT_20plus
  //  console.warn('deposit: ', DEPOSIT_20plus+'%')
  }


  let amount_financed = purchasePriceVAT - displayLoanAmount
  if (loanFor === 'van'){
    amount_financed = (purchasePriceVAT*1.2 - displayLoanAmount)
  }
  // console.warn("amount_financed",amount_financed)
  if (amount_financed >= 0 && amount_financed <= 10000.00){
    margin = margin + AMOUNT_FINANCED_0_10K
    // console.warn('amt financed:', AMOUNT_FINANCED_0_10K+'%')
  }
  else if (amount_financed > 10000.00 && amount_financed <= 25000.00){
    margin = margin + AMOUNT_FINANCED_10_25K
    // console.warn('amt financed:', AMOUNT_FINANCED_10_25K+'%')
  }
  else if (amount_financed > 25000.00 && amount_financed <=50000.00){
    margin = margin + AMOUNT_FINANCED_25_50K
    // console.warn('amt financed:', AMOUNT_FINANCED_25_50K+'%')
  }
  else if (amount_financed > 50000.00 && amount_financed <= 75000.00){
    margin = margin + AMOUNT_FINANCED_50_75K
    // console.warn('amt financed:', AMOUNT_FINANCED_50_75K+'%')
  }
  else if (amount_financed > 75000.00 && amount_financed <= 150000.00){
    margin = margin + AMOUNT_FINANCED_75_150K
    // console.warn('amt financed:', AMOUNT_FINANCED_75_150K+'%')
  }
  else if (amount_financed > 150000.00){
    margin = margin + AMOUNT_FINANCED_150Kplus
    // console.warn('amt financed:', AMOUNT_FINANCED_150Kplus+'%')
  }

  const term_Years = Math.ceil((displayLoanMonths || 0)/12)
  // console.warn.error('term_Years')
  // console.warn('margin: ',margin+'%')
  const fundingCost = loanRates ? (loanRates.filter(d =>  d.termInYears === term_Years))[0] : {interestRate:0, termInYears:0};

  // if (fundingCost && fundingCost.termInYears && fundingCost.interestRate) {
  //   console.warn('funding cost: ', fundingCost.termInYears+'yrs' || '-' ,'---', fundingCost.interestRate+'%' || '-')    
  //   // console.error(new Date())
  //   console.error('---- no more error ----')
  // } else {
  //   // console.error(new Date())
  //   console.error('error calculating funding cost', fundingCost , fundingCost?fundingCost.termInYears:null , fundingCost?fundingCost.interestRate: null)
  // }

  const interestRate = margin + (fundingCost ?fundingCost.interestRate : 0); 
  // console.warn('interest rate: ',interestRate+'%')
    setLoanInterestRate(interestRate)
    let balloon = 0;
    if(lumpsum === 'yes'){
      balloon = parseFloat(purchasePriceVAT) / 2;
    }
    const monthlyPayment = computeMonthlyCost(interestRate,displayLoanMonths,amount_financed,balloon)
    const amountPayable = computeTotalAmountPayable(displayLoanMonths, monthlyPayment, balloon, displayLoanAmount, 0, 0)
    // console.warn('monthly cost: £'+ monthlyPayment)
    // console.warn('total amount payable: £'+ amountPayable)
    // console.warn('-------------------------------------')
    const borrowAmt = loanFor === 'car' ? (purchasePriceVAT - displayLoanAmount).toFixed(2) : (purchasePriceVAT*1.2 - displayLoanAmount).toFixed(2);
    // const apRate = ((((totalAmountPayable - borrowAmt)/ borrowAmt) / 
    // (loanMonths * 30.41))) * 365 * 100
    if(monthlyPayment && monthlyPayment !== Infinity)
      setMonthlyPayment(monthlyPayment)
    setTotalAmountPayable(amountPayable)
}


  const computeMonthlyCost = (yearlyInterestRate, totalNumberOfMonths,displayLoanAmount, balloon)=>{ 
    const yearlyPercentInterestRate = yearlyInterestRate / 100;    // monthly rate, e.g. 0.2% per month
    const monthlyPercentageInterestRate = yearlyPercentInterestRate / 12;
    const denominator = Math.pow((1 + monthlyPercentageInterestRate), totalNumberOfMonths) - 1;
    const monthlyCost = (monthlyPercentageInterestRate + (monthlyPercentageInterestRate / denominator)) * (displayLoanAmount - balloon) + (balloon * monthlyPercentageInterestRate);
    return parseFloat((monthlyCost).toFixed(2));
  }


  const computeTotalAmountPayable = (termInMonths, monthlyPayment, balloon, totalDeposit, optionToPurchaseFee, arrangementFee) =>
  {    
    return (termInMonths * monthlyPayment) + balloon + totalDeposit + optionToPurchaseFee + arrangementFee;
  }

  const getInterestRates = () => {
    axios.get(`${envConfig.API.DOMAIN}/api/v1/funding-cost`)
      .then(res => {
        if (res.status === 200) {
          // console.log(res.data);
          const loanRates = _.map(res.data, (item) => {
            return {
              id: item.id,
              termInYears: item.termInYears,
              interestRate: item.rate,
            };
          });

          setLoanRates(loanRates);
          computeInterestRate();
          setInitialDataExist(true);
        } else {
          console.error(res)
          setInitialDataExist(false);
        }
      }).catch(error => {
        console.error('error in API call->', error);
        // show the error state
        setInitialDataExist(false);
      })
  }

  return (
    <div
      className="Calculator"
      id="quickquote"
      onClick={(e) => {
        changeDropdownState("closed");
      }}
    >
      <h2 className="Calculator__title">Quickly see what you could repay</h2>
      <div className="Calculator__divider">
        <div className="Calculator__input">
          <div className={"Calculator__input__cover" + (initialDataExist ? 'hidden' : '')}></div>



          {/***********************************************
           * SECTION - Asset Type
           * *********************************************/}
          <div className={"Calculator__input__single" + (loanFor === 'van' ? " van__alt" : "")} id="tabs">
            <div className="Calculator__toggles-wrapper">
              {/* <button className='Calculator__toggle Calculator__toggle_active'>Car</button> */}
              <button
                className={cx(
                  "Calculator__toggle__left",
                  loanFor === "car" ? "Calculator__toggle_active" : ""
                )}
                onClick={(e) => {
                  setLoanFor("car");
                }}
              >
                Car
              </button>
              <button
                className={cx(
                  "Calculator__toggle__right",
                  loanFor === "van" ? "Calculator__toggle_active" : ""
                )}
                onClick={(e) => {
                  setLoanFor("van");
                }}
              >
                Van
              </button>
            </div>
            <p className="Calculator__input_info">
              For other assets or personal use,{" "}
              <a
                href="https://quote.lombard.co.uk/landing"
                className="ext-link"
              >
                get a quote here
              </a>
            </p>
            <p className="Calculator__input_info">
            For Variable Rate Hire Purchase quotes{" "}
              <a
                href="#contact"
                className="ext-link"
              >
                Contact Us
              </a>
            </p>
          </div>



          {/***********************************************
           * SECTION - Turnover
           * *********************************************/}
          <div className={"Calculator__input__single" + (loanFor === 'van' ? " van" : "")} id="turnover">
            <label htmlFor="purchasePrice">Your turnover (gross)</label>
            {/* <input 
                  className={cx("CI__full", purchasePriceState)}
                  type="text" 
                  name="turnover"
                  value={turnover}
                  onFocus={() => turnoverStateSet('touched')}
                  onBlur={() => turnoverStateSet('untouched')}
                  onChange={(e) => onChangeYears(e)}
                    /> */}
            <div className={cx("CI__full dropdown", purchasePriceState)}>
              <div
                tabIndex={initialDataExist ? "0" : "-1"}
                className="dropdown_option dropdown_option_selected"
                onKeyPress={(e)=>{handlePriceDropdown(e)}}
                onClick={(e) => {
                  e.stopPropagation();
                  dropdownState === "open"
                    ? changeDropdownState("closed")
                    : changeDropdownState("open");
                }}
              >
                <div>{turnover}</div>
                <img
                  className={cx(
                    "dropdown-indicator",
                    "indicator-" + dropdownState
                  )}
                  src={Chevron}
                />
              </div>
              <div className={cx("dropdown-" + dropdownState)}>
                <div
                  className="dropdown_option"
                  tabIndex={initialDataExist ? "0" : "-1"}
                  onKeyPress={(e)=>{
                    if((e.key) === 'Enter'){
                      e.stopPropagation();
                      if (purchasePriceVAT < 250001) setEligibility(true);
                      changeTurnover("Under £2m");
                      changeDropdownState("closed");
                    }}}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (purchasePriceVAT < 250001) setEligibility(true);
                    changeTurnover("Under £2m");
                    changeDropdownState("closed");
                  }}
                >
                  <div>Under £2m</div>
                </div>
                <div
                  className="dropdown_option"
                  tabIndex={initialDataExist ? "0" : "-1"}
                  onKeyPress={(e)=>{
                    if((e.key) === 'Enter'){
                      e.stopPropagation();
                      if (purchasePriceVAT < 250001) setEligibility(true);
                      changeTurnover("Between £2m and £6.5m");
                      changeDropdownState("closed");
                    }}}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (purchasePriceVAT < 250001) setEligibility(true);
                    changeTurnover("Between £2m and £6.5m");
                    changeDropdownState("closed");
                  }}
                >
                  <div>Between £2m and £6.5m</div>
                </div>
                <div
                  className="dropdown_option"
                  tabIndex={initialDataExist ? "0" : "-1"}
                  onKeyPress={(e)=>{
                    if((e.key) === 'Enter'){
                      e.stopPropagation();
                      if (purchasePriceVAT < 250001) setEligibility(true);
                      changeTurnover("Between £6.5m and £25m");
                      changeDropdownState("closed");
                    }}}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (purchasePriceVAT < 250001) setEligibility(true);
                    changeTurnover("Between £6.5m and £25m");
                    changeDropdownState("closed");
                  }}
                >
                  <div>Between £6.5m and £25m</div>
                </div>
                <div
                  className="dropdown_option"
                  tabIndex={initialDataExist ? "0" : "-1"}
                  onKeyPress={(e)=>{
                    if((e.key) === 'Enter'){
                      e.stopPropagation();
                      setEligibility(false);
                      changeTurnover("Over £25m");
                      changeDropdownState("closed");
                    }
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    setEligibility(false);
                    changeTurnover("Over £25m");
                    changeDropdownState("closed");
                  }}
                >
                  <div>Over £25m</div>
                </div>
              </div>
            </div>
            {/* <div className={cx("CI__full dropdown", purchasePriceState)}>Hello</div> */}
          </div>
          


          {/***********************************************
           * SECTION - Purchase Price
           * *********************************************/}
          <div className={"Calculator__input__single" + (loanFor === 'van' ? " pp" : "")} id="purchasePriceVAT">
            <label htmlFor="purchasePriceVAT">
              Purchase price ({loanFor === 'van' ? 'excluding' : 'including'}{" "}VAT)
            </label>
            <div className="CI__full__wrap CI__full__wrap_amount">
              <NumberFormat
                tabIndex={initialDataExist ? "0" : "-1"}
                type="text"
                thousandSeparator={true}
                allowEmptyFormatting
                isAllowed={withValueLimit}
                className={cx("CI__full", purchasePriceVATState)}
                name="purchasePriceVAT"
                value={purchasePriceVAT}
                onFocus={(e) => {
                  if (purchasePriceVATState !== "error")
                    purchasePriceVATStateSet("touched");
                }}
                onBlur={() => {
                  if (purchasePriceVATState !== "error")
                    purchasePriceVATStateSet("untouched");
                }}
                onChange={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  let arr = (e.target.value.split('.'));
                  var price = parseFloat(e.target.value.replaceAll(",", ""));
                  if (arr.length === 2 && arr[1].length > 2)
                    price = parseFloat(price.toFixed(2))
                  onChangePurchasePrice(price);
                }}
              />
              {purchasePriceVATState === "error" && (
                <span className="errorMsg">
                  Please enter a value between £{formatNumber(minPurchasePrice)}{" "}
                  and £{formatNumber(maxPurchasePrice)}
                </span>
              )}
            </div>
          </div>
          {
            loanFor === 'van' && 
            (<div className="Calculator__input__single" id="vat">
              <label htmlFor="purchasePriceVAT">
                VAT Payable:&nbsp;&nbsp;<b>£{ formatNumber((purchasePriceVAT || 0) * 0.2, 2) }</b>
              </label>
            </div>)
          }



          {/***********************************************
           * SECTION - Deposit
           * *********************************************/}
          <div className={"Calculator__input__single" + (loanFor === 'van' ? " van" : "")} id="loanAmount">
            <label className="label_flex" htmlFor="deposit">{loanFor === 'van' ? "Deposit (inc. VAT)" : "Deposit"}
            {loanFor === 'van' ? 
            <div className="tooltip_anchor">
                  <img src={Info} />
                  <div className="tooltip">
                  New and used vans are subject to VAT at the current rate of 20% which will need to be funded as part of the deposit. If you believe your asset is subject to a different VAT classification then please <a href="https://quote.lombard.co.uk/contact-us">contact us</a>
                  </div>
                </div>
                : ""}
            </label>
            <div className="CI__full__wrap CI__full__wrap_amount">
              <NumberFormat
                tabIndex={initialDataExist ? "0" : "-1"}
                type="text"
                allowEmptyFormatting
                thousandSeparator={true}                
                isAllowed={withValueLimit}
                className={cx("CI__full", amountState)}
                name="loanAmount"
                value={loanAmount}
                onFocus={() => {
                  if (amountState !== "error") {
                    amountSliderStateSet("touched");
                    amountStateSet("touched");
                  }
                }}
                onBlur={() => {
                  if (amountState !== "error") {
                    amountSliderStateSet("untouched");
                    amountStateSet("untouched");
                  }
                }}
                // onValueChange={(values) => {
                //   const { formattedValue, value } = values;
                //   console.error(values,value)
                //   onChangeAmount(value);
                // }}
                onChange={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  let arr = (e.target.value.split('.'));
                  var price = parseFloat(e.target.value.replaceAll(",", ""));                  
                  if (arr.length === 2 && arr[1].length > 2)
                    price = parseFloat(price.toFixed(2))
                    // console.error(price.toFixed(2))
                  onChangeAmount(price);
                }}
              />
              {amountState === "error" && (
                <span className="errorMsg">
                  {totalBorrLess ? 'Total borrowing must be at least £5,000. ' : ''}Please enter a value between £{formatNumber(minLoan)} and £
                  {formatNumber(maxLoan)}
                </span>
              )}
            </div>
            <div className="Slider_wrapper">
              <div className="button_wrapper">
              <button
                className="btn"
                tabIndex={initialDataExist ? "0" : "-1"}        
                onClick={(e) => {
                  if (parseFloat(minLoan) <= parseFloat(loanAmount) - loanStep)
                    handleLoanSlider(parseFloat(loanAmount) - loanStep);
                  else handleLoanSlider(minLoan);
                }}
              >
                <img src={MinusIcon}></img>
              </button>
              {/* <span className="left">£{minLoan}</span> */}
              </div>
              {/* {displayLoanAmount} */}
              <Slider
                value={displayLoanAmount === "" ? 0 : parseFloat(displayLoanAmount)}
                orientation="horizontal"                
                min={parseFloat(minLoan)}
                max={parseFloat(maxLoan)}
                tooltip={false}
                step={10}
                labels={{
                  0: "£" + formatNumber(minLoan),
                  100: "£" + formatNumber(maxLoan),
                }}
                onChange={initialDataExist ? handleLoanSlider : null}
              />
              <div className="button_wrapper">
              <button
                className="btn"                
                onClick={(e) => {
                  if (parseFloat(maxLoan) > parseFloat(loanAmount) + loanStep)
                    handleLoanSlider(parseFloat(loanAmount) + loanStep);
                  else handleLoanSlider(maxLoan);
                }}
              >
                <img src={PlusIcon}></img>
              </button>
              {/* <span className="right">£{maxLoan}</span> */}
              </div>
            </div>
          </div>



          {/***********************************************
           * SECTION - Term Length
           * *********************************************/}
          <div className={"Calculator__input__single" + (loanFor === 'van' ? " van" : "")} id="termLength">
            <label htmlFor="termLength">Term length</label>
            <div className="CI__full__wrap_years">
              {/* <label htmlFor='loanYears'>
                  {loanYears !== '' ? <span className={loanYears === 10 ? 'padding prepend' : 'prepend'}>{loanYears === 1 ? 'Year' : 'Years'}</span> : null} */}
              <input
                className={cx("CI__full", monthState)}
                type="text"
                name="loanMonths"
                value={loanMonths}                
                onFocus={() => {
                  if (monthState !== "error") monthStateSet("touched");
                }}
                onBlur={() => {
                  if (monthState !== "error") monthStateSet("untouched");
                }}
                onChange={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  onChangeMonths(e);
                }}
              />
              {monthState === "error" && (
                <span className="errorMsg">
                  Please enter a value between {formatNumber(minTerm)} months
                  and {formatNumber(maxTerm)} months
                </span>
              )}
              {/* </label> */}
              {/* <label className={loanYears === 10 ? 'disabled' : ''} htmlFor='loanMonths'> */}
              {/* {loanMonths !== '' ? <span className={loanMonths >= 10 ? 'padding prepend' : 'prepend'}>{loanMonths === 1 ? 'Month' : 'Months'}</span> : null} */}
              {/* <input 
                  className={cx('CI__full', monthState,{'disabled': loanYears === 10})} 
                  type="text" 
                  name="loanMonths"
                  value={loanMonths}
                  onFocus={() => monthStateSet('touched')}
                  onBlur={() => monthStateSet('untouched')}
                  onChange={(e) => onChangeMonths(e)}
                    /> */}
              {/* {monthState === 'error' && (<span className="errorMsg">Please enter a value between 0 and 11</span>)} */}
              {/* </label> */}
            </div>
            <div className="Slider_wrapper">
            <div className="button_wrapper">
              <button              
                className="btn"
                tabIndex={initialDataExist ? "0" : "-1"}
                onClick={(e) => {
                  if (getLoanTermInMonths() - termStep >= minTerm) {
                    // (state.calculator.years) = loanYears + 1
                    // handleTermSlider
                    handleTermSlider(getLoanTermInMonths() - termStep);
                    // changeMonth(0)
                  } else {
                    handleTermSlider(minTerm);
                  }
                }}
              >
                <img src={MinusIcon}></img>
              </button>
              {/* <span className="left">{minTerm} months</span> */}
              </div>
              {/* {displayLoanMonths} */}
              <Slider
                value={displayLoanMonths === "" ? 0 : displayLoanMonths}
                orientation="horizontal"
                min={minTerm}
                max={maxTerm}
                tooltip={false}
                step={termStep}
                labels={{ 0: "12 months", 100: "84 months" }}
                onChange={initialDataExist ? handleTermSlider : null}
              />
              <div className="button_wrapper">
              <button              
                className="btn"
                onClick={(e) => {
                  if (getLoanTermInMonths() + termStep <= maxTerm) {
                    // (state.calculator.years) = loanYears + 1
                    // handleTermSlider
                    handleTermSlider(getLoanTermInMonths() + termStep);
                    // changeYear(parseInt(state.calculator.years))
                    // changeMonth(0)
                    // handleTermSlider()
                  } else {
                    handleTermSlider(maxTerm);
                  }
                }}
              >
                <img src={PlusIcon}></img>
              </button>
              {/* <span className="right">{maxTerm} months</span> */}
              </div>
            </div>
          </div>



          {/***********************************************
           * SECTION - Add Lump Sum
           * *********************************************/}
          <div className="Calculator__input__single" id="tabs">
            <label htmlFor="purchasePrice">
              <span className="label_flex">
                Add a final lump sum (balloon)?
                <div className="tooltip_anchor">
                  <img src={Info} />
                  <div className="tooltip">
                  A final lump sum is a large, one-off payment at the end of
                    your hire purchase agreement. It's designed to reduce your
                    regular monthly repayments, and is sometimes called a
                    balloon payment. If you choose this option it usually
                    increases the total amount repayable.

                  </div>
                </div>
              </span>
            </label>
            <div className="Calculator__toggles-wrapper">
              <button
                className={cx(
                  "Calculator__toggle__left",
                  lumpsum === "yes" ? "Calculator__toggle_active" : ""
                )}
                onClick={(e) => {
                  setLumpsum("yes");
                  // var max = (purchasePriceVAT * 25) / 100 || 0;
                  // if(loanFor == 'van'){
                  //   max = max + purchasePriceVAT*(20/100)
                  // }
                  // max = max.toFixed(2);
                  // changeMaxLoan(max);
                  // handleLoanSlider(10000);
                }}
              >
                Yes
              </button>
              <button
                className={cx(
                  "Calculator__toggle__right",
                  lumpsum === "no" ? "Calculator__toggle_active" : ""
                )}
                onClick={(e) => {
                  setLumpsum("no");
                  // var max = (purchasePriceVAT * 50) / 100 || 0;
                  // if(loanFor == 'van'){
                  //   max = max + purchasePriceVAT*(20/100)
                  // }
                  // max = max.toFixed(2);
                  // changeMaxLoan(max);
                  // onChangeAmount(loanAmount);
                }}
              >
                No
              </button>
            </div>
          </div>
        </div>

        <div className="Calculator__output">
          <h2>
            {!initialDataExist 
              ? 'Oops… looks like something’s gone wrong'
              : eligibleForLoan
                ? "Representative example"
                : "Sorry, we're unable to give a quick quote online"} 
          </h2>
          <div className="Calculator__output_groups">
            <div
              className={cx(
                "Calculator__output_values",
                (eligibleForLoan && initialDataExist) ? "" : "hidden"
              )}
            >
              <div className="Calculator__output_single">
                <span className="CO__label">Monthly repayments</span>
                <span className="CO__value">
                  
                  {monthlyPayment ?'£'+formatNumber(parseFloat(monthlyPayment).toFixed(2)) : ''}
                  </span>
              </div>
              <div className="Calculator__output_single">
                <span className="CO__label">Online arrangement fee</span>
                <span className="CO__value">£0.00</span>
              </div>
              <div className="Calculator__output_single">
                <span className="CO__label">Term length</span>
                <span className="CO__value">{displayLoanMonths || 0} months</span>
              </div>
              <div className="Calculator__output_single">
                <span className="CO__label">Representative APR</span>
                <span className="CO__value">
                  {parseFloat(formatNumber(parseFloat(apr).toFixed(2))).toFixed(1)}%{" "}
                </span>
              </div>
              <div className="Calculator__output_single">
                <span className="label_flex CO__label">Fixed interest rate</span>
                <span className="CO__value label_flex">
                  {formatNumber(
                    parseFloat(loanInterestRate || 0).toFixed(2)
                  )}
                  %
                  <div className="tooltip_anchor">
                    <img src={InfoWhite} />
                    <div className="tooltip">
                    This is a nominal interest rate.  It is applied on a yearly pro-rated basis at the frequency of your payments (e.g. monthly or quarterly) 
                    </div>
                  </div>
                </span>
              </div>
              <div className="Calculator__output_single">
                <span className="CO__label">Purchase price</span>
                <span className="CO__value">
                  £{formatNumber(parseFloat(purchasePriceVAT).toFixed(2), 2)}
                </span>
              </div>
              <div className="Calculator__output_single">
                <span className="CO__label">{loanFor === 'van' ? "Deposit (inc. VAT)" : "Deposit"}</span>
                <span className="CO__value">{displayLoanAmount ? '£'+formatNumber((parseFloat(displayLoanAmount)).toFixed(2), 2): ''}</span>
              </div>
              <div className="Calculator__output_single">
                <span className="CO__label">Final lump sum</span>
                <span className="CO__value">
                  {lumpsum === "yes"
                    ? "£" + formatNumber((parseFloat(purchasePriceVAT) / 2).toFixed(2), 2)
                    : "N/A"}
                </span>
              </div>
              <div className="Calculator__output_single">
                <span className="CO__label">Total borrowing</span>
                <span className="CO__value">{
                  (purchasePriceVAT - displayLoanAmount) 
                    ? loanFor === 'car' 
                      ? '£'+formatNumber((purchasePriceVAT - displayLoanAmount).toFixed(2), 2) 
                      : '£'+formatNumber(((purchasePriceVAT || 0) * 1.2 - displayLoanAmount || 0).toFixed(2), 2) 
                    : ''}
                </span>
              </div>
              <div className="Calculator__output_single">
                <span className="CO__label">Total amount payable</span>
                <span className="CO__value">                  
                  {totalAmountPayable ? '£'+formatNumber(
                    (parseFloat(totalAmountPayable)).toFixed(2), 2) : ''
                  }
                </span>
              </div>
            </div>
            <div className={cx(!eligibleForLoan ? "" : "hidden")}>
              <p className="Calculator__output_info">
                Unfortunately we're unable to quote online for business with
                turnover over £25m, or a purchase price over £250k.
              </p>
              <p className="Calculator__output_info">
                Please contact us to discuss your requirements and we'll be
                happy to help.
              </p>
            </div>
            
            {initialDataExist 
              ? <div>
                  {eligibleForLoan ? <>
                    <p className="Calculator__output_info">
                      Monthly price is purely indicative and subject to change, based
                      on other criteria such as age of asset, mileage, lump sum, model
                      and your financial income. This is not a confirmed quote and
                      does not constitute a formal offer on behalf of Lombard North
                      Central PLC or on behalf of any other member of NatWest Group
                      PLC group of companies.
                    </p>
                    <br />
                  </> : <>
                  </>}
                  
                  <div className="Calculator__btn-group">
                    <button
                      onClick={(e) => {
                        window.location.href = eligibleForLoan
                          ? "https://quote.lombard.co.uk/landing/cars-and-vans?extcam=L_QQ_LINK_Getapersonalisedquote"
                          : "https://quote.lombard.co.uk/contact-us";
                      }}
                      className="Calculator__btn"
                    >{eligibleForLoan ? "Get a personalised Fixed Rate quote" : "Contact us"}</button>
                    <br />
                    <br />
                    <button
                      onClick={(e) => {
                        window.location.href = '/eligibility-check/about-assets';
                      }}
                      className="Calculator__btn"
                    >Check your eligibility</button>
                  </div>
                </div>
              : <div>
                  <p className="Calculator__output_info">
                    Sorry, our loan calculator isn’t working at the moment. You can still get a personalised quote with us online or alternatively contact us using the link below.
                  </p>
                  <br />
                  <div className="Calculator__btn-group">
                    <button
                      className="Calculator__btn"
                      onClick={(e) => {
                        window.location.href = eligibleForLoan
                          ? "https://quote.lombard.co.uk/landing/cars-and-vans?extcam=L_QQ_LINK_Getapersonalisedquote"
                          : "https://quote.lombard.co.uk/contact-us";
                      }}
                    >Get a personalised Fixed Rate quote</button>
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    {/* <p className="Calculator__output_info">
                      Monthly price is purely indicative and subject to change, based
                      on other criteria such as age of asset, mileage, lump sum, model
                      and your financial income. This is not a confirmed quote and
                      does not constitute a formal offer on behalf of Lombard North
                      Central PLC or on behalf of any other member of NatWest Group
                      PLC group of companies.
                    </p> */}
                    <br />
                    <div className="Calculator__btn-group">
                      <button
                        className="Calculator__btn--alt"
                        onClick={(e) => { window.location.href = "https://quote.lombard.co.uk/contact-us"; }}
                      >Contact us</button>
                      <br />
                      <br />
                      <button
                        onClick={(e) => {
                          window.location.href = '/eligibility-check/about-assets';
                        }}
                        className="Calculator__btn--alt"
                      >Check your eligibility</button>
                    </div>
                  </div>
                </div>
            }

          </div>
        </div>
      </div>
    </div>
  );
};

export default Calculator;
