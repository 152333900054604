import React, { useEffect, useState } from 'react';
import ErrorIcon from "../../assets/images-new/error.svg";

import './field-error.scss';

const FieldError = ({ name, defaultErrorMessages: parentDefaultErrorMessages, formRegister }) => {
  const {
    errors,
  } = formRegister;

  const defaultMessages = parentDefaultErrorMessages || {
    required: 'This field is required'
  };

  const [errMsg, setErrMsg] = useState('');

  useEffect(() => {
    if (!errors[name]) {
      return;
    }
    const errObj = { ...errors[name] };

    if (errObj.ref) {
      if (errObj.message) {
        setErrMsg(errObj.message);
        return;
      }
      if (errObj.type) {
        setErrMsg(defaultMessages[errObj.type] || '');
        return;
      }
    }

    for (const key in errObj) {
      if (errObj[key]?.message) {
        setErrMsg(errObj[key].message);
        return;
      }
      if (errObj[key]?.type) {
        setErrMsg(defaultMessages[errObj[key].type] || '');
        return;
      }
    }
  }, [errors, name, defaultMessages]);

  return (
    <>
      {errors[name] &&
        <>
          {errMsg.current}

          <span className='errorMsg'>
            <img src={ErrorIcon} className='errorIcon' alt='Error Icon'></img>
            <span dangerouslySetInnerHTML={{ __html: errMsg || 'Invalid input' }}></span>
          </span>
        </>
      }
    </>
  );
};

export default FieldError;