import React, {useState} from 'react';
import { useForm } from "react-hook-form";
// import {Context} from '../../stores/store'
import 'react-rangeslider/lib/index.css'
import './Preflow.scss';
// import { ReactComponent as Tick } from '../../assets/images/tick.svg';
import { ReactComponent as WhiteTick } from '../../assets/images/tick_white.svg';



const Preflow = () => {
  const { register } = useForm();
  // const [state, dispatch] = useContext(Context);
  const [activeIndex, setActiveIndex] = useState(0);
  const [relationship, setRelationship] = useState('');
  const [goToLevel4, setGoToLevel4] = useState(false);
  const [affectedByCovid, setAffectedByCovid] = useState("");
  const [onlineBanking, setOnlineBanking] = useState("");
  const [goToLevel5, setGoToLevel5] = useState(false);
  const [borrowAmount, setBorrowingAmount] = useState("");
  const [onlineBankingBorrowing, setOnlineBankingBorrowing] = useState("");
  const [goToLevel6, setGoToLevel6] = useState("");

  const validateAndNext = (variableToValidate) => {
    if (variableToValidate) {
      setActiveIndex(activeIndex + 1);
    }
  }

  const useLoad = fn => {
    const cb = React.useRef(fn);

    React.useEffect(() => {
      const onLoad = cb.current;
      window.addEventListener('load', onLoad);
      return () => {
        window.removeEventListener('load', onLoad);
      };
    }, [cb]);
  };

  useLoad(e => {
    e.preventDefault();
    setActiveIndex(0);
  });

  return (
    <div
        id="apply"
        className="carousel">

        <div className="inner" style={{ transform: `translateX(-${activeIndex * 100}%)` }}>
            {/* {React.Children.map(children, (child, index) => {
            return React.cloneElement(child, { width: "100%" });
            })} */}
            <div className="carousel-item carousel-item__purple-bg" style={{ width: "100%" }}>
              <div className="carousel__container">
                <div className="carousel__wrapper">
                  <h2 className="carousel__container__white-h2">Ready to apply?</h2>
                  <ul className="carousel__checklist">
                    <li><WhiteTick/>You must be authorised to apply for a loan on behalf of your business</li>
                    <li><WhiteTick/>We will complete a full credit check on all key principals of the business using a credit reference agency</li>
                    <li className="carousel__checklist__btn-list" onClick={(e) => {setActiveIndex(activeIndex + 1)}}>Let’s get started</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="carousel-item carousel-item__white-bg" style={{ width: "100%" }}> 
              <div className="carousel__container">
                <div className="carousel__wrapper">
                  <h2 className="carousel__container__purple-h2">Has your business been affected by coronavirus (COVID-19)?</h2>
                  <div className="carousel__radio">
                    <label className={affectedByCovid === "Yes" ? 'active' : ''}>
                      <input name="affectedByCovid" type="radio" value="Yes" ref={register} checked={affectedByCovid === "Yes"} onChange={(e) => {setAffectedByCovid(e.currentTarget.value)}}/>
                      <span className="check"></span>
                      <span className="label">
                      Yes
                      </span>
                    </label>
                  </div>
                  <div className="carousel__radio">
                    <label className={affectedByCovid === "No" ? 'active' : ''}>
                      <input name="affectedByCovid" type="radio" value="No" ref={register} checked={affectedByCovid === "No"} onChange={(e) => {setAffectedByCovid(e.currentTarget.value)}}/>
                      <span className="check"></span>
                      <span className="label">
                      No
                      </span>
                    </label>
                  </div>
                  <div className="carousel__btn__container">
                    <button className="carousel__btn carousel__btn__white" onClick={(e) => {setAffectedByCovid(''); setActiveIndex(activeIndex - 1)}}>Previous</button>
                    <button className="carousel__btn carousel__btn__blue" disabled={affectedByCovid === ""} onClick={(e) => {validateAndNext(affectedByCovid)}}>Next</button>
                  </div>
                </div>
              </div>
            </div>
            <div className="carousel-item carousel-item__white-bg" style={{ width: "100%" }}> 
              <div className="carousel__container">
                <div className="carousel__wrapper">
                  <h2 className="carousel__container__purple-h2">Tell us about your relationship with NatWest...</h2>
                  <div className="carousel__radio">
                    <label className={relationship === "businessAccount" ? 'active' : ''}>
                      <input name="relationship" type="radio" value="businessAccount" checked={relationship === "businessAccount"} ref={register} onChange={(e) => {setRelationship(e.currentTarget.value)}}/>
                      <span className="check"></span>
                      <span className="label">
                      I have a NatWest business account
                      </span>
                    </label>
                  </div>
                  <div className="carousel__radio">
                    <label className={relationship === "personalAccount" ? 'active' : ''}>
                      <input name="relationship" type="radio" value="personalAccount" checked={relationship === "personalAccount"} ref={register} onChange={(e) => {setRelationship(e.currentTarget.value)}}/>
                      <span className="check"></span>
                      <span className="label">
                      I have a NatWest personal account
                      </span>
                    </label>
                  </div>
                  <div className="carousel__radio">
                    <label className={relationship === "noAccount" ? 'active' : ''}>
                      <input name="relationship" type="radio" value="noAccount" checked={relationship === "noAccount"} ref={register} onChange={(e) => {setRelationship(e.currentTarget.value)}}/>
                      <span className="check"></span>
                      <span className="label">
                      I don't currently bank with NatWest
                      </span>
                    </label>
                  </div>
                  <div className="carousel__btn__container">
                    <button className="carousel__btn carousel__btn__white" onClick={(e) => {setRelationship(''); setGoToLevel4(false); setActiveIndex(activeIndex - 1)}}>Previous</button>
                    <button className="carousel__btn carousel__btn__blue" disabled={relationship === ""} onClick={(e) => {setGoToLevel4(true); validateAndNext(relationship)}}>Next</button>
                  </div>
                </div>
              </div>
            </div>
            {
              (goToLevel4 && (relationship === "noAccount" || relationship === "personalAccount")) ? (
                <div className="carousel-item carousel-item__purple-end-bg" style={{ width: "100%" }}>
                  <div className="carousel__container">
                    <div className="carousel__wrapper">
                      <h2 className="carousel__container__white-h2">Apply online for a Small Business Loan</h2>
                      <p className="carousel__white-single-line ">Apply now for a Small Business Loan using our simple online application form.</p>
                      <div className="carousel__btn__container">
                        <button className="carousel__btn carousel__btn__white" onClick={(e) => {setGoToLevel4(false); setActiveIndex(activeIndex - 1)}}>Previous</button>
                        <a href="https://quote.lombard.co.uk/landing/cars-and-vans?extcam=L_QQ_LINK_ApplyOnline"><button className="carousel__btn carousel__btn__blue" >Apply online</button></a>
                      </div>
                    </div>
                  </div>
                </div>
                ) : null
            }
            {
              (goToLevel4 && relationship === "businessAccount" && affectedByCovid === "No") ? (
                <div className="carousel-item carousel-item__white-bg" style={{ width: "100%" }}> 
                  <div className="carousel__container">
                    <div className="carousel__wrapper">
                      <h2 className="carousel__container__purple-h2">Do you have Online Banking ?</h2>
                      <div className="carousel__radio">
                        <label className={onlineBanking === "Yes" ? 'active' : ''}>
                          <input name="onlineBanking" type="radio" value="Yes" checked={onlineBanking === "Yes"} ref={register} onChange={(e) => {setOnlineBanking(e.currentTarget.value)}}/>
                          <span className="check"></span>
                          <span className="label">
                          Yes
                          </span>
                        </label>
                      </div>
                      <div className="carousel__radio">
                        <label className={onlineBanking === "No" ? 'active' : ''}>
                          <input name="onlineBanking" type="radio" value="No" checked={onlineBanking === "No"} ref={register} onChange={(e) => {setOnlineBanking(e.currentTarget.value)}}/>
                          <span className="check"></span>
                          <span className="label">
                          No
                          </span>
                        </label>
                      </div>
                      <div className="carousel__btn__container">
                        <button className="carousel__btn carousel__btn__white" onClick={ (e) => {setOnlineBanking(''); setGoToLevel5(false); setActiveIndex(activeIndex - 1)}}>Previous</button>
                        <button className="carousel__btn carousel__btn__blue" disabled={onlineBanking === ""} onClick={(e) => {setGoToLevel5(true); validateAndNext(onlineBanking)}}>Next</button>
                      </div>
                    </div>
                  </div>
                </div>
                ) : null
            }
            {
              (goToLevel4 && relationship === "businessAccount" && affectedByCovid === "Yes") ? (
                <div className="carousel-item carousel-item__white-bg" style={{ width: "100%" }}> 
                  <div className="carousel__container">
                    <div className="carousel__wrapper">
                      <h2 className="carousel__container__purple-h2">How much do you want to borrow ?</h2>
                      <div className="carousel__radio">
                        <label className={borrowAmount === "moreThan25" ? 'active' : ''}>
                          <input name="borrowAmount" type="radio" value="moreThan25" checked={borrowAmount === "moreThan25"} ref={register} onChange={(e) => {setBorrowingAmount(e.currentTarget.value)}}/>
                          <span className="check"></span>
                          <span className="label">
                          I want to apply for more than £25,050
                          </span>
                        </label>
                      </div>
                      <div className="carousel__radio">
                        <label className={borrowAmount === "lessThan25" ? 'active' : ''}>
                          <input name="borrowAmount" type="radio" value="lessThan25" checked={borrowAmount === "lessThan25"} ref={register} onChange={(e) => {setBorrowingAmount(e.currentTarget.value)}}/>
                          <span className="check"></span>
                          <span className="label">
                          I want to apply for less than £25,050
                          </span>
                        </label>
                      </div>
                      <div className="carousel__btn__container">
                        <button className="carousel__btn carousel__btn__white" onClick={(e) => {setBorrowingAmount(''); setGoToLevel5(false); setActiveIndex(activeIndex - 1)}}>Previous</button>
                        <button className="carousel__btn carousel__btn__blue" disabled={borrowAmount === ""} onClick={(e) => {setGoToLevel5(true); validateAndNext(borrowAmount)}}>Next</button>
                      </div>
                    </div>
                  </div>
                </div>
                ) : null
            }
            {
              (goToLevel5 && affectedByCovid === "No" && relationship === "businessAccount" && onlineBanking === "No") ? (
                <div className="carousel-item carousel-item__purple-end-bg" style={{ width: "100%" }}>
                  <div className="carousel__container">
                    <div className="carousel__wrapper">
                      <h2 className="carousel__container__white-h2">Apply online if you don't have Online Banking</h2>
                      <p className="carousel__white-single-line ">If you're an existing customer but don't have Online Banking, you can apply for a Small Business Loan using our simple online application form.</p>
                      <div className="carousel__btn__container">
                        <button className="carousel__btn carousel__btn__white" onClick={(e) => {setGoToLevel5(false); setActiveIndex(activeIndex - 1)}}>Previous</button>
                        <a href="https://quote.lombard.co.uk/landing/cars-and-vans?extcam=L_QQ_LINK_ApplyOnline"><button className="carousel__btn carousel__btn__blue" >Apply online</button></a>
                      </div>
                    </div>
                  </div>
                </div>
                ) : null
            }
            {
              (goToLevel5 && affectedByCovid === "No" && relationship === "businessAccount" && onlineBanking === "Yes") ? (
                <div className="carousel-item carousel-item__purple-end-bg" style={{ width: "100%" }}>
                  <div className="carousel__container">
                    <div className="carousel__wrapper">
                      <h2 className="carousel__container__white-h2">Log in to apply online</h2>
                      <p className="carousel__white-single-line ">Log in to Online Banking to apply for a Small Business Loan and receive personalised rates and faster application processing.</p>
                      <b className="carousel__white-single-line">Note: We may ask you to provide additional evidence to support your application.</b>
                      <div className="carousel__btn__container">
                        <button className="carousel__btn carousel__btn__white" onClick={(e) => {setGoToLevel5(false); setActiveIndex(activeIndex - 1)}}>Previous</button>
                        <a href="https://secure2.natwest.com/as/authorization.oauth2?client_id=bbconnect_spa_client&response_type=token&redirect_uri=https%3A%2F%2Fbbconnect.apps.natwest.com%2Fcontent%2Ffinished&state=%7B%22client_id%22%3A%22bbconnect_spa_client%22%2C%22network%22%3A%22pingIdentityModule%22%2C%22display%22%3A%22page%22%2C%22callback%22%3A%22_hellojs_6g7cknms%22%2C%22state%22%3A%22NWB%22%2C%22redirect_uri%22%3A%22https%3A%2F%2Fbbconnect.apps.natwest.com%2Fcontent%2Ffinished%22%2C%22scope%22%3A%22basic%22%2C%22page_uri%22%3A%22https%3A%2F%2Fbbconnect.apps.natwest.com%2Fcontent%2F%3F%26devicemode%3Dnull%26subbrand%3Dresponsive_natwest_business%26CCF%3DY%22%7D&scope="><button className="carousel__btn carousel__btn__blue" >Log in to apply</button></a>
                      </div>
                    </div>
                  </div>
                </div>
                ) : null
            }
            {
              (goToLevel5 && affectedByCovid === "Yes" && relationship === "businessAccount" && borrowAmount === "moreThan25") ? (
                <div className="carousel-item carousel-item__purple-end-bg" style={{ width: "100%" }}>
                  <div className="carousel__container">
                    <div className="carousel__wrapper">
                      <h2 className="carousel__container__white-h2">Apply for the Recovery Loan Scheme</h2>
                      <p className="carousel__white-single-line ">If your business has been affected by Covid-19, you may be eligible to apply for the Recovery Loan Scheme (RLS). The new Scheme aims to support businesses affected by Covid-19 as they recover and grow following the coronavirus pandemic.</p>
                      <div className="carousel__btn__container">
                        <button className="carousel__btn carousel__btn__white" onClick={(e) => {setGoToLevel5(false); setActiveIndex(activeIndex - 1)}}>Previous</button>
                        <a href="https://www.natwest.com/business/support-centre/service-status/coronavirus/recovery-loan-scheme.html"><button className="carousel__btn carousel__btn__blue" >Find out more</button></a>
                      </div>
                    </div>
                  </div>
                </div>
                ) : null
            }
            {
              (goToLevel5 && affectedByCovid === "Yes" && relationship === "businessAccount" && borrowAmount === "lessThan25") ? (
                <div className="carousel-item carousel-item__white-bg" style={{ width: "100%" }}> 
                  <div className="carousel__container">
                    <div className="carousel__wrapper">
                      <h2 className="carousel__container__purple-h2">Do you have Online Banking ?</h2>
                      <div className="carousel__radio">
                        <label className={onlineBankingBorrowing === "Yes" ? 'active' : ''}>
                          <input name="onlineBankingBorrowing" type="radio" value="Yes" checked={onlineBankingBorrowing === "Yes"} ref={register} onChange={(e) => {setOnlineBankingBorrowing(e.currentTarget.value)}}/>
                          <span className="check"></span>
                          <span className="label">
                          Yes
                          </span>
                        </label>
                      </div>
                      <div className="carousel__radio">
                        <label className={onlineBankingBorrowing === "No" ? 'active' : ''}>
                          <input name="onlineBankingBorrowing" type="radio" value="No" checked={onlineBankingBorrowing === "No"} ref={register} onChange={(e) => {setOnlineBankingBorrowing(e.currentTarget.value)}}/>
                          <span className="check"></span>
                          <span className="label">
                          No
                          </span>
                        </label>
                      </div>
                      <div className="carousel__btn__container">
                        <button className="carousel__btn carousel__btn__white" onClick={(e) => {setOnlineBankingBorrowing(''); setGoToLevel6(false); setActiveIndex(activeIndex - 1)}}>Previous</button>
                        <button className="carousel__btn carousel__btn__blue" disabled={onlineBankingBorrowing === ""} onClick={(e) => {setGoToLevel6(true); validateAndNext(onlineBankingBorrowing)}}>Next</button>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null
            }
            {
                (goToLevel6 && affectedByCovid === "Yes" && relationship === "businessAccount" && onlineBankingBorrowing === "No") ? (
                  <div className="carousel-item carousel-item__purple-end-bg" style={{ width: "100%" }}>
                    <div className="carousel__container">
                      <div className="carousel__wrapper">
                        <h2 className="carousel__container__white-h2">Apply online if you don't have Online Banking</h2>
                        <p className="carousel__white-single-line ">If you're an existing customer but don't have Online Banking, you can apply for a Small Business Loan using our simple online application form.</p>
                        <div className="carousel__btn__container">
                          <button className="carousel__btn carousel__btn__white" onClick={(e) => {setGoToLevel6(false); setActiveIndex(activeIndex - 1)}}>Previous</button>
                          <a href="https://quote.lombard.co.uk/landing/cars-and-vans?extcam=L_QQ_LINK_ApplyOnline"><button className="carousel__btn carousel__btn__blue" >Apply online</button></a>
                        </div>
                      </div>
                    </div>
                  </div>
                  ) : null
              }
              {
                (goToLevel6 && affectedByCovid === "Yes" && relationship === "businessAccount" && onlineBankingBorrowing === "Yes") ? (
                  <div className="carousel-item carousel-item__purple-end-bg" style={{ width: "100%" }}>
                    <div className="carousel__container">
                      <div className="carousel__wrapper">
                        <h2 className="carousel__container__white-h2">Log in to apply online</h2>
                        <p className="carousel__white-single-line">Log in to Online Banking to apply for a Small Business Loan and receive personalised rates and faster application processing.</p>
                        <b className="carousel__white-single-line">Note: We may ask you to provide additional evidence to support your application.</b>
                        <div className="carousel__btn__container">
                          <button className="carousel__btn carousel__btn__white" onClick={(e) => {setGoToLevel6(false); setActiveIndex(activeIndex - 1)}}>Previous</button>
                          <a href="https://secure2.natwest.com/as/authorization.oauth2?client_id=bbconnect_spa_client&response_type=token&redirect_uri=https%3A%2F%2Fbbconnect.apps.natwest.com%2Fcontent%2Ffinished&state=%7B%22client_id%22%3A%22bbconnect_spa_client%22%2C%22network%22%3A%22pingIdentityModule%22%2C%22display%22%3A%22page%22%2C%22callback%22%3A%22_hellojs_6g7cknms%22%2C%22state%22%3A%22NWB%22%2C%22redirect_uri%22%3A%22https%3A%2F%2Fbbconnect.apps.natwest.com%2Fcontent%2Ffinished%22%2C%22scope%22%3A%22basic%22%2C%22page_uri%22%3A%22https%3A%2F%2Fbbconnect.apps.natwest.com%2Fcontent%2F%3F%26devicemode%3Dnull%26subbrand%3Dresponsive_natwest_business%26CCF%3DY%22%7D&scope="><button className="carousel__btn carousel__btn__blue" >Log in to apply</button></a>
                        </div>
                      </div>
                    </div>
                  </div>
                  ) : null
              }
        </div>
    </div>
  );
}


export default Preflow;
