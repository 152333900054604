import React, {useContext, useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
// import cx from 'classnames';
import { withRouter } from "react-router-dom";

import { Context } from '../../../stores/store'
import { trackApplicationStep } from '../../../common/tagging';

const DecisionNone = ({ history }) => {
  const [ state ] = useContext(Context);
  const {
    setValue, 
  } = useForm({
    mode: 'onChange',
  });
  const [loaded, isLoaded] = useState(false);
  
  /**
   * init
   */
  useEffect(() => {
    
    if (loaded) {
      return;
    }

    /****** first load ******/
    // 1, tagging
    trackApplicationStep('no-decision');

    // 2, load existing data to form
    
    /****** first load completed ******/
    isLoaded(true);
  }, [state.formData.data, loaded, setValue]);

  return (
    <>
    <div className='ec__banner row'>
      <div className='ec__banner-inner col-lg-8 col-lg-offset-2'>
        <div className="ec__banner-title">We’re sorry our eligibility checker is currently unavailable</div>
        <div className='ec__banner-subtitle'>Our eligibility checker is currently down for routine maintenance so unfortunately we have been unable to make a decision at this time.</div>
      </div>
    </div>

    <div className='ec__main row'>
      <div className='ec__main-inner col-lg-8 col-lg-offset-2'>
        <p>
          Please try again later. This will not affect your credit rating.
          <br/><br/>
          Or alternately please <a href="https://www.lombard.co.uk/support/contact-us.html?intcam=HP-TB-DEF-Default" rel="noopener noreferrer" target="_blank">contact us</a> to discuss you application.
        </p>
      </div>
    </div>
    </>
  );
}

export default withRouter(DecisionNone);