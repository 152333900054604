const Navigation = {
    'Vehicle finance': {
        'Car finance': [
            {
                "title": 'Business car finance',
                "links": [
                    {
                        "title": 'Hire Purchase',
                        "link": 'https://lombard.co.uk/assets/business-car-finance/hire-purchase.html'
                    },
                    {
                        "title": "Contract Hire",
                        "link": "https://lombard.co.uk/assets/business-car-finance/contract-hire.html"
                    }
                ]
            }
            , {
                "title": '',
                "links": [
                    
                ]
            }
        ],
        'Van finance': [
            {
                "title": 'Business van finance',
                "links": [
                    {
                        "title": 'Hire Purchase',
                        "link": 'https://lombard.co.uk/assets/business-van-finance/hire-purchase.html'
                    },
                    {
                        "title": "Contract Hire",
                        "link": "https://lombard.co.uk/assets/business-van-finance/contract-hire.html"
                    }
                ]
            }
            , {
                "title": '',
                "links": [
                    
                ]
            }
        ],
        'Types of finance': [
            {
                "title": 'Types of finance',
                "links": [
                    {
                        "title": 'Hire Purchase',
                        "link": 'https://lombard.co.uk/finance-options/hire-purchase.html'
                    },
                    {
                        "title": "Contract Hire",
                        "link": "https://lombard.co.uk/finance-options/contract-hire.html"
                    },
                    {
                        "title": 'Finance Lease',
                        "link": 'https://lombard.co.uk/finance-options/finance-lease.html'
                    },
                    {
                        "title": "Residual Value Lease",
                        "link": "https://lombard.co.uk/finance-options/residual-value-based-leases.html"
                    }
                ]
            }
            , {
                "title": '',
                "links": [
                    
                ]
            }
        ],
        'Manage your existing account': [
            {
                "title": 'Manage your existing account',
                "links": [
                    {
                        "title": 'Request a settlement figure',
                        "link": 'https://lombard.co.uk/lombard/support/manage-existing-account.html#settlement'
                    },
                    {
                        "title": "Request a clearance confirmation",
                        "link": "https://lombard.co.uk/lombard/support/manage-existing-account.html#settlement"
                    },
                    {
                        "title": 'Request a copy of documents',
                        "link": 'https://lombard.co.uk/lombard/support/manage-existing-account.html#copies'
                    },
                    {
                        "title": "Make a payment or clear your balance",
                        "link": "https://lombard.co.uk/lombard/support/manage-existing-account.html#payment"
                    },
                    {
                        "title": "Request your balance",
                        "link": "https://lombard.co.uk/lombard/support/manage-existing-account.html#balance"
                    },
                    {
                        "title": "Change your contact details",
                        "link": "https://lombard.co.uk/lombard/support/manage-existing-account.html#balance"
                    }
                ]
            }
            , {
                "title": '',
                "links": [
                    
                ]
            }
        ],
        'Insights': [
            {
                "title": "Insights",
                "link": "https://lombard.co.uk/insights.html"
            }
        ]
    },
    'Specialist asset finance': {
        'Vehicles' : [
            {
                "title": 'Business car finance',
                "links": [
                    {
                        "title": 'See all our leading options',
                        "link": 'https://lombard.co.uk/assets/business-car-finance.html'
                    },
                    {
                        "title": 'Business hire purchase',
                        "link": 'https://lombard.co.uk/assets/business-car-finance/hire-purchase.html'
                    },
                    {
                        "title": 'Business contract hire',
                        "link": 'https://lombard.co.uk/finance-options/contract-hire.html'
                    }

                ]   
            },
            {
                "title": 'Business van finance',
                "links": [
                    {
                        "title": 'See all our lending options',
                        "link": 'https://lombard.co.uk/assets/business-van-finance.html'
                    },
                    {
                        "title": 'Hire purchase',
                        "link": 'https://lombard.co.uk/assets/business-van-finance/hire-purchase.html'
                    },
                    {
                        "title": 'Contract hire',
                        "link": 'https://lombard.co.uk/finance-options/contract-hire.html'
                    }
                ]   
            },
            {
                "title": 'Commercial vehicles',
                "links": [
                    {
                        "title": 'See all our lending options',
                        "link": 'https://lombard.co.uk/assets/commercial-vehicles.html'
                    },
                    {
                        "title": 'Hire purchase',
                        "link": 'https://lombard.co.uk/assets/commercial-vehicles/hire-purchase.html'
                    },
                    {
                        "title": 'Finance lease',
                        "link": 'https://lombard.co.uk/assets/commercial-vehicles/finance-lease.html'
                    },
                    {
                        "title": 'Residual Value Lease',
                        "link": 'https://lombard.co.uk/assets/commercial-vehicles/residual-value-lease.html'  
                    }
                ]   
            }
        ],
        'Agricultural': [
            {
                "title": "Agricultural",
                "links": [
                    {
                        "title": "See all our lending options",
                        "link": "https://lombard.co.uk/assets/agricultural-equipment.html"
                    },
                    {
                        "title": "Hire purchase",
                        "link": "https://lombard.co.uk/assets/agricultural-equipment/hire-purchase.html"
                    },
                    {
                        "title": "Finance lease",
                        "link": "https://lombard.co.uk/assets/agricultural-equipment/finance-lease.html"
                    },
                    {
                        "title": "Residual Value Lease",
                        "link": "https://lombard.co.uk/assets/agricultural-equipment/residual-value-lease.html"
                    },
                    {
                        "title": "Sales and leaseback",
                        "link": "https://lombard.co.uk/finance-options/sale-and-leaseback.html"
                    },
                ]
            },
            {
                "title": '',
                "links": [
                    
                ]
            }
        ],
        'Marine': [
            {
                "title": "Marine",
                "links": [
                    {
                        "title": "See all our marine finance options",
                        "link": "https://lombard.co.uk/assets/marine-finance.html"
                    },
                    {
                        "title": "Leisure marine",
                        "link": "https://lombard.co.uk/assets/marine-finance/leisure-marine-finance.html"
                    },
                    {
                        "title": "Commercial marine",
                        "link": "https://lombard.co.uk/assets/marine-finance/commercial-marine-finance.html"
                    }
                ]
            },
            {
                "title": "",
                "links": [
                  
                ]
            }
        ],
        'Technology': [
            {
                "title": "Technology",
                "links": [
                    {
                        "title": "See all our lending options",
                        "link": "https://lombard.co.uk/assets/technology-finance.html"
                    },
                    {
                        "title": "Hire purchase",
                        "link": "https://lombard.co.uk/assets/technology-finance/hire-purchase.html"
                    },
                    {
                        "title": "Finance lease",
                        "link": "https://lombard.co.uk/assets/technology-finance/finance-lease.html"
                    },
                    {
                        "title": "Residual Value Lease",
                        "link": "https://lombard.co.uk/assets/technology-finance/residual-value-lease.html"
                    },
                    {
                        "title": "Intellectual property funding",
                        "link": "https://lombard.co.uk/assets/technology-finance/intellectual-property-funding.html"
                    }
                ]
            },
            {
                "title": "",
                "links": [
                  
                ]
            }
        ],
        'Renewable energy': [
            {
                "title": "Renewable energy",
                "links": [
                    {
                        "title": "See all our lending options",
                        "link": "https://lombard.co.uk/assets/sustainable-finance/other-green-assets.html"
                    },
                    {
                        "title": "Hire purchase",
                        "link": "https://lombard.co.uk/assets/sustainable-finance/other-green-assets.html"
                    },
                    {
                        "title": "Finance lease",
                        "link": "https://lombard.co.uk/assets/sustainable-finance/other-green-assets.html"
                    },
                    {
                        "title": "Residual Value Lease",
                        "link": "https://lombard.co.uk/assets/sustainable-finance/other-green-assets.html"
                    }
                ]
            },
            {
                "title": "",
                "links": [
                  
                ]
            }
        ],
        'Aviation': [
            {
                "title": "Aviation",
                "links": [
                    {
                        "title": "See all our lending options",
                        "link": "https://lombard.co.uk/assets/aviation-finance.html"
                    },
                    {
                        "title": "Chattel loan with mortgage",
                        "link": "https://lombard.co.uk/finance-options/chattel-mortgage.html"
                    }
                ]
            },
            {
                "title": "",
                "links": [
                  
                ]
            }
        ],
        'Machinery': [
            {
                "title": "Renewable energy",
                "links": [
                    {
                        "title": "See all our lending options",
                        "link": "https://lombard.co.uk/assets/manufacturing-machinery.html"
                    },
                    {
                        "title": "Hire purchase",
                        "link": "https://lombard.co.uk/assets/manufacturing-machinery/hire-purchase.html"
                    },
                    {
                        "title": "Finance lease",
                        "link": "https://lombard.co.uk/assets/manufacturing-machinery/finance-lease.html"
                    },
                    {
                        "title": "Residual Value Lease",
                        "link": "https://lombard.co.uk/assets/manufacturing-machinery/residual-value-lease.html"
                    }
                ]
            },
            {
                "title": "",
                "links": [
                  
                ]
            }
        ],
        'Healthcare equipment': [
            {
                "title": "Healthcare equipment",
                "links": [
                    {
                        "title": "See all our lending options",
                        "link": "https://lombard.co.uk/assets/healthcare-equipment.html"
                    },
                    {
                        "title": "Hire purchase",
                        "link": "https://lombard.co.uk/assets/healthcare-equipment/hire-purchase.html"
                    },
                    {
                        "title": "Finance lease",
                        "link": "https://lombard.co.uk/assets/healthcare-equipment/finance-lease.html"
                    },
                    {
                        "title": "Residual Value Lease",
                        "link": "https://lombard.co.uk/assets/healthcare-equipment/residual-value-lease.html"
                    }
                ]
            },
            {
                "title": "",
                "links": [
                  
                ]
            }
        ],
        'Asset finance explained': [
            {
                "title": "Asset finance explained",
                "link": "https://lombard.co.uk/assets.html"
            }
        ],
        'Refinance solutions': [
            {
                "title": "Refinance solutions",
                "link": "https://lombard.co.uk/services/refinance-solutions.html"
            }
        ],
        'Insights': [
            {
                "title": "Insights",
                "link": "https://lombard.co.uk/insights.html"
            }
        ]
    },
    'Green Asset  Finance': {
        'Green Asset Finance': [
            {
                "title": 'Green Asset Finance',
                "link": 'https://lombard.co.uk/assets/sustainable-finance.html'        
            }],
        'Electric vehicles': [
            {
                "title": 'Our products',
                "links": [
                    {
                        "title": 'Hire Purchase',
                        "link": 'https://lombard.co.uk/lombard/assets/sustainable-finance/electric-vehicle.html#hire'
                    },
                    {
                        "title": 'Contract Hire',
                        "link": 'https://lombard.co.uk/lombard/assets/sustainable-finance/electric-vehicle.html#contract'
                    },                    
                ]
            },
            {
                "title": 'Business Supports',
                "links": [
                    {
                        "title": 'Why switch to an electric vehicle?',
                        "link": 'https://lombard.co.uk/assets/sustainable-finance/reasons-to-switch-to-an-electric-vehicle.html'
                    },
                    {
                        "title": 'ULEZ',
                        "link": 'https://lombard.co.uk/industry-insights/ulez.html'
                    },
                    {
                        "title": 'Swoop',
                        "link": 'https://lombard.co.uk/services/swoop.html'
                    },
                    {
                        "title": 'Octopus energy',
                        "link": 'https://lombard.co.uk/lombard/assets/sustainable-finance/charging-solutions.html#offering'
                    },
                    {
                        "title": 'Green business builders',
                        "link": 'https://lombard.co.uk/lombard/assets/sustainable-finance/green-business-builders.html#GreenBusinessBuilders'
                    },
                ]
            }],
            'Other assets': [
                {
                    "title": 'Other assets',
                    "link": "https://lombard.co.uk/assets/sustainable-finance/other-green-assets.html"                 
                }],
            'Support for your business':[
                {                    
                    "title": 'Support for your business',
                    "links": [
                        {
                            "title": 'ULEZ',
                            "link": 'https://lombard.co.uk/industry-insights/ulez.html'
                        },
                        {
                            "title": 'Swoop',
                            "link": 'https://lombard.co.uk/services/swoop.html'
                        },
                        {
                            "title": 'Octopus energy',
                            "link": 'https://lombard.co.uk/lombard/assets/sustainable-finance/charging-solutions.html#offering'
                        },
                        {
                            "title": 'Green business builders',
                            "link": 'https://lombard.co.uk/lombard/assets/sustainable-finance/green-business-builders.html#GreenBusinessBuilders'
                        }
                    ]
                },
                {
                    "title": '',
                    "links": [
                        
                    ]
                }
            ],
            'Insights': [
                {
                    "title": "Insights",
                    "link": "https://lombard.co.uk/insights/sustainability.html"
                }
            ]
        
    },
    'Business support': {

        'Contact Us': [
            {
                "title": 'Get in touch',
                "links": [
                    {
                        "title": 'Contact us',
                        "link": 'https://lombard.co.uk/support/contact-us.html'
                    },
                    {
                        "title": 'How to appeal a lending decision',
                        "link": 'https://lombard.co.uk/support/contact-us/appeal-a-decision.html'
                    },
                    {
                        "title": 'How to make a complaint',
                        "link": 'https://lombard.co.uk/support/contact-us/make-a-complaint.html'
                    },
                    {
                        "title": 'Coronavirus support',
                        "link": 'https://lombard.co.uk/support/contact-us/coronavirus.html'
                    },
                    {
                        "title": 'FAQs',
                        "link": 'https://lombard.co.uk/support/frequently-asked-questions.html'
                    }
            
                ]
            },
            {
                "title": '',
                "links": [
        ]}],
        'Manage your existing account': [
            {
                "title": 'Manage your existing account',
                "links": [
                    {
                        "title": 'Request a settlement figure',
                        "link": 'https://lombard.co.uk/lombard/support/manage-existing-account.html#settlement'
                    },
                    {
                        "title": 'Request a clearance confirmation',
                        "link": 'https://lombard.co.uk/lombard/support/manage-existing-account.html#settlement'
                    },
                    {
                        "title": 'Request a copy of documents',
                        "link": 'https://lombard.co.uk/lombard/support/manage-existing-account.html#copies'
                    },
                    {
                        "title": 'Make a payment or clear your balance',
                        "link": 'https://lombard.co.uk/lombard/support/manage-existing-account.html#payment'
                    },
                    {
                        "title": 'Request your balance',
                        "link": 'https://lombard.co.uk/lombard/support/manage-existing-account.html#balance'
                    },
                    {
                        "title": 'Change your contact details',
                        "link": 'https://lombard.co.uk/lombard/support/manage-existing-account.html#balance'
                    }
            
                ]
            },
            {
                "title": '',
                "links": [
        ]}],
        'Government support': [
            {
                "title": 'Business support tools',
                "links": [
                    {
                        "title": 'Swoop',
                        "link": 'https://lombard.co.uk/services/swoop.html'
                    },
                    {
                        "title": 'Super-deduction',
                        "link": 'https://lombard.co.uk/services/super-deduction.html'
                    },
                    {
                        "title": 'Annual Investment Allowance',
                        "link": 'https://lombard.co.uk/services/annual-investment-allowance.html'
                    }
            
                ]
            },
            {
                "title": '',
                "links": [                    
                ]}],

                'Insights': [
                    {
                        "title": "Insights",
                        "link": "https://lombard.co.uk/insights.html"
                    }
                ]
    },
};

export default Navigation;

