


/**
 * navigate to next step/page
 */
const navToNext = (currStepIdx, stepsData, history, nextStepId) => {
  if (nextStepId) {
    history.push(`/eligibility-check/${nextStepId}`);
    return;
  }

  const nextStepIdx = currStepIdx + 1;
  history.push(`/eligibility-check/${stepsData[nextStepIdx].id}`);
}

/**
 * navigate to previous step/page
 */
const navToPrev = (currStepIdx, stepsData, history, nextStepId) => {
  // console.log(`[navToPrev] ${nextStepId}`);
  if (nextStepId) {
    history.push(`/eligibility-check/${nextStepId}`);
    return;
  }

  history.push(`/eligibility-check/${stepsData[currStepIdx - 1].id}`);
}

/**
 * render and calculate the new formData, to be dispatched to update the store
 */
const calcNewFormData = (currFormData, currStepIdx, newStepdata) => {
  const { steps, data } = currFormData;

  const currStepId = steps[currStepIdx].id;
  const currStepkey = steps[currStepIdx].key;
  const count = steps.filter((x) => {return x.isComplete}).length + 1;
  const nextStepId = steps.findIndex((element) => (
    element.isComplete === false && element.id !== currStepId
  ));
  const newData = {...data};
  newData[currStepkey] = newStepdata;

  const newFormData = {
    data: newData,
    steps: steps.map(el => el.id === currStepId ? { ...el, isComplete: true }: el),
    sectionsCompelete: 4 - count,
    activeStep: steps[nextStepId]?.id
  }
  // console.log('[calcNewFormData] newFormData', newFormData);

  return newFormData;
};


export { 
  navToNext,
  navToPrev,
  calcNewFormData, 
};