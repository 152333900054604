import React, {useContext, useEffect} from 'react';
import { ReactComponent as Warning } from '../../assets/images/Icon_warning.svg';

import { withRouter } from "react-router-dom";


import {Context} from '../../stores/store'

import './eligibilityDecision.scss';

const NotApproved = ({history}) => {
    const [state] = useContext(Context);

    useEffect(() => {
        const {formData} = state;
        if (formData.status !== "Not Eligible" && formData.status !== "Not Likely" && formData.status !== "Unlikely") {
            history.push('/business/loans-and-finance/small-business-loan')
        }
    }, [history, state]);

    return (
       <div className="ED">
  <div className="ED__heading">
    <div className="ED__wrapper">
      <div className="ED__flex">
        <div className="ED__heading__title">
          <h1>Unfortunately, it does not look like you will be approved.</h1>
        </div>
      </div>
    </div>
  </div>
  <div className="ED__content">
    <div className="ED__wrapper">
      <div className="ED__flex">
        <div className="ED__content__checklist__wrapper checklist-warning">
          <div className="ED__result-warning">
            <Warning />
            <div className="ED__result__content">
              <span>Eligibility decision</span>
              <p>
                We've checked the personal and business details you provided
                against our affordability criteria. Unfortunately it's unlikely
                you would be approved for an application based on your loan
                requirements.
              </p>
            </div>
          </div>
          <h3 className="ED__content__title--large">Speak to the team</h3>
          <p className="ED__content__para">
            You can call us to talk about your lending requirements and
            eligibility on <strong>0345 711 4477</strong>. Our experts are on
            hand to explain our affordability criteria and discuss your options.
          </p>
          <p className="ED__info">
            Mon-Fri 9am-5.30pm (excl. bank holidays). <br />
            Calls may be recorded.
          </p>
          <div className="ED__bottom__wrapper">
            <div className="ED__bottom bottom-warning">
              <h3>Compare alternative lending options</h3>
              <div>
                <p>
                  If you want to explore more options, you can compare our business
                  overdraft, loans and business bank account products against others
                  in the market on the following websites, as suggested by the UK
                  Government's Competition and Markets Authority:
                </p>
                <ul className="ED__bottom__links">
                  <li><a href="https://www.alternativebusinessfunding.co.uk/?utm_source=natwest&utm_medium=referral&utm_campaign=designated_banks">Alternative Business Funding</a></li>
                  <li><a href="https://www.fundingoptions.com/bank/51/?brand=Natwest">Funding Options</a></li>
                  <li><a href="https://www.fundingxchange.co.uk/?cma10">Funding Xchange</a></li>
                  <li><a href="https://swoopfunding.com/">Swoop Funding</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="ED__Calculator__output">
            <h3>You may be able to secure your lending needs using Esme, our digital lending platform</h3>
            <p>Esme provide unsecured business loans ranging from £10,000* to £250,000. The easy and secure paperless application process takes less than 10 minutes.</p>
          <p>Plus there's no set up fees, no early repayment charges, and applying does not effect your credit score.</p>
          <a href="https://www.esmeloans.com/?utm_source=Internal%20Bank%20Automated%20Form%20Referral&utm_medium=Online%20tool&utm_campaign=RBS%20NatWest%20Referral%20Process%20Automated"
             className="ED__Calculator__output_btn" 
             id="CHECK_WITH_ESME">Check your eligibility with Esme</a>
          <span className="ED__Calculator__output_info">*Sole Traders from £25,500</span>
        </div> */}
      </div>
    </div>
  </div>
       <section className="Bottom__section">
        <div className="Bottom__content">
        <div className="Bottom__desktop">
        <p>
        Customers with hearing and speech impairments can contact us by using our Relay UK Service: 18001 0345 711 4477 
        <br/>
        <br/>
        </p>
        <p>
        For more information on accessibility visit:<br/><a id="ACCESSABILITY" href="https://www.natwest.com/business/accessibility.html">https://www.natwest.com/business/accessibility.html</a><br/>
        Calls may be recorded for training and monitoring purposes.<br/>
        To see how much your call might cost, visit the <a id="CALLCHARGE" href="https://supportcentre.natwest.com/913202582">call charge information page.</a>
               </p>
        </div>
        <p>
        National Westminster Bank Plc. Registered in England and Wales (Registered Number 929027), Registered Office: 250 Bishopsgate, London EC2M 4AA.
        </p>
        </div>
      </section>
</div>
    )
  };

export default withRouter(NotApproved);