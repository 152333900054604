import React, {useState, useContext} from 'react';

import Logo  from '../../assets/images/logo-header.png'
import { ReactComponent as LoginPurple } from '../../assets/images/Login-purple.svg'
import { ReactComponent as LoginWhite } from '../../assets/images/Login-white.svg'

import {Context} from '../../stores/store'

import MobileNavigation from '../../components/MobileNav/MobileNav';
import DesktopNavigation from '../../components/DesktopNav/DesktopNav';

import './header.scss';

const Header = () => {

    const [state, dispatch] = useContext(Context);
    const [showNav, toggleNav] = useState(false);
    // const [showLogin, toggleLogin] = useState(false);
    const [showLogin] = useState(false);

    const toggleCallback = value => {
        toggleNav(!value);
    }

    const closeDN = () => {
        let result = [];
        dispatch({type: 'TOGGLE_DN', payload: result});
        document.body.classList.remove('modal-open');
    }
  return (
      <>
    <div className="Header">
        <div className="Header__top">
        <div className="Header__top__links">
                {/* <a href="https://www.natwest.com/personal.html">Personal</a>
                <a href="https://www.natwest.com/premier-banking.html">Premier</a>
                <a className="active" href="https://www.natwest.com/business.html">Business</a> */}
            </div>
        <div className="Header__login">
            <button type="button" onClick={() => 
                window.location.href='https://my.lombard.co.uk/login'
                // toggleLogin(!showLogin)
            }>
                <LoginPurple className="login-desktop" />
                <LoginWhite className="login-mobile"/>
                <span>My Lombard</span>
            </button>
            {showLogin ? (
            <ul className="Header__login--options">
                <li><a href="https://www.bankline.natwest.com/CWSLogon/logon.do?CTAuthMode=RBSG_CORP4P&domain=.bankline.natwest.com&ct-web-server-id=Internet&CT_ORIG_URL=%2Fbankline%2Fnatwest%2Fdefault.jsp&ct_orig_uri=https%3A%2F%2Fwww.bankline.natwest.com%3A443%2Fbankline%2Fnatwest%2Fdefault.jsp">Bankline</a></li>
                <li><a href="https://www.nwolb.com/default.aspx">Online Banking</a></li>
                <li><a href="https://cardsonline-commercial.com/RBSG_Commercial/Login.do?promoCode=NatWest">ClearSpend</a></li>
                <li><a href="https://rbsif.facflow.com/logon/Logon.do?ct_orig_uri=https%3A%2F%2Frbsif.facflow.com%3A443%2Fff%2Fpages%2Fdefault.jsp&domain=.facflow.com">RBSIF FacFlow</a></li>
                <li><a href="https://login.freeagent.com/natwest/login?utm_source=natwest&utm_medium=digital_banking&utm_campaign=bb_login">FreeAgent</a></li>
            </ul>
            ) : null}
        </div>
        </div>
        <div className="Header__content">
            <div className="Header__logo">
                <a href="https://www.lombard.co.uk/">
                    <img src={Logo} alt="logo"></img>
                </a>
            </div>
            <DesktopNavigation />
            <button type="button" onClick={() => toggleNav(true)} className="Header__NavToggle">
                <span>Menu</span>
            </button>
        </div>
    </div>

    {showNav ? (
        <MobileNavigation closeNav={toggleCallback} />
    ) : null}
    {state.navData.activeItem.length > 0 && (
    <button type='button' onClick={() => closeDN()} className='Header__overlay' />
)}
    </>
  );
}

export default Header;
