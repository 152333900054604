import cx from 'classnames';
import React from 'react';
import { Controller } from 'react-hook-form';
import NumberFormat from 'react-number-format';

import { toNum } from '../../../common/util';
import './field-amount.scss';

const FieldAmount = ({ name, formRegister, 
  validate: parentValidateHandler, 
  onChange: parentOnChangeHandler,
}) => {
  const {
    control,
    errors,
  } = formRegister;
  
  return (
    <>
    <Controller
      control={control}
      as={NumberFormat}
      name={name}
      className={cx(
        { error: errors[name] }
      )}
      allowNegative={false}
      thousandSeparator
      placeholder='£'
      prefix='£ '
      decimalScale='2'
      rules={{ validate: val => {
        const valNum = toNum(val);
        if (parentValidateHandler) {
          return parentValidateHandler(valNum);
        }
      }}}
      onChange={([e]) => {
        const valNum = toNum(e?.currentTarget?.value);
        if (parentOnChangeHandler) {
          parentOnChangeHandler(valNum);
        }
        return e?.currentTarget?.value;
      }}
    />
    </>
  );
};

export default FieldAmount;
