import Axios from 'axios';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import AsyncSelect from 'react-select/async';

import envConfig from '../../../common/env.config';
import 'react-select-search/style.css';
import './field-company-search.scss';
import { formatAddressStr } from '../../../common/util';


const FieldCompanySearch = ({title, name, defaultValue, companyType, onChange: onParentChangeHandler, formRegister}) => {
  const { 
    register,
    setValue,
    triggerValidation,
  } = formRegister;
  const [isLoading, setIsLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState('none');
  const customStyles = {
    input: (provided, state) => ({
      ...provided,
      'fontWeight': 'normal',
      'boxShadow': 'none',
    }),
    noOptionsMessage: (provided, state) => ({ 
      ...provided, 
      'textAlign': 'left',
    }),
    loadingMessage: (provided, state) => ({ 
      ...provided, 
      'textAlign': 'left',
    }),
    option: (baseStyles, state) => ({
      ...baseStyles,
      background: state.isFocused ? '#F2EAF9' : state.isSelected ? '#F2EAF9' : '#FFFFFF',
      color: state.isFocused ? '#333333' : state.isSelected ? '#FFFFFF' : '#333333',
      borderWidth: state.isFocused ? '1px' : '1px',
      borderColor: state.isFocused ? '#5E10B1' : 'transparent',
      borderStyle: 'solid',
    }),
  };

  /** *****************************************************
   *  
   * *****************************************************/
  useEffect(() => {
    setSelectedOption(defaultValue);
  }, [defaultValue, setSelectedOption]);

  /** *****************************************************
   *  
   * *****************************************************/
  const formatOptionLabel = ({ value, label, subLabel, customAbbreviation }) => (
    <div style={{ 'fontWeight': 'normal' }}>
      <div>{label}</div>
      <div style={{ "fontSize": "14px"  }}>{subLabel}</div>
      <div style={{ marginLeft: "10px", color: "#ccc" }}>
        {customAbbreviation}
      </div>
    </div>
  );

  /** *****************************************************
   *  
   * *****************************************************/
  const onLoadOptions = async (o, callback) => {
    // console.log(`[onLoadOptions] ${companyType} --- ${o}`);
    
    if (o.length < 3) {
      return [];
    }

    const url = `${envConfig.API.DOMAIN}${envConfig.API.PATH.COMPANY_SEARCH}`;
    const params = { str: o };
    if (companyType) {
      params.companyType = companyType;
    }
    const res = await Axios.get(url, { params });
    
    const newOptions = _.map(res.data, (item) => {
      return {
        value: item,
        label: `${item.CommercialName} (${item.BusinessRef})`,
        subLabel: `
          ${formatAddressStr(item.BusinessLocation?.LocationLine1?.toLowerCase())},
          ${formatAddressStr(item.BusinessLocation?.LocationLine2?.toLowerCase())},
          ${item.BusinessLocation?.Postcode}
        `,
      };
    });
    
    callback(newOptions || []);
  };

  /** *****************************************************
   *  
   * *****************************************************/
  const onInputChangeHandler = (o) => {
    // console.log(`[onInputChangeHandler] ${o}`);
  };

  /** *****************************************************
   *  
   * *****************************************************/
  const onChangeHandler = async (o) => {
    // console.log('[onChangeHandler]', o);

    setIsLoading(true);
    setSelectedOption(o);

    // get details of the company, and add it to store
    const url = `${envConfig.API.DOMAIN}${envConfig.API.PATH.COMPANY_DETAILS}?legalStatus=${o.value.LegalStatus}&bizRef=${o.value.BusinessRef}`
    const res = await Axios.get(url);

    o.value = {
      ...o.value,
      ...res.data,
    };
    
    setValue(name, o);

    if (onParentChangeHandler) {
      onParentChangeHandler(o);
    }

    setIsLoading(false);

    triggerValidation(name);
  };

  return (
    <>
    {isLoading && 
    <div className='field-company-search__loading-container'>
      <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
    </div>}
    
    <AsyncSelect
      className='field-company-search__search'
      components={{ 
        DropdownIndicator:() => null, 
        IndicatorSeparator:() => null,
      }}
      // cacheOptions
      loadOptions={onLoadOptions}
      formatOptionLabel={formatOptionLabel}
      noOptionsMessage={({inputValue}) => !inputValue || inputValue.length < 3 ? null : "No results found"}  
      styles={customStyles}
      placeholder={''}
      value={selectedOption}
      onInputChange={onInputChangeHandler}
      onChange={onChangeHandler}
      {...register(name, { validate: (o) => {
        if (!selectedOption || !selectedOption.value) {
          return 'Please select a company';
        }
      } })}
    />
    </>
  );
};

export default FieldCompanySearch;
