import cx from 'classnames';
import React from 'react';

import './field-input.scss';

const FieldInput = ({ name, validate: parentValidateHandler, maxlength, formRegister }) => {
  const {
    register,
    errors, 
    formState, 
    watch,
  } = formRegister;
  const watchAllFields = watch({ nest: true });

  return (
    <>
    <input 
      type="text" 
      name={name}
      maxLength={maxlength}
      className={cx( 
        'form-control',
        { touched: formState.touched[name] },
        { success: watchAllFields[name] },
        { error: errors[name] }
      )}
      // {...register(name, { required: false, max: 10 })}
      ref={register({ 
        required: true,
        validate: (val) => {
          if (parentValidateHandler) {
            return parentValidateHandler(val);
          }
        },
        // max: 100, 
        // validate: {
        //   positive: value => parseInt(value) > 0,
        //   lessThanTen: value => parseInt(value) < 10,
        //   // asyncValidate: async () => await fetch(url)
        // }
        // validate: value => value === '1'
      })}
    />
    </>
  );
};

export default FieldInput;