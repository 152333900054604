// import { getAccessLevel } from "./utils";

window.digitalData = {
  page: {},
  application: {},
  // form: {},
};

/* eslint-disable no-underscore-dangle */
if (!window._satellite) {
  if (!window.__karma__) {
    console.warn(
      "Adobe Analytics was not initialised. Mocking _satellite.track()"
    );
  }
  console.info("satellite is already initialized");
  window._satellite = { track: () => {} };
}


export function trackApplicationStep(stepName, applicantType) {
  const stepNameMap = {
    'about-assets': 'About your Asset',
    'about-applicant': 'Type of Applicant',
    'company-search': 'Company Search',
    'company-details': 'Your Details',
    'accept': 'Application Complete',
    'refer': 'Application Complete',
    'decline': 'Application Complete',
    'no-decision': 'Application Complete',
    'not-available': 'Application Complete',
  };

  resetTrackingData();

  window['digitalData'].application = {
    brandName: 'Lombard',
    applicantType: applicantType || '',
  };
  window['digitalData'].page = {
    name: stepNameMap[stepName],
  };
  triggerTracking('pageTransition');
}

///////////////////////////////////////
// PRIVATE FUNCTIONS
///////////////////////////////////////
/**
 * private function to trigger the satellite tracking
 * @param {*} trackKeyword name of the type/event to track
 */
function triggerTracking(trackKeyword) {
  // console.log('[triggerTracking]', JSON.stringify(window['digitalData']));
  window["_satellite"].track(trackKeyword);
}

function resetTrackingData() {
  window["digitalData"] = {};
}