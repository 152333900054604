

const constants = {
  Car: { class: 1, code: 'CAR' },
  Van: { class: 1, code: 'LCV' },
  'Other Vehicles': {
    Bus: { class: 2, code:	'BUS' },
    Coach: { class: 2, code: 'BUS' },
    'Mini-bus': { class: 2,	code: 'BUS' },
    Trailer: { class: 3, code: 'TLR' }
  },
  Technology: {
    '16/35mm film camera and telecine/datacine': { class: 5, code: 'TECH' },
    'Audio - outside broadcast equipment' : { class: 5, code: 'TECH' },
    'Film/video camera lens': { class: 5, code: 'TECH' },
    'Film/video lighting and grip equipment': { class: 5, code: 'TECH' },
    Loudspeaker: { class: 5, code: 'TECH' }
  },
  'Construction': {
    'Access Platform': { class: 3, code: 'COS' },
    'Backhoe Loader': { class: 3, code: 'COS' },
    'Bulldozer': { class: 3, code: 'COS' },
    'Crane': { class: 3, code: 'COS' },
    'Dump truck': { class: 3, code: 'COS' },
    'Forklift': { class: 3, code: 'COS' },
    'Hydraulic Excavator': { class: 3, code: 'COS' },
    'Overhead and gantry crane': { class: 3, code: 'COS' },
    'Telehandler': { class: 3, code: 'COS' },
    'Wheel loader': { class: 3, code: 'COS' },
  },
  Production: {
    'Expanded polystyrene equipment': { class: 4, code: 'PLASM' },
    'Foam moulding equipment': { class: 4, code: 'PLASM' },
    'Laser cutter': { class: 4, code: 'SMM' },
    'Packaging conveyor': { class: 4, code: 'FOP' },
    'Packaging line': { class: 4, code: 'FOP' },
    'Sawing machine': { class: 4, code: 'TEXT' },
    'Sewing machine/embroidery machine': { class: 4, code: 'TEXT' },
    'Wrapping machine': { class: 4, code: 'FOP' },
  },
  Farming: {
    'All-terrain vehicle': { class: 2, code: 'AGRI' },
    'Chipper': { class: 2, code: 'WOOD' },
    'Combine harvester': { class: 2, code: 'AGRI' },
    'Demountable sprayer/sprayer': { class: 2, code: 'AGRI' },
    'Hedger/grass trimmer': { class: 2, code: 'AGRI' },
    'Milking machinery': { class: 2, code: 'AGRI' },
    'Self-propelled sprayer': { class: 2, code: 'AGRI' },
    'Tractor': { class: 2, code: 'AGRI' },
    'Utility vehicle': { class: 2, code: 'AGRI' },
  },
  More: {
    'Robotic and spot welding machine': { class: 4, code: 'SMM' },
    'Sheet metal press brake': { class: 4, code: 'SMM' },
    'Wood lathe': { class: 4, code: 'WOOD' },
    'Woodwork drilling machine': { class: 4, code: 'WOOD' },
  },
};

export default constants;




// 'All-terrain vehicle': { class: 4, code: 'AGRI' },
// 'Chipper': { class: 4, code: 'WOOD' },
// 'Combine harvester': { class: 4, code: 'AGRI' },
// 'Demountable sprayer/sprayer': { class: 4, code: 'AGRI' },
// 'Hedger/grass trimmer': { class: 4, code: 'AGRI' },
// 'Milking machinery': { class: 4, code: 'AGRI' },
// 'Self-propelled sprayer': { class: 4, code: 'AGRI' },
// 'Tractor': { class: 4, code: 'AGRI' },
// 'Utility vehicle': { class: 4, code: 'AGRI' },


const fakeFormData = {
  "aboutAssets": {
    "existingCustomer": "no",
    "purchasePrice": "£ 11223344556677.89",
    "deposit": "£ 1112223333",
    "assetsCount": "3",

    // "assetSingle": "yes",
    // "assetType": "Van",
    "assetSingle": "no",
    "mainAssetType": "Car",
    "assetDescription": "Film/video camera lens"
  },
  "aboutApplicant": {
    "applicantType": "Limited Company"
  },
  "companySearch": {
    "company": {
      "value": {
        "LegalStatus": "L",
        "BusinessRef": "13625002",
        "CommercialName": "K2 MUSIC EVENTS LTD",
        "BusinessLocation": {
          "LocationLine1": "18 MARSWORTH DRIVE",
          "LocationLine2": "BROUGHTON",
          "LocationLine3": "AYLESBURY",
          "Postcode": "HP22 7BG"
        },
        "SICInformation": {
          "CodeType": "B",
          "SICCode": "5552",
          "Description": "CATERING"
        },
        "BusinessStatus": "A",
        "Name": "K2 MUSIC EVENTS LTD",
        "NameType": "RC",
        "RegNumber": "13625002",
        "Identification": {
          "LegalStatus": "2",
          "IncorporationDate": "2021-09-16",
          "RegisteredOffice": {
            "LocationLine1": "18 MARSWORTH DRIVE",
            "LocationLine2": "BROUGHTON",
            "LocationLine3": "AYLESBURY",
            "Postcode": "HP22 7BG"
          },
          "Currency": "GBP",
          "IssuedCapital": "1",
          "AsRegisteredOffice": "Y",
          "TradingLocation": {
            "LocationLine1": "18 MARSWORTH DRIVE",
            "LocationLine2": "BROUGHTON",
            "LocationLine3": "AYLESBURY",
            "Postcode": "HP22 7BG"
          },
          "SICInformation1980": [
            {
              "Code": "6640",
              "Description": "FOOD CATERING"
            }
          ],
          "SICInformation1992": [
            {
              "Code": "5552",
              "Description": "CATERING"
            }
          ],
          "FilingDetails": {
            "DatabaseAccountsOutOfDate": "N",
            "FiledAccountsOutOfDate": "N",
            "LatestReturns": "2022-10-07",
            "LatestReturnsDatabase": "2022-10-07",
            "NumAccountsOnFile": "0",
            "AccountsRefDate": {
              "MM": "09",
              "DD": "30"
            }
          },
          "Auditor": null,
          "Bank": null,
          "PrevSearches": {
            "NumPrevSearch": "0",
            "NumPrevSearch3Mth": "0",
            "NumPrevSearch6Mth": "0",
            "NumPrevSearch12Mth": "0"
          },
          "CCLs": {
            "TotalNumberCCL": "0"
          }
        },
        "CorporateStructure": {
          "CompanyOwnership": {
            "BulkShareHolders": "N",
            "BulkTelephone": "0115 9016000 *2",
            "NumPrincShareHldrs": "1",
            "ParentDetail": null,
            "UltParentDetail": null,
            "CorporateShareholder": [
              {
                "Name": "FARIDA KANCHWALA",
                "Holding": "1 ORD £1"
              }
            ]
          },
          "Subsidiaries": {
            "TreePosition": "1",
            "TotalElements": "1",
            "TotalSubs": "0",
            "TotalNumImmediateSubs": "0",
            "TotalNumLiveImmediateSubs": "0",
            "TotalNumDormantImmediateSubs": "0",
            "TotalNumDissolvedLiquidatedImmediateSubs": "0",
            "TotalNumNestedSubs": "0",
            "TotalNumSubs": "0",
            "TotalNumUKSubs": "0",
            "TotalNumOverseasSubs": "0"
          }
        },
        "ManagementInfo": {
          "LatestReturns": "2022-10-07",
          "NumDirs": "1",
          "NumSecs": "0",
          "NumCurrDirs": "1",
          "NumPrevDirs": "0",
          "DirectorAvgAge": "0",
          "DirectorAvgLength": "13",
          "NumCurrDirectorLast12": "0",
          "NumAppointmentsLast12": "0",
          "NumResignationsLast12": "0",
          "NumDisqualifications": "0",
          "Directors": {
            "CurrentDirector": [
              {
                "ForeignAddressFlag": "N",
                "DirectorCompanyFlag": "N",
                "DirectorNumber": "D20938428",
                "AppointmentDate": "2021-09-16",
                "AppointmentIndicator": "E",
                "DirectorshipLength": "0013",
                "LatestReturns": "2022-10-07",
                "NumberConvictions": "0",
                "DirectorDetails": {
                  "Name": {
                    "Prefix": "MRS",
                    "Forename": "FARIDA",
                    "MiddleNames": "BAQUER SHIRAZ",
                    "SurName": "KANCHWALA"
                  },
                  "CommercialDetail": null,
                  "Location": {
                    "HouseNumber": "18",
                    "Street": "MARSWORTH DRIVE",
                    "District": "BROUGHTON",
                    "PostTown": "AYLESBURY",
                    "County": "BUCKS",
                    "Postcode": "HP22 7BG",
                    "RMC": "1076100"
                  },
                  "Occupation": "DIRECTOR",
                  "Nationality": "BRITISH",
                  "ShareInformation": "1 ORD £1"
                }
              }
            ]
          }
        },
        "Shareholders": {
          "HeaderInfo": [
            {
              "RecordType": "H",
              "IssuingCompany": "13625002",
              "CurrentPreviousFlag": "C",
              "EffectiveDate": "2022-10-07",
              "IssuedCapital": "1",
              "LegalStatus": "0",
              "DocumentType": "C",
              "LatestCurrent": "2022-10-12",
              "LatestUpdate": "2022-10-12",
              "DateLoaded": "2022-10-12"
            }
          ],
          "ShareCapital": [
            {
              "RecordType": "I",
              "IssuingCompany": "13625002",
              "CurrentPreviousFlag": "C",
              "EffectiveDate": "2022-10-07",
              "ShareClassNo": "000001",
              "Currency": "GBP",
              "ShareClassCode": "1",
              "ShareClassDesc": "ORD",
              "VotingRights": "1",
              "TotalNominalCapital": "1",
              "NumShares": "1",
              "NominalValue": "1.00",
              "Percentage": "100.00"
            }
          ],
          "Shareholdings": [
            {
              "RecordType": "S",
              "IssuingCompany": "13625002",
              "CurrentPreviousFlag": "C",
              "EffectiveDate": "2022-10-07",
              "ShareClassNo": "000001",
              "ShareholdingNo": "000001",
              "NumShares": "1",
              "TotalNominalCapital": "1",
              "Percentage": "100.00",
              "PercentageTotalCapital": "100",
              "Approximation": "0"
            }
          ],
          "Shareholder": [
            {
              "RecordType": "U",
              "IssuingCompany": "13625002",
              "CurrentPreviousFlag": "C",
              "EffectiveDate": "2022-10-07",
              "ShareClassNo": "000001",
              "ShareholdingNo": "000001",
              "ShareholderNo": "000001",
              "ShareholderDetails": {
                "ShareholderType": "I",
                "Name": {
                  "FirstName": "FARIDA",
                  "Surname": "KANCHWALA"
                },
                "CommercialDetail": {
                  "KGENName": "KNCL F"
                }
              }
            }
          ]
        },
        "Financials": {
          "Quartiles": null,
          Accounts: [
            {
            },
            {
              ProfitLoss: {
              }
            },
            {
              ProfitLoss: {
                TurnoverSalesDetails: {
                }
              }
            },
            {
              ProfitLoss: {
                TurnoverSalesDetails: {
                  TurnoverSales: 25000001
                }
              }
            },
            {
              ProfitLoss: {
                TurnoverSalesDetails: {
                  TurnoverSales: 200
                }
              }
            }
          ]
        },
        "DirectorExpandedSummary": [
          {
            "NumDirectorsFound": "1",
            "NumDirectorsNotFound": "0",
            "NocFlag": "N",
            "NumDisqualifications": "0",
            "NumConvictions": "0",
            "NumCIFAS": "0",
            "NumDirectorships": "1",
            "NumCurrrentDirships": "1",
            "NumCurrentDissolvedDirships": "0",
            "NumPreviousDirectorships": "0",
            "NumDirectorshipsFailed": "0",
            "NumDirectorshipsNotFailed": "1",
            "NumDirectorshipsCompulsoryLiquidator": "0",
            "NumDirectorshipsCreditorLiquidator": "0",
            "NumDirectorshipsMemberLiqidator": "0",
            "NumDirectorshipsUnknownLiquidator": "0",
            "NumDirectorshipsWindUpOrder": "0",
            "NumDirectorshipsAdministratorAppointed": "0",
            "NumDirectorshipsAdministratorDischarge": "0",
            "NumDirectorshipsAppointmentAdministrativeReceiver": "0",
            "NumDirectorshipsReceiverAppointed": "0",
            "NumDirectorshipsAdministrativeReceiverCessation": "0",
            "NumDirectorshipsReceiverCessation": "0",
            "NumDirectorshipsApprovalVoluntaryArrangements": "0",
            "NumDirectorshipsCompletionVoluntaryArrangements": "0",
            "NumDirectorshipsRevocationVoluntaryArrangements": "0",
            "NumDirectorshipsSuspensionVoluntaryArrangements": "0",
            "NumDirectorshipsReinstated": "0",
            "NumDirectorshipsDissolved": "0",
            "DirectorNumbers": {
              "DirectorNumber": "D20938428"
            }
          }
        ]
      },
      "label": "K2 MUSIC EVENTS LTD (13625002)",
      "subLabel": "\n          18 marsworth drive, \n          Broughton, \n          HP22 7BG\n        "
    }
  },
  "companyDetails": {
    "tempTurnover": "",
    "firstname": "name1",
    "lastname": "last1",
    // "dob": {
    //   "day": "11",
    //   "month": "11",
    //   "year": "2001"
    // },
    // "address": {
    //   "flatNumber": "",
    //   "houseNumber": "20",
    //   "houseName": "Dickens Yard",
    //   "streetName": "20 Trafalgar HouseDickens Yard",
    //   "streetName2": "Longfield Avenue",
    //   "town": "London"
    // }
  }
};


export { fakeFormData }