import cx from 'classnames';
import React from 'react';

import './field-radio.scss';

const FieldRadio = ({ name, subType, options, validate: parentValidateHandler, onChange: parentOnChangeHandler, formRegister }) => {
  const {
    watch,
    register,
    triggerValidation,
  } = formRegister;
  const watchAllFields = watch({ nest: true });

  if (!options) {
    options = [
      { value: 'yes', label: 'Yes'},
      { value: 'no', label: 'No'},
    ];
  }
  
  const validationHandler = {
    required: !parentValidateHandler ? 'This field is required' : false,
    validate: val => {
      // console.log(val);
      if (parentValidateHandler) {
        return parentValidateHandler(val);
      }
    }
  };
  
  return (
    <>
    {options && options.map((option, idx) => {
      const optionValue = option.value || option;
      const optionLabel = option.label || option;
      return (
        <label key={optionValue} className={cx(
          idx === 0 
            ? 'field-radio__option field-radio__option--first' 
            : idx === options.length - 1 
              ? 'field-radio__option field-radio__option--last' 
              : 'field-radio__option',
          watchAllFields[name] === optionValue ? 'active' : 'inactive',
        )}>
          <input name={name} type="radio" value={optionValue} ref={register(validationHandler)} 
            onChange={(o) => {
              // console.log('[onChange] [field-radio]', o.currentTarget.value);
              triggerValidation(name);

              if (parentOnChangeHandler) {
                parentOnChangeHandler(o.currentTarget.value);
              }
            }}
          />
          
          {!subType && 
          <>
          <span className="check"></span>
          <span className="label">{optionLabel}</span>
          </>}

          {subType && subType === 'field-radio-button' &&
          <div className="btn btn-select"><span>{optionLabel}</span></div>}
        </label>
      )
    })}
    </>
  );
};

export default FieldRadio;
