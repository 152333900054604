import React, {useContext, useEffect, useState, useRef } from 'react';
import { useForm } from "react-hook-form";
import _ from 'lodash';
import { withRouter } from "react-router-dom";
import moment from 'moment';
import { ReactComponent as Info } from "../../../assets/images/info_icon_quote_svg.svg";
import { ReactComponent as IconDelete } from "../../../assets/images-new/bin.svg";
import { ReactComponent as IconError } from "../../../assets/images-new/error.svg";
import { toFormatedNumStr } from '../../../common/util';

import { Context } from '../../../stores/store'
import { experianCheck } from '../../../common/experian';
import { navToNext, navToPrev, calcNewFormData } from '../../../common/navigate';
import { Field } from '../../../components/fields';

import 'react-select-search/style.css';
import { toDecimal, toNum } from '../../../common/util';
import envConfig from '../../../common/env.config';
import { trackApplicationStep } from '../../../common/tagging';

const CompanyDetails = ({ history }) => {
  const [ state, dispatch ] = useContext(Context);
  const { formData } = state;
  const formRegister = useForm({
    mode: 'onBlur',
  });
  const {
    register, 
    handleSubmit, 
    errors,
    watch, 
    setValue,
    setError,
  } = formRegister;
  const firstLoad = useRef(true);
  const [loaded, isLoaded] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submissionError, setSubmissionError] = useState(false);
  const [add2Directors, setAdd2Directors] = useState(false);
  const [isTurnoverValid, setIsTurnoverValid] = useState(true);
  // const attemptedToSubmit = useRef(false);
  const attemptedToSubmit = useRef(false);
  
  const watchAllFields = watch({ nest: true });
  const currentStepIdx = 3;
  const pageStateData = _.get(state, 'formData.data.companyDetails');

  let isSubmitted = useRef();
  const { aboutApplicant, companySearch } = state.formData.data;
  const isLtd = aboutApplicant?.applicantType === 'Limited Company';
  const isLlp = aboutApplicant?.applicantType === 'Limited Liability Partnership';
  const isSoleTrader = aboutApplicant?.applicantType === 'Sole Trader';
  const isPartnership = aboutApplicant?.applicantType === 'Partnership';
  const isIndividual = aboutApplicant?.applicantType === 'Individual';
  
  // only LTD and LLP cares about incorporation date
  const cutOffDate = moment().subtract(3, 'years').format('YYYY-MM-DD');
  const showAddingDirectors = 
    isPartnership || 
    ((isLtd || isLlp) && 
      state.formData.data.companySearch.company?.value.Identification?.IncorporationDate > cutOffDate
    );

  /**
   * 
   */
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const validateAnnualTurnover = (val) => {
    const turnover = toNum(val || companySearch?.annualTurnover);
    
    if (turnover > 25000000) {
      setIsTurnoverValid(false);
      return;
    } else {
      setIsTurnoverValid(true);
      return true;
    }
  };

  /**
   * 
   */
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onFirstLoaded = () => {
    // 1, assign data from store to form
    const arr = [];
    for (const key in pageStateData) {
      if (!key.includes('address')) {
        arr.push({ [key]: pageStateData[key] });
      } else {
        arr.push({ [key]: {
          postcode: pageStateData[key].postcode
        } });
      }
    }
    setValue(arr);
    setTimeout(() => { setValue(arr); }, 10);
    if (pageStateData.director2_firstName || pageStateData.director2_lastName || pageStateData.director2_dob) {
      setAdd2Directors(true);
    }
    setTimeout(() => { setValue(arr); }, 20);

    // 2, init - update companySearch.annualTurnover if not exist
    //    If no companySearch.annualTurnover, extract the value from companySearch.company.value, and assign it to companySearch.annualTurnover
    if (companySearch.annualTurnover == null) {
      if (!companySearch.company?.value?.Financials?.Accounts) {
        dispatch({ type: 'FORM_STEP_DATA', payload: ['formData.data.companySearch.annualTurnover', 0.00] });
      } else {
        const found = _.find(companySearch.company.value?.Financials.Accounts, (account) => {
          return account?.ProfitLoss?.TurnoverSalesDetails?.TurnoverSales > 0;
        });

        if (found) {
          dispatch({ type: 'FORM_STEP_DATA', payload: ['formData.data.companySearch.annualTurnover', found.ProfitLoss?.TurnoverSalesDetails?.TurnoverSales] });
        }
      }
    } else {
      // console.log('companySearch.annualTurnover ===', companySearch.annualTurnover);
    }
  };

  /**
   * init
   */
  useEffect(() => {
    // console.log(state.formData.data);

    if (firstLoad.current) {
      firstLoad.current = false;
      onFirstLoaded();
    }
    
    const isValid = validateAnnualTurnover();
    // console.log('[useEffect] isValid', isValid);
    if (!isValid) {
      return;
    }

    // setIsEditing(true);
    if (loaded) {
      if (isSubmitted.current) {
        // console.log('[useEffect] isSubmitted', isSubmitted);
        isSubmitted.current = false;
      }
      return;
    }

    /****** first load ******/
    // 1, tagging
    trackApplicationStep('company-details', aboutApplicant?.applicantType);

    // 2, load existing data to form
    
    /****** first load completed ******/
    isLoaded(true);
  }, [aboutApplicant.applicantType, loaded, onFirstLoaded, validateAnnualTurnover]);

  const submitToExperian = async (newFormData) => {
    setIsSubmitting(true);
    setSubmissionError(false);
    
    try {
      // console.log(newFormData);
      // console.log(state.formData.data);
      const results = await experianCheck(newFormData.data);
      // const results = await experianCheck(state.formData.data);
      // console.log('[submitToExperian] results', results);

      const decisionRouteMapping = {
        accept: 'result',
        refer: 'outcome',
        decline: 'decision',
        'no-decision': 'no-decision',
        'not-available': 'not-available',
      };

      if (results?.decision) {
        navToNext(currentStepIdx, state.formData.steps, history, decisionRouteMapping[results.decision]);
      }
      setIsSubmitting(false);
    } catch(err) {
      setIsSubmitting(false);
      console.log('ERROR!!!', err);
      setSubmissionError(true);
    }
  };

  /**
   * submit form / nav to next step
   */
  const submitHandler = (data) => {
    // console.log(`[onSubmit]`, data);

    const isValid = validateAnnualTurnover();
    if (!isValid) {
      return;
    }
    
    if (isEditing) {
      setError('companyTurnover', { type: 'custom', message: 'Please confirm your annual turnover' });
      return;
    }
    
    // update store
    const newFormData = calcNewFormData(state.formData, currentStepIdx, data);
    // console.log(JSON.stringify(newFormData));
    dispatch({ type: 'FORM_STEP', payload: newFormData });
    
    isSubmitted.current = true;
    submitToExperian(newFormData);
  }

  /**
   * toggle editing mode for company
   */
  const toggleEdit = (saveEdit) => {
    const data = state.formData.data;
    
    if (isEditing) {
      // editing >> non-editing
      setIsEditing(!isEditing);
      const newVal = saveEdit ? watchAllFields.companyTurnover : (watchAllFields.tempTurnover === 'NaN' ? 0 : watchAllFields.tempTurnover);
      dispatch({
        type: 'FORM_STEP_DATA',
        payload: [ 
          'formData.data.companySearch.annualTurnover', 
          newVal
          // saveEdit ? watchAllFields.companyTurnover : watchAllFields.tempTurnover
        ]
      });

      const isValid = validateAnnualTurnover(newVal);
      if (!isValid) {
        return;
      }
    } else {
      // non-editing >> editing
      setIsEditing(!isEditing);
      setValue('tempTurnover', toDecimal(toNum(data.companySearch?.annualTurnover)));
      setValue('companyTurnover', toDecimal(toNum(data.companySearch?.annualTurnover)));
    }
  }

  /**
   * 
   */
  const prevBtnHandler = () => {
    const newFormData = calcNewFormData(state.formData, currentStepIdx, watchAllFields);
    dispatch({ type: 'FORM_STEP', payload: newFormData });
    navToPrev(currentStepIdx, state.formData.steps, history, isIndividual ? 'about-applicant' : undefined);
  }

  /**
   *  
   */
  const onChangeHandlerApplicantAddress = (o) => {
    // console.log(`[onChangeHandlerApplicantAddress] ${JSON.stringify(o)}`);
  };

  /**
   * 
   */
  return (
    <>
    {envConfig.debug && <div className='log-block log-block-2'><p>{JSON.stringify(watchAllFields)}</p></div>}


    {isSubmitting && 
    <div className='ec__loading-container'>
      {/* <LoaderDark /> */}
      <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
    </div>
    }


    <div className='ec__banner row'>
      <div className='ec__banner-inner col-lg-8 col-lg-offset-2'>
        <div className="ec__banner-title">Eligibility Checker</div>
      </div>
    </div>

    <div className='ec__main row'>
      <div className='ec__main-inner col-lg-8 col-lg-offset-2'>
        <form onSubmit={handleSubmit(submitHandler)} className="ec--form ec--step-one active">
          <div className="ec--form-wrapper">



            <div className='ec--step-progress'><b>Step 4</b> of 4</div>
            <div className='ec--step-progress-bar step-4'>
              <div className='ec--step-progress-bar-dot'></div>
              <div className='ec--step-progress-bar-dot'></div>
              <div className='ec--step-progress-bar-dot'></div>
              <div className='ec--step-progress-bar-step'></div>
            </div>

            {!isSoleTrader && !isPartnership && !isIndividual &&
            <>
              <div className='ec--step-title'><h2>Company details</h2></div>
              <div className='ec--step-subtitle'>Are your company details correct?</div>
            </>
            }


            {(isLtd || isLlp) &&
            <div className='field-summary'>
              <div className='field-summary__item field-summary__item--highlight'>
                <div className='field-summary__item-title'>{
                  formData.data.companySearch?.company?.value?.CommercialName
                  // formData.data.companySearch?.companyName
                }</div>
                <div className='field-summary__item-content'>{
                  formData.data.companySearch?.company?.subLabel
                }</div>
              </div>
              <div className='field-summary__item'>
                <div className='field-summary__item-title'>Company registration number</div>
                <div className='field-summary__item-content'>{
                  formData.data.companySearch?.company?.value?.BusinessRef
                }</div>
              </div>
              <div className='field-summary__item'>
                <div className='field-summary__item-title'>Incorporated date</div>
                <div className='field-summary__item-content'>{
                  moment(formData.data.companySearch?.company?.value?.Identification?.IncorporationDate).format('Do MMM YYYY')
                }</div>
              </div>
              
              
              <input 
                type="text" 
                name="tempTurnover"
                className="form-control d-none"
                value={formData.data.companySearch?.companyTurnover || 0}
                ref={register()}
              />

              {!isEditing &&
              <div className='field-summary__item'>
                <div className='field-summary__item-title'>Annual turnover</div>
                <div className='field-summary__item-content'>
                  { (() => {
                      let turnover = toDecimal(toNum(formData.data.companySearch?.annualTurnover));
                      
                      return '£' + toFormatedNumStr(turnover);
                    })()
                  }
                </div>
              </div>}
              
              {!isEditing &&
              <div className='field-summary__action'>
                <button type="button" className='btn btn-link' onClick={() => ( toggleEdit() )}>Is the turnover incorrect?</button>
              </div>}


              {isEditing &&
              <Field
                type='field-amount'
                name='companyTurnover'
                title={`Annual turnover`}
                defaultValue={formData.data.companySearch?.annualTurnover}
                formRegister={formRegister}
              />
              }

              {isEditing && 
              <div className='field-summary__action field-summary__action-editing'>
                <button type="button" className='btn btn-link' onClick={() => { toggleEdit(true) }}>&#10004;&nbsp;Confirm changes</button>
                <button type="button" className='btn btn-link' onClick={() => { toggleEdit(false) }}><span>&#x2715;</span>Discard changes</button>
              </div>}
            </div>}

            {!isTurnoverValid && 
            <div className='ec__warning ec__warning--error'>
              <div className='inner-left-border-red'></div>
              <div className='ec__warning-icon'><IconError /></div>
              <div className='ec__warning-title'>Turnover exceeds £25 million</div>
              <div className='ec__warning-content'>Unfortunately we’re unable to quote online for business with turnover over £25m. Please contact your relationship manager or call our direct team for support on 0800 502 402 (available Monday to Friday 9am to 5pm) and we’ll be happy to help.</div>
            </div>}

            { (((isLtd || isLlp) && showAddingDirectors) || isSoleTrader || isPartnership || isIndividual) &&
            <div className='ec--step-your-details'>
              <div className='ec--step-title'>
                <h2>Your details</h2>
              </div>
              <div className='ec--step-subtitle'>
                {(isLtd || isLlp || isPartnership) && `Now that we have some details about your company, we just need a few details about you as the main applicant`}
                {(isSoleTrader) && `Now that we have some details about your business, we just need a few details about you as the main applicant`}
                {isIndividual && `Now tell us a bit about yourself`}
              </div>
              <Field formRegister={formRegister} 
                type='field-input' 
                name='firstname' 
                title='First name'
                defaultErrorMessages={{
                  required: 'Please enter your first name'
                }}
                maxlength='15'
              />
              <Field formRegister={formRegister} 
                type='field-input' 
                name='lastname' 
                title='Last name' 
                defaultErrorMessages={{
                  required: 'Please enter your last name'
                }}
                maxlength='30'
              />
              <Field 
                formRegister={formRegister} 
                type='field-date' 
                name="dob" 
                title="Date of birth"
                attemptedToSubmit={attemptedToSubmit.current}
                validate={(val) => {
                  // check if 18 years old;
                  const today = moment(new Date()).format('YYYY-MM-DD');
                  const inputDate = moment((Number(val.year) + 18) + '-' + val.month + '-' + val.day).format('YYYY-MM-DD');

                  if (inputDate > today) {
                    return 'You need to be 18 or over to apply for finance';
                  }
                }}
              />
              <Field 
                formRegister={formRegister} 
                type='field-address' 
                name='address' 
                groupTitle='Your current address'
                onChange={onChangeHandlerApplicantAddress}
                isSubmitted={() => {
                  return attemptedToSubmit.current;
                }}
              />
              {errors.address && <div className='ec__warning ec__warning--error'>
                <div className='inner-left-border-red'></div>
                <div className='ec__warning-icon'><IconError /></div>
                <div className='ec__warning-title'>Address details missing</div>
                <div className='ec__warning-content'>If you live in a flat, please enter a flat number and building name and/or number. Otherwise, please enter a house number and (if relevant) a house name.</div>
              </div>}
              {isIndividual && 
              <Field formRegister={formRegister} type='field-dropdown' name='residentialStatus' title='Residential Status' 
                options={['Tenant', 'Living with parents', 'Homeowner', 'Other']}
                validate={(val) => {
                  if (!val) {
                    return 'Please select your residential status';
                  }
                }}
              />}
            </div>
            }


            {!isSoleTrader &&
            !isIndividual &&
            showAddingDirectors &&
            <>
              <hr/>
              <p>If you’d like, you can add the details of an additional two {(isLlp || isPartnership) ? 'partners' : 'directors'}. <b>This is optional</b>, but could make the result more accurate.</p>

              <Field formRegister={formRegister} type='field-radio' name='addDirector' 
                title={
                  (isLlp || isPartnership) 
                    ? 'Would you like to add details for another partner?'
                    : 'Would you like to add details for another director?'
                }
                options={[
                  { value: 'yes', label: 'Yes' },
                  { value: 'no', label: 'No' },
                ]}
              />
              
              {watchAllFields.addDirector === 'yes' && 
              <div className='field-group field-group--dark'>
                <div className='field-group__title'>
                  {(isLlp || isPartnership) && <span>Partner</span>}
                  {(isLtd) && <span>Director</span>}
                </div>

                <div className='field-group__action'>
                  <div className='btn btn-link' onClick={() => {
                    if (!add2Directors) {
                      setValue('addDirector', 'no');
                    } else {
                      setValue('director1_dob', watchAllFields.director2_dob);
                      setValue('director1_firstName', watchAllFields.director2_firstName);
                      setValue('director1_lastName', watchAllFields.director2_lastName);
                      setValue('director1_address', watchAllFields.director2_address);
                      setAdd2Directors(false);
                    }
                  }}><div className='deleteIcon'><IconDelete/></div><div>Delete</div></div>
                </div>

                <Field formRegister={formRegister} 
                  type='field-input'
                  name='director1_firstName' 
                  title='First name'
                  defaultErrorMessages={{
                    required: 'Please enter your first name'
                  }}
                  maxlength='15'
                />
                <Field formRegister={formRegister} 
                  type='field-input' 
                  name='director1_lastName' 
                  title='Last name'
                  defaultErrorMessages={{
                    required: 'Please enter your last name'
                  }}
                  maxlength='30'
                />
                <Field formRegister={formRegister} type='field-date' name='director1_dob' 
                  title='Date of birth'
                  validate={(val) => {
                    // console.log('[validate]', val);
  
                    // check if 18 years old;
                    const today = moment(new Date()).format('YYYY-MM-DD');
                    const inputDate = moment((Number(val.year) + 18) + '-' + val.month + '-' + val.day).format('YYYY-MM-DD');
                    // console.log(today, inputDate);
  
                    if (inputDate > today) {
                      return 'You need to be 18 or over to apply for finance';
                    }
                  }}
                />
                <Field 
                  formRegister={formRegister} 
                  type='field-address' 
                  name='director1_address' 
                  groupTitle='Current address' 
                  isSubmitted={() => {
                    return attemptedToSubmit.current;
                  }}
                />
              </div>}
              {errors.director1_address && 
              <div className='ec__warning ec__warning--error'>
                <div className='inner-left-border-red'></div>
                <div className='ec__warning-icon'><IconError /></div>
                <div className='ec__warning-title'>Address details missing</div>
                <div className='ec__warning-content'>If you live in a flat, please enter a flat number and building name and/or number. Otherwise, please enter a house number and (if relevant) a house name.</div>
              </div>}
              
              {watchAllFields.addDirector === 'yes' && !add2Directors &&
              <div className='field-group'>
                <button type="button" className='btn btn-link' onClick={() => { setAdd2Directors(true) }}>+ Add another {(isLlp || isPartnership) ? 'partner' : 'director'}</button>
              </div>}

              {watchAllFields.addDirector === 'yes' && add2Directors &&
              <div className='field-group field-group--dark'>
                <div className='field-group__title'>
                  {(isLlp || isPartnership) && <span>Partner</span>}
                  {(isLtd) && <span>Director</span>}
                </div>

                <div className='field-group__action'>
                  <div className='btn btn-link' onClick={() => {
                    setAdd2Directors(false);
                  }}><div className='deleteIcon'><IconDelete/></div><div>Delete</div></div>
                </div>

                <Field formRegister={formRegister} 
                  type='field-input' 
                  name='director2_firstName' 
                  title='First name'
                  defaultErrorMessages={{
                    required: 'Please enter your first name'
                  }}
                  maxlength='15'
                />
                <Field formRegister={formRegister} 
                  type='field-input' 
                  name='director2_lastName' 
                  title='Last name'
                  defaultErrorMessages={{
                    required: 'Please enter your last name'
                  }}
                  maxlength='30'
                />
                <Field formRegister={formRegister} type='field-date' name='director2_dob' 
                  title='Date of birth'
                  validate={(val) => {
                    // console.log('[validate]', val);
  
                    // check if 18 years old;
                    const today = moment(new Date()).format('YYYY-MM-DD');
                    const inputDate = moment((Number(val.year) + 18) + '-' + val.month + '-' + val.day).format('YYYY-MM-DD');
                    // console.log(today, inputDate);
  
                    if (inputDate > today) {
                      return 'You need to be 18 or over to apply for finance';
                    }
                  }}
                />
                <Field 
                  formRegister={formRegister} 
                  type='field-address' 
                  name='director2_address'
                  groupTitle='Current address'
                  isSubmitted={() => {
                    return attemptedToSubmit.current;
                  }} 
                />
              </div>}

              {errors.director2_address && 
              <div className='ec__warning ec__warning--error'>
                <div className='inner-left-border-red'></div>
                <div className='ec__warning-icon'><IconError /></div>
                <div className='ec__warning-title'>Address details missing</div>
                <div className='ec__warning-content'>If you live in a flat, please enter a flat number and building name and/or number. Otherwise, please enter a house number and (if relevant) a house name.</div>
              </div>}
            </>}



            <hr/>
            <div className='ec__warning'>
              <div className='inner-left-border'></div>
              <div className='ec__warning-icon'><Info /></div>
              <div className='ec__warning-title'>Privacy notice</div>
              <div className='ec__warning-content'>Our <u><a href="https://www.lombard.co.uk/finance-options/hire-purchase/eligibility-checker-privacy-notice.html" target="_blank" rel="noopener noreferrer">Privacy Notice</a></u> explains how we will use your personal and financial information during this eligibility checker process.</div>
            </div>
                            
            {submissionError && 
            <div className='ec__warning ec__warning--error'>
              <div className='inner-left-border-red'></div>
              <div className='ec__warning-icon'><IconError /></div>
              <div className='ec__warning-title'>Submission Error</div>
              <div className='ec__warning-content'>Please try again later</div>
            </div>}


            <div className="ec--step-bottom">
              <button type="submit" className="btn btn-primary" onClick={() => { attemptedToSubmit.current = true; }}>See my results</button>
              <button type="button" className="btn btn-link" onClick={prevBtnHandler}>Previous</button>
            </div>

          </div>
        </form>
      </div>
    </div>
    </>

  );
}

export default withRouter(CompanyDetails);
