
var _ = require('lodash');

const Reducer = (state, action) => {
    switch (action.type) {
        case 'UPDATE_LOAN_AMOUNT':
            return {
                ...state,
                calculator: {
                    ...state.calculator,
                    loanAmount: action.payload,
                }
            };
        case 'UPDATE_LOAN_LENGTH_MONTHS':
            return {
                ...state,
                calculator: {
                    ...state.calculator,
                    months: action.payload,
                }
            };
        case 'UPDATE_LOAN_LENGTH_YEARS':
            return {
                ...state,
                calculator: {
                    ...state.calculator,
                    years: action.payload,
                }
            };
        case 'UPDATE_RESULTS':
            return {
                ...state,
                calculator: {
                    ...state.calculator,
                    monthlyRepayments: action.payload.repayments,
                    Total: action.payload.total,
                    interestRate: action.payload.interestRate,
                    APR: action.payload.APR
                }
            };
        case 'FORM_STEP':
            return {
                ...state,
                formData: {
                    ...state.formData,
                    combinedTimeAtAddress: action.payload.data.combinedTimeAtAddress ? action.payload.data.combinedTimeAtAddress : state.formData.combinedTimeAtAddress,
                    data: action.payload.data,
                    steps: action.payload.steps,
                    sectionsCompelete: action.payload.sectionsCompelete,
                    activeStep: action.payload.activeStep
                }
            };
        case 'FORM_STEP_DATA':
            const newState = {...state};
            _.set(newState, action.payload[0], action.payload[1]);
            return newState;
        case 'FORM_EDIT':
            return {
                ...state,
                formData: {
                    ...state.formData,
                    data: 
                        {
                            addressHistory: [...state.formData.data.addressHistory],
                            aboutYou: {...state.formData.data.aboutYou},
                            aboutbusiness: {...state.formData.data.aboutbusiness},
                        },
                    steps: action.payload.steps,
                    sectionsCompelete: action.payload.sectionsCompelete,
                    activeStep: action.payload.activeStep
                }
            };
        case 'ADD_ADDRESS':
            return {
                ...state,
                formData: {
                    ...state.formData,
                    combinedTimeAtAddress: action.payload.data.combinedTimeAtAddress,
                    data: action.payload.data,
                }
            };
        case 'EDIT_ADDRESS':
            return {
                ...state,
                formData: {
                    ...state.formData,
                    combinedTimeAtAddress: action.payload.data.combinedTimeAtAddress,
                    data: action.payload.data,
                }
            };
        case 'RESULT':
            return {
                ...state,
                formData: {
                    ...state.formData,
                    status: action.payload,
                }
            };     
        case 'TOGGLE_DN':
            return {
                ...state,
                navData: {
                    ...state.navData,
                    activeItem: action.payload,
                }
            };   
        default:
            return state;
    }
};

export default Reducer;